import { useAuthClient, useAuthState } from '@features/app-hooks'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'
import { Button, Dialog, DialogButtons, Modal, TextInput } from '@st/theme'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { formatPhoneNumber, formatUSPhoneNumber } from '@st/util/input-format'
import { useSDK } from '@features/app-deps-provider'
import { AuthError } from '@st/sdk'
import { formatAuthError } from '@features/auth'

type AccountAction = { type: 'enableTwoFactorAuth' } | { type: 'disableTwoFactorAuth' }

export function OrganizationAccountSettingsPage() {
  const { user } = useAuthState()

  const [action, setAction] = useState<AccountAction | undefined>(undefined)

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Account</h2>
      <div className="gap-y flex flex-col items-start gap-4">
        <p className="text-base text-gray-600">Logged in as {user?.email}</p>
        {match(user?.mfaMethod)
          .with('phone_number', () => (
            <div className="flex flex-col items-start gap-2">
              <p>Two Factor auth is enabled with phone number {user?.phoneNumber}</p>

              <Button variant="default" onClick={() => setAction({ type: 'disableTwoFactorAuth' })}>
                Disable Two Factor Authentication
              </Button>
            </div>
          ))
          .otherwise(() => (
            <div className="flex flex-col items-start gap-2">
              <p>Two Factor auth is disabled.</p>
              <Button variant="default" onClick={() => setAction({ type: 'enableTwoFactorAuth' })}>
                Enable Two Factor Authentication
              </Button>
            </div>
          ))}
      </div>

      {match(action)
        .with({ type: 'enableTwoFactorAuth' }, () => (
          <Modal isOpen={true}>
            <EnableTwoFactorAuthDialog onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .with({ type: 'disableTwoFactorAuth' }, () => (
          <Modal isOpen={true}>
            <DisableTwoFactorAuthDialog onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .otherwise(() => null)}
    </OrganizationSettingsScaffold>
  )
}

type TwoFactorAuthState =
  | { status: 'idle'; error?: 'invalid_phone_number' }
  | { status: 'sendingCode'; phoneNumber: string }
  | { status: 'enterCode'; phoneNumber: string; error?: AuthError }
  | { status: 'verifyingCode'; phoneNumber: string; code: string }
  | { status: 'success'; phoneNumber: string }

function EnableTwoFactorAuthDialog({ onClose }: { onClose: () => void }) {
  const authClient = useAuthClient()
  const sdk = useSDK()

  const [state, setState] = useState<TwoFactorAuthState>({ status: 'idle' })

  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')

  async function onSendVerificationCode() {
    setState({ status: 'sendingCode', phoneNumber })
    await sdk.send({ type: 'accounts/updatePhoneNumber', phoneNumber })
    await sdk.send({ type: 'accounts/sendPhoneNumberVerificationCode' })
    setState({ status: 'enterCode', phoneNumber })
  }

  async function onVerifyCode() {
    setState({ status: 'verifyingCode', phoneNumber, code })
    const result = await sdk.send({ type: 'accounts/verifyPhoneNumber', code })

    if (result.error) {
      setState({ status: 'enterCode', phoneNumber, error: result.error })
      return
    }

    if (result.status == 'success') {
      await sdk.send({ type: 'accounts/enableMultiFactorAuthentication', method: 'phone_number' })
      await authClient.refresh()
      setState({ status: 'success', phoneNumber })
    } else {
      setState({ status: 'enterCode', phoneNumber })
    }
  }

  switch (state.status) {
    case 'idle':
    case 'sendingCode':
      return (
        <Dialog
          title="Enable Two Factor Authentication"
          subtitle="Two factor authentication is a security feature that requires two forms of identification to access your account."
          buttons={
            <DialogButtons>
              <Button variant="default" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onSendVerificationCode}>
                {state.status == 'sendingCode' ? 'Sending code...' : 'Send verification code'}
              </Button>
            </DialogButtons>
          }
        >
          <div className="flex flex-col gap-2">
            <TextInput
              className="w-full"
              placeholder="Enter your phone number ex +1 (443) 997-3253"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              onBlur={() => {
                const formattedNumber = formatUSPhoneNumber(phoneNumber)
                if (!formattedNumber) {
                  setState({ status: 'idle', error: 'invalid_phone_number' })
                  return
                } else {
                  setPhoneNumber(formattedNumber)
                }
              }}
            />

            {state.status == 'idle' && state.error == 'invalid_phone_number' && (
              <p className="text-red-500">Invalid phone number</p>
            )}
          </div>
        </Dialog>
      )
    case 'enterCode':
    case 'verifyingCode':
      return (
        <Dialog
          title="Enable Two Factor Authentication"
          subtitle={`We sent a verification code to your phone number ${state.phoneNumber}. Please enter it below.`}
          buttons={
            <DialogButtons>
              <Button variant="default" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onVerifyCode} disabled={code == ''}>
                {state.status == 'verifyingCode'
                  ? 'Verifying phone number...'
                  : 'Verify phone number'}
              </Button>
            </DialogButtons>
          }
        >
          <div className="flex flex-col gap-2">
            <input
              type="text"
              autoFocus
              autoComplete="off"
              className="rounded-md border-0 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
              placeholder="Enter the 6 digit code verification code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            {state.status == 'enterCode' && state.error && (
              <p className="text-red-500">{formatAuthError(state.error)}</p>
            )}
          </div>
        </Dialog>
      )
    case 'success':
      return (
        <Dialog
          title="Two Factor Authentication Enabled"
          subtitle="Two factor authentication is now enabled for your account."
          buttons={
            <DialogButtons>
              <Button variant="default" onClick={onClose}>
                Close
              </Button>
            </DialogButtons>
          }
        />
      )
  }
}

function DisableTwoFactorAuthDialog({ onClose }: { onClose: () => void }) {
  const authClient = useAuthClient()
  const sdk = useSDK()
  const [isDisabling, setIsDisabling] = useState(false)

  async function onDisable() {
    setIsDisabling(true)
    // Disabling is equivalent to clearing the phone number.
    await sdk.send({ type: 'accounts/updatePhoneNumber', phoneNumber: '' })
    await authClient.refresh()
    onClose()
  }

  return (
    <Dialog
      title="Disable Two Factor Authentication"
      subtitle="Are you sure you want to disable two factor authentication? Your account will be less secure."
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose} disabled={isDisabling}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDisable} disabled={isDisabling}>
            {isDisabling ? 'Disabling...' : 'Disable'}
          </Button>
        </DialogButtons>
      }
    />
  )
}
