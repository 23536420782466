import { useIntercomChatVisible } from '@features/analytics/analytics-service'
import { useAppDeps } from '@features/app-deps-provider'
import { useOrganization } from '@features/organizations'
import { Button, SpreadsheetTable, Wizard } from '@st/theme'
import { download } from '@util/download'
import { usePromise } from '@util/promise'

export function STQuestionnaireDownloadCSVPage({
  folderIds,
  onClose,
  onClickBack
}: {
  folderIds: string[]
  onClose: () => void
  onClickBack: () => void
}) {
  useIntercomChatVisible(false)

  const organization = useOrganization()
  const { sdk } = useAppDeps()

  const data = usePromise(() => {
    return sdk.send({
      type: 'folders/getFolderListExport',
      organizationId: organization.id,
      folderIds: folderIds
    })
  }, [])

  function onPressDownloadCSV() {
    if (data.status != 'fulfilled') throw new Error('Failed to download CSV')

    download(new Blob([data.value.csvContent], { type: 'text/csv' }), 'questionnaires.csv')
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onClickBack}>
            Back
          </Button>
          <Button variant="primary" onClick={onPressDownloadCSV}>
            Download CSV
          </Button>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main
        title="Please download the CSV file with the questionnaire links."
        variant="wide"
      >
        <SpreadsheetTable className="w-full">
          <SpreadsheetTable.Head>
            <SpreadsheetTable.Th>Email address</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Client</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Salutation</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Entity name</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Return type</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Tax year</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Questionnaire link</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Questionnaire status</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Source</SpreadsheetTable.Th>
            <SpreadsheetTable.Th>Source ID</SpreadsheetTable.Th>
          </SpreadsheetTable.Head>
          <SpreadsheetTable.Body>
            {data.status == 'fulfilled'
              ? data.value.rows.map((row, index) => {
                  return (
                    <SpreadsheetTable.Row key={index}>
                      <SpreadsheetTable.Cell>{row.emails}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.client}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.salutation}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.entity}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.returnType}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.taxYear}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.questionnaireLink}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.questionnaireStatus}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.source}</SpreadsheetTable.Cell>
                      <SpreadsheetTable.Cell>{row.sourceId}</SpreadsheetTable.Cell>
                    </SpreadsheetTable.Row>
                  )
                })
              : null}
          </SpreadsheetTable.Body>
        </SpreadsheetTable>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}
