/**
 * A value with only primitive data that can be serialized to a JSON string
 */
export type JSONValue = JsonPrimitive | JsonMap | JSONArray | null | undefined

export type JsonPrimitive = string | number | boolean

export type JSONArray = JSONValue[]

export interface JsonMap {
  [key: string]: JSONValue
}

export function isEmpty<T extends JSONValue | null>(obj: T): boolean {
  if (obj === undefined || obj === null) {
    return true
  } else if (Array.isArray(obj)) {
    return obj.length == 0
  } else if (typeof obj == 'string') {
    return obj.length == 0
  } else if (typeof obj == 'object') {
    return Object.keys(obj).length == 0
  }
  return false
}

export function isNotEmpty<T>(obj: T | null | undefined): obj is T {
  return !isEmpty(obj as JSONValue)
}

type Cast<Out> = (input: unknown) => Out

export const toStr: Cast<string> = (input) => (typeof input === 'string' ? input : '')

export const toStrArray: Cast<string[]> = (input) =>
  Array.isArray(input) && input.every((i) => typeof i === 'string') ? input : []

export const toBool: Cast<boolean> = (input) => (typeof input === 'boolean' ? input : false)
