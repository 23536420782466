import {
  BulletedText,
  DateInput,
  Dropdown,
  GridTable,
  IconImage,
  LabeledGridTable,
  MultiSelect,
  NumberStepper,
  RadioSelect,
  StandardTable,
  Text,
  TextInput,
  ToggleButton,
  WizardAddressInput,
  WizardNameInput,
  WizardNoteComposer
} from '@features/ui-components'
// for some reason causing the build to fail
import { TextField } from '@components/text-field'
import { hasValidationError } from '@features/st-questionnaire/st-questionnaire-module'
import { QuestionnairePageValidationResult } from '@features/st-questionnaire/st-questionnaire-validation'
import { WizardYesNo } from '@features/ui-components/wizard-yes-no'
import {
  CheckboxConfig,
  ComponentConfig,
  DateInputConfig,
  DropdownConfig,
  GridTableConfig,
  LabeledGridTableConfig,
  MoneyInputConfig,
  MultiSelectConfig,
  NumberStepperConfig,
  RadioSelectConfig,
  TextInputConfig,
  YesNoConfig
} from '@st/ui-config'
import { useReadWriteContext } from '@st/pdf'
import { toBool, toInt, toMaybeBool, toMaybeStr, toStr, toStrArray } from '@st/util/cast'
import { clsx } from 'clsx'
import { CategoriesSelector } from './categories-selector'
import { WizardFilesInput } from './wizard-files-input'
import { WizardShareholderInput } from '@features/ui-components/wizard-shareholder-input'

type Props = {
  config: ComponentConfig
  validationResult: QuestionnairePageValidationResult
}

/**
 * A component driven by the config in {@link PageComponentConfig}.
 */
export function WizardUIComponent({ config, validationResult }: Props) {
  switch (config.__typename) {
    case 'AddressInputConfig':
      return <WizardAddressInput config={config} validationResult={validationResult} />
    case 'BulletedTextConfig':
      return <BulletedText {...config} />
    case 'CheckboxConfig':
      return <WizardToggleButton {...config} />
    case 'CategorySelectorConfig':
      return <CategoriesSelector sectionId={config.sectionId} />
    case 'DateInputConfig':
      return <WizardDateInput config={config} validationResult={validationResult} />
    case 'DropdownConfig':
      return <WizardDropdown {...config} />
    case 'FilesInputConfig':
      return <WizardFilesInput {...config} />
    case 'GridTableConfig':
      return <WizardGridTable {...config} className="wizard-form-table" />
    case 'ImageConfig':
      return <IconImage {...config} />
    case 'LabeledGridTableConfig':
      return <WizardLabeledGridTable {...config} className="wizard-form-table" />
    case 'MoneyInputConfig':
      return <WizardMoneyInput config={config} validationResult={validationResult} />
    case 'MultiSelectConfig':
      return <WizardMultiSelect {...config} />
    case 'NameInputConfig':
      return <WizardNameInput config={config} validationResult={validationResult} />
    case 'NoteWidgetConfig':
      return <WizardNoteComposer {...config} />
    case 'NumberStepperConfig':
      return <WizardNumberStepper {...config} />
    case 'RadioSelectConfig':
      return <WizardRadioSelect {...config} />
    case 'StandardTableConfig':
      return <StandardTable className="wizard-form-table" {...config} />
    case 'TextConfig':
      return <Text {...config} />
    case 'TextInputConfig':
      return <WizardTextInput config={config} validationResult={validationResult} />
    case 'YesNoConfig':
      return <WizardYesNoInput {...config} />
    case 'ShareholderInputConfig':
      return <WizardShareholderInput config={config} validationResult={validationResult} />
    default:
      return (
        <div>
          <p>Unknown</p>
          <pre>{JSON.stringify(config, null, 2)}</pre>
        </div>
      )
  }
}

function WizardToggleButton(config: CheckboxConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toBool(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <ToggleButton
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardDateInput({
  config,
  validationResult
}: {
  config: DateInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <DateInput
      className={clsx({
        'border-red-500': hasValidationError(validationResult, config.userInputKey)
      })}
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardDropdown(config: DropdownConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toMaybeStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <Dropdown {...config} value={value} onChange={(v) => setValues({ [config.userInputKey]: v })} />
  )
}

function WizardMultiSelect(config: MultiSelectConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toStrArray(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <MultiSelect
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardRadioSelect(config: RadioSelectConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toMaybeStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <RadioSelect
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardLabeledGridTable(props: LabeledGridTableConfig & { className?: string }) {
  const { useRead, useWrite } = useReadWriteContext()
  return <LabeledGridTable {...props} useRead={useRead} useWrite={useWrite} />
}

function WizardGridTable(props: GridTableConfig & { className?: string }) {
  const { useRead, useWrite } = useReadWriteContext()
  return <GridTable {...props} useRead={useRead} useWrite={useWrite} />
}

function WizardYesNoInput(config: YesNoConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toMaybeBool(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <WizardYesNo
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardNumberStepper(config: NumberStepperConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toInt(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <NumberStepper
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardTextInput({
  config,
  validationResult
}: {
  config: TextInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <TextInput
      className={clsx({
        'border-red-500': hasValidationError(validationResult, config.userInputKey)
      })}
      {...config}
      rows={10}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function WizardMoneyInput({
  config,
  validationResult
}: {
  config: MoneyInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <TextField
      className={clsx({
        'border-red-500': hasValidationError(validationResult, config.userInputKey)
      })}
      label={config.label}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}
