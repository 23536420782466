import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { ClientSubmissionValidationResult, IncompleteReasonOption, STChecklistItem } from '@st/sdk'
import { create } from 'mutative'
import { STQuestionnaireMessage } from './st-questionnaire-module'

type STReviewState = {
  folderId: string
  incompleteChecklistItems: STChecklistItem[]
  editedIncompleteReasons: Record<string, string>
  validationResult: ClientSubmissionValidationResult | undefined
  status: 'editing' | 'saving' | 'confirmSubmission' | 'submitting' | 'submitted'
}

type STReviewMessage =
  | {
      type: 'setIncompleteReason'
      checklistItemId: string
      incompleteReason: string | undefined
    }
  | { type: 'saveBeforeSubmit' }
  | { type: 'submit' }
  | { type: 'goBack' }
  | SDKResponse

type STReviewInit = {
  folderId: string
  incompleteChecklistItems: STChecklistItem[]
}

export const stReviewModule = defineModule<
  STReviewState,
  STReviewMessage,
  STReviewInit,
  { sdk: SDKMessage; stQuestionnaire: STQuestionnaireMessage }
>({
  name: 'stReviewQuestionnaire',
  init: ({ folderId, incompleteChecklistItems }) => {
    return {
      folderId,
      status: 'editing',
      incompleteChecklistItems,
      editedIncompleteReasons: {},
      validationResult: undefined
    }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'setIncompleteReason':
        return create(state, (s) => {
          const checklistItem = s.incompleteChecklistItems.find(
            (el) => el.id == message.checklistItemId
          )
          s.editedIncompleteReasons[message.checklistItemId] = message.incompleteReason!

          if (
            !message.incompleteReason ||
            message.incompleteReason == checklistItem!.incompleteReason
          ) {
            delete s.editedIncompleteReasons[message.checklistItemId]
          }
        })
      case 'saveBeforeSubmit':
        // We first save the incomplete reasons
        // That API call will return a validation
        // Based on that validation, we will either
        // 1. Show a hard error (ex: must provide incomplete reasons)
        // OR
        // 2. Show a confirmation dialog. (If there is a warning, the dialog may be a bit different.)
        const options: IncompleteReasonOption[] = Object.entries(state.editedIncompleteReasons).map(
          ([checklistItemId, incompleteReason]) => {
            return { checklistItemId, incompleteReason }
          }
        )

        return [
          { ...state, status: 'saving', validationResult: undefined, dialog: undefined },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/setChecklistItemIncompleteReasons',
                folderId: state.folderId,
                options: options
              }
            }
          }
        ]
      case 'submit':
        return [
          { ...state, status: 'submitting' },
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/submitQuestionnaire', folderId: state.folderId }
            }
          }
        ]
      case 'goBack':
        return { ...state, status: 'editing' }
      case 'response':
        if (message.operation.type == 'folders/setChecklistItemIncompleteReasons') {
          const validationResult = message.operation.response

          switch (validationResult.status) {
            case 'missing_reasons_error':
              // if reasons are missing, we throw them back to the review page
              return { ...state, status: 'editing', validationResult }
            case 'passed':
              return { ...state, status: 'confirmSubmission', validationResult }
            case 'prefer_complete_before_submission_warning':
              return {
                ...state,
                status: 'confirmSubmission',
                validationResult
              }
          }
        } else if (message.operation.type == 'folders/submitQuestionnaire') {
          return [
            { ...state, status: 'submitted' },
            { stQuestionnaire: { type: 'returnToDashboard' } }
          ]
        }
        return state
    }
  }
})

export function selIsSubmitting(state: STReviewState) {
  return state.status == 'saving' || state.status == 'submitting'
}

export function selIncompleteReasons(state: STReviewState): Record<string, string> {
  const reasons: Record<string, string> = {}
  for (const item of state.incompleteChecklistItems) {
    reasons[item.id] = state.editedIncompleteReasons[item.id] ?? item.incompleteReason
  }

  return { ...reasons, ...state.editedIncompleteReasons }
}
