export function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false
    }

    for (var i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false
      }
    }

    return true
  } else if (
    a !== null &&
    typeof a === 'object' &&
    !Array.isArray(a) &&
    b !== null &&
    typeof b == 'object' &&
    !Array.isArray(b)
  ) {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)

    if (aKeys.length !== bKeys.length) {
      return false
    }

    for (var k of aKeys) {
      if (!deepEqual(a[k], b[k])) {
        return false
      }
    }
    return true
  }

  return false
}

export type PaginatedPage<T> = {
  number: number
  items: T[]
  isFirst: boolean
  isLast: boolean
}

export function paginate<T>(array: T[], size: number): Array<PaginatedPage<T>> {
  if (size <= 0) {
    throw new Error('Chunk size must be positive')
  }

  const pages: Array<PaginatedPage<T>> = []

  if (array.length == 0) {
    return [{ number: 1, items: [], isFirst: true, isLast: true }]
  }

  for (let i = 0; i < array.length; i += size) {
    pages.push({
      number: i + 1,
      isFirst: false,
      isLast: false,
      items: array.slice(i, i + size)
    })
  }

  pages[0].isFirst = true
  pages[pages.length - 1].isLast = true

  return pages
}
