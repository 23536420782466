import { TextField } from '@components/text-field'
import { DateInputConfig, MoneyInputConfig, TextInputConfig } from '@st/ui-config'
import { ConfigInputProps } from '@util/input-component'

type FormTextInputProps = ConfigInputProps<
  TextInputConfig | DateInputConfig | MoneyInputConfig,
  string
> & {
  isMultiline?: boolean | undefined | null
  className?: string
  placeholder?: string
  onClick?: () => void
  onBlur?: () => void
  readonly?: boolean
}
export function FormTextInput({
  isMultiline,
  value,
  label,
  tip,
  onChange,
  onClick,
  onBlur,
  placeholder,
  readonly,
  className
}: FormTextInputProps) {
  return (
    <TextField
      className={className}
      isMultiline={isMultiline || false}
      rows={12}
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      readOnly={readonly}
    />
  )
}
