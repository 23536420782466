import { createSTClientSDK, STSDK } from '@st/sdk'
import { getSupportedStorage, KeyValueStorage } from '@st/util/key-value-storage'
import { err, ok, Result } from '@st/util/result'
import {
  AuthClientAdapter,
  AuthError,
  AuthSignUpOpts,
  AuthStatusEvent,
  AuthStatusListener,
  LoginWithEmailPasswordResult,
  LoginWithMFACodeResult,
  User
} from './auth-client-adapter'
import { AuthState } from './auth-state'
import { uuidv7 } from '@st/util/uuidv7'

const TOKEN_KEY = 'st:token'

// userId = d3MXN3iyA7b7INozl4JYZa16ouh2, email = vendiddy@gmail.com
export class STAuthClientAdapter implements AuthClientAdapter, Disposable {
  private sdk: STSDK

  private storage: KeyValueStorage

  constructor() {
    this.storage = getSupportedStorage()

    console.info(`[auth] Init with storage adapter: ${this.storage.name}`)

    this.sdk = createSTClientSDK({
      baseUrl: process.env.NEXT_PUBLIC_API_V2_ENDPOINT!,
      getToken: async (): Promise<string | undefined> => {
        return this.storage.getItem(TOKEN_KEY) ?? undefined
      },
      getExtraHeaders: () => {
        return { 'x-request-id': uuidv7() }
      }
    })

    this.refresh()

    this.storage.addChangeListener(TOKEN_KEY, this.onStorageChange)
  }

  onStorageChange = () => {
    console.info('[auth] onStorageChange()')
    this.refresh()
  }

  private _onAuthStatusChanged: AuthStatusListener[] = []

  refresh = async () => {
    console.info('[auth] refresh()')

    const token = this._readToken()

    if (!token) {
      this._loggedOut()
      return
    }

    const response = await this.sdk.fetch({ type: 'accounts/getCurrentUser' })

    console.info(`[auth] Fetch current user status=${response.status}`)

    if (response.status == 401) {
      this._writeToken(null)
      this._loggedOut()
      return
    }

    const user = await response.json()

    console.info(`[auth] Fetched: ${JSON.stringify(user)}`)

    this._loggedIn(user)
  }

  signUp = async ({
    email,
    name,
    password,
    passwordConfirm
  }: AuthSignUpOpts): Promise<Result<User, AuthError>> => {
    console.info('[auth] signUp()')

    const res = await this.sdk.send({
      type: 'accounts/registerAccount',
      name,
      email,
      password
    })
    if (res.error) {
      return err(res.error)
    }

    this._writeToken(res.token)
    this._loggedIn(res.user)
    return ok(res.user)
  }

  loginWithEmailPassword = async (
    email: string,
    password: string
  ): Promise<LoginWithEmailPasswordResult> => {
    console.info('[auth] loginWithEmailPassword()')

    this._loggingIn()
    const res = await this.sdk.send({
      type: 'accounts/loginWithEmailPassword',
      email,
      password
    })

    if (res.mfaSession) {
      return {
        status: 'mfaRequired',
        session: res.mfaSession
      }
    }

    if (res.error) {
      return { status: 'failed', error: res.error }
    }

    await this.storage.setItem(TOKEN_KEY, res.token!)
    this._loggedIn(res.user)
    return { status: 'succeeded', user: res.user }
  }

  completeLoginWithMFACode = async (
    sessionId: string,
    code: string
  ): Promise<LoginWithMFACodeResult> => {
    console.info('[auth] completeLoginWithMFACode()')

    this._loggingIn()

    const res = await this.sdk.send({
      type: 'accounts/completeLoginWithMultiFactorCode',
      mfaSessionId: sessionId,
      code
    })

    if (res.error) {
      return { status: 'failed', error: res.error }
    }

    this._writeToken(res.token)
    this._loggedIn(res.user)
    return { status: 'succeeded', user: res.user }
  }

  loginWithMagicLink = async (magicLinkToken: string): Promise<Result<User, AuthError>> => {
    console.info('[auth] loginWithMagicLink()')

    this._loggingIn()
    const res = await this.sdk.send({
      type: 'accounts/loginWithMagicLink',
      magicLinkToken: magicLinkToken
    })

    if (res.error) {
      return err(res.error)
    }

    this._writeToken(res.token)
    this._loggedIn(res.user)

    return ok(res.user)
  }

  resetPassword = async (
    token: string,
    password: string,
    passwordConfirmation: string
  ): Promise<Result<void, AuthError>> => {
    console.info('[auth] resetPassword()')
    // can handle this client-side - no need to make a network request
    if (password != passwordConfirmation) {
      return err({ type: 'password_confirmation_mismatch', message: 'Passwords do not match' })
    }

    const res = await this.sdk.send({
      type: 'accounts/resetPassword',
      resetPasswordToken: token,
      password,
      passwordConfirmation
    })

    if (res.error) {
      return err(res.error)
    }

    return ok(undefined)
  }

  logout = (): Promise<void> => {
    console.info('[auth] logout()')
    this._writeToken(null)
    this._loggedOut()
    return Promise.resolve()
  }

  /**
   * Get the mock token stored in localStorage of the format mock:$userId:$email
   * stored at dev:token
   *
   * For example:
   * mock:d3MXN3iyA7b7INozl4JYZa16ouh2:vendiddy@gmail.com
   *
   * @returns
   */
  getToken = (): string | undefined => {
    console.info('[auth] getToken()')
    const token = this._readToken()

    const currentlyLoggedIn = this._state.status === 'loggedIn'

    if (currentlyLoggedIn && !token) {
      console.info('[auth] Auth state mismatch: logged in but no token in storage. Logging out.')
      // There is a inconsistency between the auth state and the token in storage
      // We need to log out to prevent bad things from happening
      this._loggedOut()

      throw new AuthStateInconsistentError(
        'Auth state mismatch: logged in but no token in storage. Logging out.'
      )
    }

    return token
  }

  getUser = () => {
    return this._state.user
  }

  subscribeToAuthStatus = (listener: AuthStatusListener) => {
    this._onAuthStatusChanged.push(listener)

    // we trigger this on the first subscription to make sure the listener
    // is notified of the current auth state
    if (this._state.status === 'loggedIn') {
      this._notifyListeners({ type: 'loggedIn', user: this._state.user! })
    } else if (this._state.status === 'loggedOut') {
      this._notifyListeners({ type: 'loggedOut' })
    }

    return () => {
      this._onAuthStatusChanged.splice(this._onAuthStatusChanged.indexOf(listener), 1)
    }
  }

  private _readToken = () => {
    const token = this.storage.getItem(TOKEN_KEY) ?? undefined
    console.info(`[auth] Read token: ${formatToken(token)}`)
    return token
  }

  private _writeToken = (token: string | null | undefined) => {
    if (token) {
      console.info(`[auth] Write token: ${formatToken(token)}`)
      this.storage.setItem(TOKEN_KEY, token)
    } else {
      console.info(`[auth] Remove token`)
      this.storage.removeItem(TOKEN_KEY)
    }
  }

  private _state: AuthState = { status: 'unknown', loginState: { status: 'idle' } }

  _loggingIn = () => {
    console.info('[auth] loggingIn')
    this._state = { status: 'loggingIn', loginState: { status: 'idle' } }
  }

  _loggedIn = (user: User) => {
    console.info('[auth] loggedIn')
    this._state = { status: 'loggedIn', user, loginState: { status: 'idle' } }
    this._notifyListeners({ type: 'loggedIn', user })
  }

  _loggedOut = () => {
    console.info('[auth] loggedOut')
    this._state = { status: 'loggedOut', loginState: { status: 'idle' } }
    this._notifyListeners({ type: 'loggedOut' })
  }

  _notifyListeners = (event: AuthStatusEvent) => {
    for (const l of this._onAuthStatusChanged) l(event)
  };

  [Symbol.dispose](): void {}
}

class AuthStateInconsistentError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthStateInconsistentError'
  }
}

function formatToken(token: string | undefined | null) {
  if (!token) return 'null'
  return token.slice(0, 8) + '...'
}
