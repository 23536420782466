import { ComponentConfig, DateInputConfig, MoneyInputConfig, TextInputConfig } from '@st/ui-config'
import { useReadWriteContext } from '@st/pdf'
import { toStr } from '@st/util/cast'
import { FormTextInput } from './form-text-input'

type Props = { config: ComponentConfig }

/**
 * A component driven by the config in {@link PageComponentConfig}.
 */
export function CellComponent({ config }: Props) {
  switch (config.__typename) {
    case 'TextInputConfig':
    case 'MoneyInputConfig':
    case 'DateInputConfig':
      return <WizardCellTextInput {...config} />
    default:
      return <UnknownComponent config={config} />
  }
}

function WizardCellTextInput(config: TextInputConfig | MoneyInputConfig | DateInputConfig) {
  const { useRead, useWrite } = useReadWriteContext()

  const value = toStr(useRead(config.userInputKey))
  const setValues = useWrite()

  return (
    <FormTextInput
      {...config}
      value={value}
      onChange={(v) => setValues({ [config.userInputKey]: v })}
    />
  )
}

function UnknownComponent({ config }: { config: ComponentConfig }) {
  return (
    <div>
      <p>Unknown type {config.__typename}</p>
      <pre>{JSON.stringify(config, null, 2)}</pre>
    </div>
  )
}
