import { DropdownConfig } from '@st/ui-config'
import { QuestionTip } from '@st/theme'
import { ConfigInputProps } from '@util/input-component'
import { useId } from '@util/react'
import { clsx } from 'clsx'
import { ChangeEvent } from 'react'

type DropdownProps = ConfigInputProps<DropdownConfig, string | undefined> & {
  className?: string
  placeholder?: string
}
export function Dropdown({
  className,
  label,
  placeholder = 'Select an option',
  tip,
  value,
  onChange,
  options
}: DropdownProps) {
  const id = useId()

  function onSelectOption(e: ChangeEvent<HTMLSelectElement>): void {
    onChange(e.target.value)
  }

  const optionsComponents = [
    <option key={''} value={undefined} label={placeholder} />,
    ...options.map((opt) => {
      return (
        <option key={opt.key} value={opt.key}>
          {opt.label}
        </option>
      )
    })
  ]

  return (
    <div className="flex flex-col gap-2">
      {label.length == 0 ? null : (
        <label htmlFor={id}>
          {label}
          {tip && <QuestionTip title={tip.title} body={tip.body} />}
        </label>
      )}
      <select
        className={clsx(
          'h-16 rounded-lg border-0 py-1.5 pl-4 pr-12 text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-indigo-600',
          className
        )}
        id={id}
        value={value}
        onChange={onSelectOption}
      >
        {optionsComponents}
      </select>
    </div>
  )
}
