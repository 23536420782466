import { YesNoConfig } from '@st/ui-config'
import { YesNo } from '@theme'
import { cn } from '@util/cn'
import { ConfigInputProps } from '@util/input-component'

type YesNoInputProps = ConfigInputProps<YesNoConfig, boolean | undefined>

export function WizardYesNo({ label, isCondensed, value, onChange }: YesNoInputProps) {
  return (
    <div
      className={cn(
        isCondensed ? 'flex flex-row justify-between gap-5' : 'flex flex-col gap-5',
        isCondensed ? 'mt-1.5' : null
      )}
    >
      {label && isCondensed ? <p className="text-lg">{label}</p> : null}
      <YesNo type={isCondensed ? 'small' : 'large'} value={value} onChange={onChange} />
    </div>
  )
}
