import { Constraints, getAlignOffset, validateSize, Offset, move, Size } from '../../util/geom'
import { DrawOp, Expand, Flex, PDFElement, RenderContext, Renderer } from './interface'

// main axis -> row, cross = column
// main axis -> col, cross = row

export const flexRenderer: Renderer<Flex> = {
  getSize(props, constraints, context) {
    const boxes = getBoxes(props, constraints, context)
    switch (props.direction) {
      case 'row':
        return validateSize(props, constraints, {
          width: getTotal(boxes, (box) => box.size.width),
          height: getMax(boxes, (box) => box.size.height)
        })
      case 'column':
        return validateSize(props, constraints, {
          height: getTotal(boxes, (box) => box.size.height),
          width: getMax(boxes, (box) => box.size.width)
        })
    }
  },
  render(props, origin, constraints, context) {
    const align = props.align ?? 'start'

    const boxes = getBoxes(props, constraints, context)
    const thisSize = this.getSize(props, constraints, context)

    const ops: DrawOp[] = []
    var point = origin

    for (const box of boxes) {
      const renderer = context.rendererFor(box.el.type)

      const offset: Offset =
        props.direction == 'row'
          ? {
              dx: 0,
              dy: getAlignOffset(thisSize.height, box.size.height, align)
            }
          : { dy: 0, dx: getAlignOffset(thisSize.width, box.size.width, align) }

      ops.push(renderer.render(box.el, move(point, offset), box.constraints, context))

      point =
        props.direction == 'row'
          ? move(point, { dx: box.size.width })
          : move(point, { dy: box.size.height })
    }

    return { type: 'group', ops }
  }
}

type BoxedChild = {
  el: PDFElement
  size: Size
  constraints: Constraints
  index: number
  flex?: number
}

function getBoxes(flex: Flex, constraints: Constraints, context: RenderContext): BoxedChild[] {
  const direction = flex.direction
  const mainAxisSize = flex.mainAxisSize ?? 'max'

  const boxes: BoxedChild[] = []

  const indexedChildren = flex.children.map(
    (child, index) => [child, index] as [PDFElement, number]
  )

  const normalChildren = indexedChildren.filter(([child, _]) => child.type != 'Expand')

  const flexChildren = indexedChildren
    .filter(([child, _]) => child.type == 'Expand')
    .map((x) => x as [Expand, number])

  const normalBoxes = normalChildren.map<BoxedChild>(([el, index]) => {
    const renderer = context.rendererFor(el.type)

    const childConstraints: Constraints =
      flex.direction == 'row'
        ? { width: Infinity, height: constraints.height }
        : { height: Infinity, width: constraints.width }

    const size = renderer.getSize(el, childConstraints, context)

    return {
      el,
      index,
      size,
      constraints: childConstraints
    }
  })

  const totalMainAxisSpace = direction == 'row' ? constraints.width : constraints.height

  const normalMainAxisSpace =
    flex.direction == 'row'
      ? getTotal(normalBoxes, (b) => b.size.width)
      : getTotal(normalBoxes, (b) => b.size.height)

  // no flex/expand children, so return just the normal boxes
  if (flexChildren.length == 0) return normalBoxes

  const flexUnitSize =
    (totalMainAxisSpace - normalMainAxisSpace) /
    getTotal(flexChildren, ([child]) => child.flex ?? 1)

  const flexBoxes = flexChildren.map<BoxedChild>(([el, index]) => {
    const renderer = context.rendererFor(el.type)
    const flexSize = (el.flex ?? 1) * flexUnitSize
    const childConstraints: Constraints =
      direction == 'row'
        ? { width: flexSize, height: constraints.height }
        : { height: flexSize, width: constraints.width }

    const size = renderer.getSize(el, childConstraints, context)
    validateSize(el, childConstraints, size)

    return {
      el,
      index,
      size,
      constraints: childConstraints
    }
  })

  for (const box of normalBoxes) boxes[box.index] = box
  for (const box of flexBoxes) boxes[box.index] = box

  return boxes
}

function getTotal<T>(items: T[], valueOf: (el: T) => number): number {
  return items.reduce((total, el) => {
    return total + valueOf(el)
  }, 0)
}

function getMax<T>(items: T[], valueOf: (el: T) => number): number {
  return items.reduce((max, el) => {
    return Math.max(max, valueOf(el))
  }, 0)
}

export const expandRenderer: Renderer<Expand> = {
  getSize(props, constraints, context) {
    if (props.children.length > 1) throw `Must only have 1 child`
    const child = props.children[0]

    const renderer = context.rendererFor(child.type)
    const size = renderer.getSize(child, constraints, context)

    return validateSize(props, constraints, size)
  },
  render(props, origin, constraints, context) {
    // we also delegate the rendering to the child
    if (props.children.length > 1) throw `Must only have 1 child`
    const child = props.children[0]

    const renderer = context.rendererFor(child.type)
    return renderer.render(child, origin, constraints, context)
  }
}
