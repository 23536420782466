import { clsx } from 'clsx'
import { ReactNode } from 'react'

type Props = {
  className?: string
  variant: 'success' | 'warning' | 'error'
  children: ReactNode
}
export function Banner({ className, variant, children }: Props) {
  return (
    <div
      className={clsx(
        'pointer-events-auto justify-between gap-x-6 rounded-md px-6 py-2.5 text-center text-base',
        variant == 'success' && 'bg-green-300 text-green-800',
        variant == 'warning' && 'bg-yellow-300 text-yellow-900',
        variant == 'error' && 'bg-red-400 text-white',
        className
      )}
    >
      {children}
    </div>
  )
}
