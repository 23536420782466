import {
  WizardCategoryConfig,
  WizardConfig,
  WizardPageConfig,
  WizardSectionConfig
} from '@st/ui-config'
import { matchesCondition, resolveValue } from '@st/util/expression'
import { JsonMap } from '@st/util/json-value'

import { groupBy, unique } from '@st/util/array'
import { Section } from '@st/util/section'

/**
 * Represents the current state of an entire questionnaire.
 * Keeps track of the UI config, the data input so far for the questionnaire, and so on.
 *
 * The rest of the questionnaire app stays consistent with the current state represented by this object.
 * When this state updates, all the react components *reactively* re-render to reflect the new state.
 *
 * The app uses this state directly in some situations.
 * In other sitautions, the app makes use of pure functions such as {@link validateInputs}
 * which are essentially derived formulas meant to render certain parts of the app.
 *
 * The high level flow is as such:
 *  - A user interacts with the UI. For example, they may select a category or update a field.
 *  - A React component calls a function on {@link QuestionnaireController} which has a state property
 *  - The controller does what it needs to do and dispatches an {@link Action}.
 *    This action represents an event of something that happened.
 *  - From the current state on the controller and the dispatched action,
 *    the *new* state property on is immediately calculated using {@link reducer}
 *  - The state property on the controller is replaced with this new state.
 *  - All of the react components questionnaire making use of this state *reactively* update
 *
 */

/**
 * Derived calculated from the source state of {@link FormState}
 * This state is not necessary but is here as a convenience to make calculations easier on the UI
 * It is calculated using {@link calculateDerivedState}
 */
export interface WizardState {
  inputs: JsonMap

  uiConfig: WizardConfig

  /**
   * A list of all the pages in the UI config.
   * Does not take into consideration filters, selected categories, etc
   */
  allIndexedPages: IndexedPage[]

  /**
   * A flattened and filtered list of all the pages in the UI config
   * These are the pages that the client of an accountant will see as they
   * hit Next/Previous in the UI.
   *
   * It is a subset of {@link allIndexedPages} which contains all the pages.
   */
  filteredPages: IndexedPage[]
}

export interface IndexedPage {
  page: WizardPageConfig
  category?: WizardCategoryConfig
  section: WizardSectionConfig

  /**
   * Whether the category this page is in has been selected by the user
   */
  categorySelected: boolean

  /**
   * Whether the page matches the showWhen filter.
   * Does not consider the selected categories.
   */
  matchesFilter: boolean
}

/**
 * The entire state of the wizard (not including what page we're currently on)
 * Can be derived from the folder.inputs, the UIConfig, and some context (any data such as the user's role)
 *
 * @param inputs
 * @param uiConfig
 * @param context
 * @returns
 */
export function calculateWizardState(arg: {
  inputs: JsonMap
  priorInputs: JsonMap
  context: JsonMap
  uiConfig: WizardConfig
}): WizardState {
  const allIndexedPages = buildPageIndex(arg)

  return {
    inputs: arg.inputs,
    uiConfig: arg.uiConfig,
    allIndexedPages,
    filteredPages: allIndexedPages.filter((p) => p.matchesFilter && p.categorySelected)
  }
}

export function pageById(state: WizardState, pageId: string): WizardPageConfig | undefined {
  for (var section of state.uiConfig!.sections) {
    if (section.indexPage?.id == pageId) {
      return section.indexPage
    }
    for (var category of section.categories) {
      for (var page of category.pages) {
        if (page.id == pageId) {
          return page
        }
      }
    }
  }
}

/**
 * Used for building up an index that makes it easier to look things up
 * The ui config is flattened into a single array so it's easier to figure out what is next/prev
 */
function buildPageIndex(arg: {
  inputs: JsonMap
  priorInputs: JsonMap
  context: JsonMap
  uiConfig: WizardConfig
}): IndexedPage[] {
  const pages: IndexedPage[] = []

  for (var section of arg.uiConfig.sections) {
    if (section.indexPage) {
      pages.push({
        page: section.indexPage,
        section,
        categorySelected: true,
        matchesFilter: true
      })
    }

    for (var category of section.categories) {
      const categorySelected =
        category.isRequired || arg.inputs[category.isSelectedUserInputKey ?? ''] == true

      for (var page of category.pages) {
        pages.push({
          page,
          category,
          section,
          categorySelected,
          matchesFilter: matchesShowWhen({
            showWhenExpr: page.showWhen ?? undefined,
            inputs: arg.inputs,
            priorInputs: arg.priorInputs,
            context: arg.context
          })
        })
      }
    }
  }

  return pages
}

function matchesShowWhen(opts: {
  showWhenExpr: string | undefined
  inputs: JsonMap
  priorInputs: JsonMap
  context: JsonMap
}) {
  // if there's no filter, we default to showing it
  if (!opts.showWhenExpr) {
    return true
  }

  return matchesCondition(opts.showWhenExpr, {
    resolveValue: (path) => questionnaireResolveExpressionValue(path, opts)
  })
}

export function questionnaireResolveExpressionValue(
  path: string,
  opts: {
    showWhenExpr: string | undefined
    inputs: JsonMap
    priorInputs: JsonMap
    context: JsonMap
  }
) {
  if (path.startsWith('context.')) {
    return resolveValue(path.substring('context.'.length), opts.context)
  }
  if (path.startsWith('priorInputs.')) {
    return resolveValue(path.substring('priorInputs.'.length), opts.priorInputs)
  }
  return resolveValue(path, opts.inputs)
}

export function selectableCategoriesInGroups(
  categories: WizardCategoryConfig[]
): Section<string, WizardCategoryConfig>[] {
  const categoriesByGroupName = groupBy(
    categories.filter((c) => c.isRequired !== true),
    (cat) => cat.groupName || 'Other'
  )
  const categoryGroupNames = unique(categories.map((c) => c.groupName || 'Other'))
  return categoryGroupNames
    .map((groupName) => {
      return {
        heading: groupName,
        items: categoriesByGroupName[groupName] || []
      } as Section<string, WizardCategoryConfig>
    })
    .filter((el) => el.items.length > 0)
}

export function getWizardSection(
  config: WizardConfig,
  sectionId: string
): WizardSectionConfig | undefined {
  return config.sections.find((el) => el.id == sectionId)
}

export function getSectionIds(config: WizardConfig): string[] {
  return config.sections.map((s) => s.id)
}

export function indexedPageById(state: WizardState, id: string): IndexedPage | undefined {
  return state.allIndexedPages.find((el) => el.page.id == id)
}
