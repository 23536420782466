import { useFlashState } from '@st/react-util/use-flash-state'
import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, LinkIcon, PlusIcon, Table, Tip, TrashIcon } from '@st/theme'
import { formatEntities } from '@st/util/entities'
import {
  saveFolder,
  sendQuestionnaireEmail,
  STSendQuestionnaireMessage,
  stSendQuestionnaireModule,
  STSendQuestionnaireState
} from './st-send-questionnaire-module'
import { isValidEmail } from '@st/util/email'

export function STCopyShareableLinkDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const stSendQuestionnaire = useProcess(stSendQuestionnaireModule, {
    folderId: folderId,
    templateType: 'folder_invite'
  })
  const state = useProcessState(stSendQuestionnaireModule)

  if (!state.sendState) {
    return null
  }

  return (
    <STCopyShareableLinkDialogView
      state={state}
      send={stSendQuestionnaire.send}
      onClickSendQuestionnaire={async (message) => {
        await stSendQuestionnaire.send(sendQuestionnaireEmail({ message: message }))
        onClose()
      }}
      onSaveFolder={(message) => {
        stSendQuestionnaire.send(saveFolder({ message: message }))
      }}
      onClose={onClose}
    />
  )
}

function STCopyShareableLinkDialogView({
  state,
  onSaveFolder,
  onClose,
  send
}: {
  state: STSendQuestionnaireState
  send: (message: STSendQuestionnaireMessage) => void
  onClickSendQuestionnaire: (message: string) => void
  onSaveFolder: (message: string) => void
  onClose: () => void
}) {
  const entities = state.sendState?.folder?.entities

  return (
    <Dialog
      title={
        entities ? `Shareable link for ${formatEntities(entities, 'internal')}` : 'Shareable link'
      }
      subtitle="The following emails will have access to the shareable link for this questionnaire. When accessing the link, they will be asked to verify their email address for security purposes."
      className="w-full max-w-2xl"
      buttons={
        <DialogButtons>
          <Button
            variant="subtle"
            onClick={() => {
              onSaveFolder(state.sendState?.message ?? '')
              onClose()
            }}
          >
            Close
          </Button>
          <CopyQuestionnaireLink
            url={state.sendState?.questionnaireUrl ?? ''}
            onCopied={() => onSaveFolder(state.sendState?.message ?? '')}
          />
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        <Table>
          <Table.Head>
            <Table.Th>Emails</Table.Th>
            <Table.Th className="w-32"></Table.Th>
          </Table.Head>
          <Table.Body>
            {state.emails.map((email) => (
              <Table.Row key={email} className="group">
                <Table.Cell className="px-6">{email}</Table.Cell>
                <Table.Cell>
                  <div className="hidden justify-end group-hover:flex">
                    <Tip title="Remove access">
                      <Button
                        variant="subtle"
                        leadingIcon={<TrashIcon className="h-4 w-4" />}
                        onClick={() => send({ type: 'removeEmail', email })}
                      />
                    </Tip>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell className="px-0" colSpan={2}>
                <form
                  className="flex flex-row items-center"
                  onSubmit={(e) => {
                    e.preventDefault()

                    if (isValidEmail(state.inputValue)) {
                      send({ type: 'addEmail', email: state.inputValue })
                    }
                  }}
                >
                  <PlusIcon className="h-4 w-4 text-gray-400" />
                  <input
                    type="text"
                    autoFocus={true}
                    autoComplete="off"
                    placeholder="Type email address and press Enter..."
                    className="flex-1 border-0 border-transparent px-2 text-base text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:border-transparent focus:ring-0"
                    value={state.inputValue}
                    onChange={(e) => send({ type: 'typeEmail', email: e.target.value })}
                  />
                </form>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Dialog>
  )
}

function CopyQuestionnaireLink({ url, onCopied }: { url: string; onCopied: () => void }) {
  const [copied, setCopied] = useFlashState(false, 1500)

  return (
    <Button
      variant="primary"
      leadingIcon={<LinkIcon className="h-4 w-4" />}
      onClick={() => {
        navigator.clipboard.writeText(url)
        setCopied(true)
        onCopied()
      }}
    >
      {copied ? 'Copied link' : 'Copy shareable link'}
    </Button>
  )
}
