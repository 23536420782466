import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  translateX?: number
}
export function NoteTip({ children, translateX }: Props) {
  return (
    <div className="relative overflow-visible">
      <div
        className="static z-[9999] ml-2 flex h-5 flex-row justify-start md:absolute md:left-0 md:top-[-10px] md:ml-0"
        style={{ left: translateX }}
      >
        <div className="ml-0 hidden border-y-8 border-l-0 border-r-8 border-y-transparent border-r-yellow-300 md:block" />

        <div className="rounded bg-yellow-300 px-3 text-xs leading-5 text-black md:rounded-l-none md:rounded-r">
          {children}
        </div>
      </div>
    </div>
  )
}
