import { useSDK } from '@features/app-deps-provider'
import { useAuthState } from '@features/app-hooks'
import { DumpJSON } from '@st/react-util/dump-json'
import { Button, DocumentDropZone } from '@st/theme'
import { FileEntry } from '@st/util/archive/unpack-upload'
import { unpackUpload } from '@st/util/archive/unpack-upload'
import { throwTestException } from '@util/test-exception'
import { useState } from 'react'

export function DebugPage() {
  const authState = useAuthState()
  const sdk = useSDK()

  const [unpackedFiles, setUnpackedFiles] = useState<FileEntry[]>([])

  async function callServerException() {
    await sdk.send({ type: 'accounts/triggerTestException', message: 'error from client' })
  }

  async function callInvalidArguments() {
    await sdk.send({ type: 'accounts/triggerTestException' } as any)
  }

  return (
    <div className="flex h-full w-full flex-col gap-4 p-20">
      <Button
        variant="primary"
        onClick={() => {
          throwTestException()
        }}
      >
        Test Browser Exception
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          callServerException()
        }}
      >
        Test Server Exception
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          callInvalidArguments()
        }}
      >
        Test Invalid Arguments
      </Button>

      <DocumentDropZone
        onDrop={async (dt) => {
          const fileEntries = await unpackUpload(dt)
          setUnpackedFiles(fileEntries)
        }}
      >
        <div>Drop files here</div>
      </DocumentDropZone>

      {unpackedFiles.length > 0 && (
        <div>
          <h1 className="mt-4 text-2xl font-bold">Dropped files</h1>
          <DumpJSON value={unpackedFiles as any} />
        </div>
      )}

      <h1 className="mt-4 text-2xl font-bold">Auth state</h1>
      <DumpJSON value={authState} />
    </div>
  )
}
