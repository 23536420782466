import { FieldComment, ReadWriteProvider, renderToPDF } from '@st/pdf'
import { isEmpty, JsonMap, JSONValue } from '@st/pdf/src/util/json-value'
import { FormConfig } from '@st/ui-config'
import { deepEqual } from './util'
import { FormationDocument } from './formation-document'
import {
  FolderSummary,
  STCategory,
  STChecklistItem,
  STDocument,
  STDocumentType,
  STOpenFolderState
} from '@st/sdk'
import {
  CHECKLIST_ITEMS_PER_PAGE,
  FormationChecklistDocument
} from './formation-checklist-document'

export type FormationData = {
  formConfig: FormConfig
  inputs: JsonMap
  priorInputs: JsonMap
  fieldHints: Record<string, string>
  fieldComments: FieldComment[]
}
export async function renderFormationToPDF({
  formConfig,
  inputs,
  priorInputs,
  fieldHints,
  fieldComments
}: FormationData): Promise<Blob> {
  const doc = await renderToPDF(
    <ReadWriteProvider
      useRead={(key) => inputs[key]}
      useReadInputs={(sel) => sel(inputs)}
      useFieldDecoration={(key) => {
        const hint = fieldHints[key]
        const curValue = inputs[key]
        const priorValue = priorInputs[key]

        return formationFieldDecoration({ hint, curValue, priorValue })
      }}
      useReadFieldComment={(key) => fieldComments.find((c) => c.key == key)}
    >
      <FormationDocument config={formConfig} />
    </ReadWriteProvider>
  )

  const data = await doc.save()
  return new Blob([data], { type: 'application/pdf' })
}

export type ChecklistData = {
  entityName: string
  folder: FolderSummary
  checklistItems: STChecklistItem[]
  documents: STDocument[]
  documentTypes: STDocumentType[]
  categories: STCategory[]
}

export async function renderFormationChecklistToPDF(opts: ChecklistData): Promise<Blob> {
  const doc = await renderToPDF(
    <FormationChecklistDocument
      entityName={opts.entityName}
      folder={opts.folder}
      checklistItems={opts.checklistItems}
      documents={opts.documents}
      documentTypes={opts.documentTypes}
      categories={opts.categories}
      checklistItemsPerPage={CHECKLIST_ITEMS_PER_PAGE}
    />
  )

  const data = await doc.save()

  return new Blob([data], { type: 'application/pdf' })
}

export function formationFieldDecoration({
  hint,
  curValue,
  priorValue
}: {
  hint: string | undefined
  curValue: JSONValue
  priorValue: JSONValue | undefined
}) {
  if (isEmpty(curValue)) {
    // not filled in
    return { hint, backgroundColor: '#f2f4fd' }
  } else if (deepEqual(curValue, priorValue)) {
    // filled in but same as previous year
    return {
      hint,
      backgroundColor: '#e0fbee',
      borderColor: '#5abe8e',
      borderWidth: 0.5
    }
  } else {
    // changed from the prev year (or we don't have anything for last year)
    return {
      hint,
      backgroundColor: '#ffffe3',
      borderColor: '#daa00b',
      borderWidth: 0.5
    }
  }
}
