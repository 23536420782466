import { TextField } from '@components/text-field'
import { DateInputConfig } from '@st/ui-config'
import { QuestionTip } from '@st/theme'
import { ConfigInputProps } from '@util/input-component'

type DateInputProps = ConfigInputProps<DateInputConfig, string> & {
  className?: string
  placeholder?: string
  onBlur?: () => void
}
export function DateInput({
  className,
  value,
  label,
  onChange,
  onBlur,
  placeholder = 'mm/dd/yyyy',
  tip
}: DateInputProps) {
  return (
    <TextField
      type="text"
      className={className}
      value={value}
      label={label}
      tip={tip == null ? undefined : <QuestionTip title={tip.title} body={tip.body} />}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
