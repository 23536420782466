import { RadioSelectConfig } from '@st/ui-config'
import { RadioSelectOption } from '@theme'
import { ConfigInputProps } from '@util/input-component'
import { useState } from 'react'

type Props = ConfigInputProps<RadioSelectConfig, string | undefined>
export function RadioSelect({ value, options, onChange }: Props) {
  const [selectedKey, setValue] = useState(value)

  function onSelectOption(key: string): void {
    setValue(key)
    onChange(key)
  }

  const optionsComponents = options.map((opt) => {
    return (
      <RadioSelectOption
        {...opt}
        key={opt.key}
        selected={opt.key == selectedKey}
        onSelect={() => onSelectOption(opt.key)}
      />
    )
  })

  return (
    <div className="flex w-full flex-col items-stretch gap-5 p-0.5" style={{ padding: 2 }}>
      {optionsComponents}
    </div>
  )
}
