import { useTrackEvent } from '@features/analytics'
import { useSDK } from '@features/app-deps-provider'
import { useAuthState, useSetRoute } from '@features/app-hooks'
import { OrganizationMarketingHeader } from '@features/marketing/components/organization-marketing-header'
import { organizationModule } from '@features/organizations/organization-module'
import { routes } from '@features/routing'
import { useProcess, useProcessState } from '@st/redux'
import { Plan } from '@st/sdk'
import { Button, CheckIcon, Dialog, DialogButtons, Modal, Select } from '@st/theme'
import { formatMoney } from '@st/util/format'
import { ReactNode, useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import { organizationBillingModule, upgradePlan } from './organization-billing-module'
import { STAppHeaderNav } from './st-app-header-nav'
import { STPricingFAQList } from './st-pricing-faq-list'

type BillingAction =
  | { type: 'showUpgradeSelector' }
  | { type: 'confirmUpgrade'; planId: string }
  | { type: 'bookCall' }
  | { type: 'signUp' }

type Promotion = {
  discount: number
  before: string
  coupon: string
}

export const CURRENT_PROMOTION: Promotion | undefined = undefined

function formatDiscount(discount: number) {
  return Math.round(discount * 100).toString() + '%'
}

export function OrganizationPlansPage({ promotion }: { promotion: Promotion | undefined }) {
  const organization = useProcessState(organizationModule, (s) => s.organization!)

  const organizationBilling = useProcess(organizationBillingModule, {
    organizationId: organization.id
  })
  const state = useProcessState(organizationBillingModule)
  const [action, setAction] = useState<BillingAction | undefined>()

  function onAction(action: BillingAction) {
    switch (action.type) {
      case 'showUpgradeSelector':
        setAction(action)
        break
      case 'bookCall':
        window.open('https://calendly.com/stanford-tax/intro', '_blank')
        break
      case 'signUp':
        window.location.href = '/sign-up'
        break
      case 'confirmUpgrade':
        const continueUrl =
          window.location.origin +
          routes.toPath({
            name: 'manage_organization_plan',
            organizationSlug: organization.slug
          })
        organizationBilling.send(upgradePlan({ planId: action.planId, continueUrl }))
        break
    }
  }

  return (
    <div style={{ minWidth: '1200px' }}>
      <STAppHeaderNav />

      <DiscountBanner promotion={promotion} />

      <div className="flex flex-col items-center px-16 py-8">
        <h1 className="text-center text-4xl text-gray-800">Plans</h1>

        {match(state)
          .with({ status: 'loading' }, () => null)
          .with({ status: 'loaded' }, ({ billingState }) => {
            return (
              <>
                <OrganizationPlanColumns
                  onAction={onAction}
                  isLoggedIn={true}
                  // we show the buttons only if the user is not already subscribed
                  showButtons={billingState.activeSubscription == null}
                />

                <h1 className="mb-6 mt-10 text-center text-4xl text-gray-800">
                  Frequently Asked Questions
                </h1>

                <STPricingFAQList />

                <BookConsultationNotice />
              </>
            )
          })
          .run()}
      </div>

      {match(action)
        .with({ type: 'showUpgradeSelector' }, () => {
          if (state.status != 'loaded') {
            throw new Error('Billing state not loaded')
          }
          return (
            <Modal isOpen={true}>
              <UpgradePlanDialog
                availablePlans={state.billingState.availablePlans}
                promotion={promotion}
                onAction={onAction}
                onClose={() => setAction(undefined)}
              />
            </Modal>
          )
        })
        .otherwise(() => null)}
    </div>
  )
}

export function OrganizationLoggedOutPlansPage({
  promotion
}: {
  promotion: Promotion | undefined
}) {
  useTrackEvent('viewed_plans_page')

  const setRoute = useSetRoute()
  const authState = useAuthState()
  const sdk = useSDK()

  function onAction(action: BillingAction) {
    switch (action.type) {
      case 'bookCall':
        window.open('https://calendly.com/stanford-tax/intro', '_blank')
        break
      case 'signUp':
        window.location.href = routes.toPath({ name: 'sign_up' })!
        break
    }
  }

  // redirect them to the logged in pricing page if they are logged in and have a single organization
  useEffect(() => {
    if (authState.user) {
      sdk.send({ type: 'organizations/getMyOrganizationMemberships' }).then(({ memberships }) => {
        if (memberships.length == 1) {
          return setRoute({
            name: 'logged_in_pricing',
            organizationSlug: memberships[0].organization.slug
          })
        }
      })
    }
  }, [sdk, setRoute, authState.user])

  return (
    <div>
      <OrganizationMarketingHeader />
      <DiscountBanner promotion={promotion} />

      <div className="flex flex-col items-center px-16 py-8">
        <h1 className="text-center text-4xl text-gray-800">Plans</h1>
        <OrganizationPlanColumns onAction={onAction} isLoggedIn={false} showButtons={true} />

        <h1 className="mb-6 mt-16 text-center text-4xl text-gray-800">
          Frequently Asked Questions
        </h1>

        <STPricingFAQList />

        <BookConsultationNotice />
      </div>
    </div>
  )
}

function UpgradePlanDialog({
  availablePlans,
  promotion,
  onAction,
  onClose
}: {
  availablePlans: Plan[]
  promotion: Promotion | undefined
  onAction: (action: BillingAction) => void
  onClose: () => void
}) {
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>()

  const selectedPlan = availablePlans.find((plan) => plan.id == selectedPlanId)

  return (
    <Dialog
      title="Upgrade Plan"
      subtitle={
        <span>
          {`Please select the number of returns you'd like to use in 2025. You'll only have to pay for
          clients who use the questionnaire (upload at least one document). Unused credits will roll
          over to the following year.`}
        </span>
      }
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={selectedPlanId == undefined}
            onClick={() => {
              if (selectedPlanId) {
                onAction({ type: 'confirmUpgrade', planId: selectedPlanId })
              }
            }}
          >
            Continue
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col items-start p-2">
        <Select
          className="mb-4"
          placeholder="Select number of returns"
          options={availablePlans}
          value={selectedPlanId}
          onChange={(planId) => setSelectedPlanId(planId!)}
          buildValue={(plan) => plan.id}
          buildLabel={(plan) => `${plan.baseUnitsIncluded} returns`}
        />

        {selectedPlan && <SelectedPlanSummary plan={selectedPlan} promotion={promotion} />}
      </div>
    </Dialog>
  )
}

function SelectedPlanSummary({
  plan,
  promotion
}: {
  plan: Plan
  promotion: Promotion | undefined
}) {
  return (
    <ul className="space-y-1 text-sm/6 text-gray-600">
      {promotion ? (
        <SelectPlanItem>
          <span className="line-through">{formatMoney(plan.basePrice)}/year</span>{' '}
          {formatMoney(plan.basePrice * (1 - promotion.discount))}/year before {promotion.before}{' '}
          (code: {promotion.coupon})
        </SelectPlanItem>
      ) : (
        <SelectPlanItem>{formatMoney(plan.basePrice)}/year</SelectPlanItem>
      )}

      <SelectPlanItem>Includes {plan.baseUnitsIncluded} returns</SelectPlanItem>
      <SelectPlanItem>
        Additional returns are {formatMoney(plan.additionalUnitPrice)}/return
      </SelectPlanItem>
      <SelectPlanItem>No implementation fees</SelectPlanItem>
    </ul>
  )
}

function SelectPlanItem({ children }: { children: ReactNode }) {
  return (
    <li className="flex gap-x-3 text-base">
      <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
      {children}
    </li>
  )
}

function OrganizationPlanColumns({
  onAction,
  showButtons,
  isLoggedIn
}: {
  onAction: (action: BillingAction) => void
  showButtons: boolean
  isLoggedIn: boolean
}) {
  return (
    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
      {PLAN_CONFIGS.map((planConfig, index) => {
        return (
          <PlanCardColumn
            key={index}
            isLoggedIn={isLoggedIn}
            showButtons={showButtons}
            planConfig={planConfig}
            onAction={onAction}
          />
        )
      })}
    </div>
  )
}

function PlanCardColumn({
  planConfig,
  isLoggedIn,
  showButtons,
  onAction
}: {
  isLoggedIn: boolean
  planConfig: PlanConfig
  showButtons: boolean
  onAction: (action: BillingAction) => void
}) {
  const button = isLoggedIn ? planConfig.loggedInButton : planConfig.loggedOutButton

  return (
    <div className="rounded-3xl p-8 ring-1 ring-gray-200">
      <h3 className="text-lg/8 font-semibold text-gray-900">{planConfig.name}</h3>
      <p className="mt-4 h-12 text-base text-gray-600">{planConfig.description}</p>
      <p className="mt-6 flex h-12 items-baseline gap-x-1">
        {planConfig.price && (
          <span className="text-4xl font-semibold tracking-tight text-gray-900">
            {planConfig.price}
          </span>
        )}
        {planConfig.price && planConfig.price != '$0' && (
          <span className="text-base font-semibold text-gray-600">/return</span>
        )}
      </p>

      {showButtons && (
        <Button
          className="my-6 w-full"
          size="l"
          variant={button.action ? 'primary' : 'default'}
          disabled={!button.action}
          onClick={() => button.action && onAction(button.action)}
        >
          {button.caption}
        </Button>
      )}

      <PlanFeaturesChecklist features={planConfig.features} />
    </div>
  )
}

function PlanFeaturesChecklist({ features }: { features: string[] }) {
  return (
    <ul role="list" className="mt-8 space-y-3 text-sm/6 text-gray-600">
      {features.map((item, index) => {
        if (item == '-') {
          return (
            <li key={index} className="flex gap-x-3 text-base">
              <hr className="my-2 w-full border-t bg-stone-200" />
            </li>
          )
        }
        return (
          <li key={index} className="flex gap-x-3 text-base">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
            {item}
          </li>
        )
      })}
    </ul>
  )
}

function DiscountBanner({ promotion }: { promotion: Promotion | undefined }) {
  if (!promotion) {
    return null
  }

  return (
    <div className="pointer-events-auto flex items-center justify-center gap-x-6 bg-green-300 px-6 py-2.5 text-center text-base text-green-800">
      Get {formatDiscount(promotion.discount)} off all plans purchased before {promotion.before}.
      Use code {promotion.coupon} at checkout.
    </div>
  )
}

function BookConsultationNotice() {
  return (
    <div className="mt-8 text-base">
      {`Didn't find the answer to your question? `}
      <a
        className="text-base text-blue-500"
        target="_blank"
        href="https://calendly.com/stanford-tax/intro"
      >
        Book a consultation
      </a>
    </div>
  )
}

type PlanButton = { caption: string; action: BillingAction | undefined }

type PlanConfig = {
  id: string
  name: string
  description: string
  price?: string
  features: string[]
  loggedInButton: PlanButton
  loggedOutButton: PlanButton
}

const PLAN_CONFIGS: PlanConfig[] = [
  {
    name: 'Free',
    id: 'tier',
    price: '$0',
    description: 'Give StanfordTax a test drive with five returns.',
    loggedOutButton: { caption: 'Get started', action: { type: 'signUp' } },
    loggedInButton: { caption: 'Current plan', action: undefined },
    features: [
      '1 user, up to 5 returns',
      '-',
      'Client questionnaire',
      'Preparer workpaper',
      '1040, 1120, 1120s, 1065'
    ]
  },
  {
    name: 'Premium',
    id: 'premium',
    price: '$12',
    description: 'Our most popular plan for small and medium sized firms.',
    loggedOutButton: { caption: 'Select plan', action: { type: 'signUp' } },
    loggedInButton: { caption: 'Select plan', action: { type: 'showUpgradeSelector' } },
    features: [
      '8 users',
      '-',
      'Client questionnaire',
      'Preparer workpaper',
      '1040, 1120, 1120s, 1065',
      '-',
      'Email and live chat support',
      '24-hour support response time',
      '-',
      'No implementation fees'
    ]
  },
  {
    name: 'Professional',
    id: 'professional',
    price: '$18',
    description: 'Dedicated support for larger firms.',
    loggedOutButton: { caption: 'Select plan', action: { type: 'signUp' } },
    loggedInButton: { caption: 'Select plan', action: { type: 'showUpgradeSelector' } },
    features: [
      '20 users',
      '-',
      'Client questionnaire',
      'Preparer workpaper',
      '1040, 1120, 1120s, 1065',
      '-',
      'Live chat and phone support',
      '12-hour support response time',
      'Dedicated account manager',
      '-',
      'No implementation fees'
    ]
  },
  {
    name: 'Enterprise',
    id: 'enterprise',
    price: '',
    description: 'Our most customizable plan for advanced control.',
    loggedOutButton: { caption: 'Talk to sales', action: { type: 'bookCall' } },
    loggedInButton: { caption: 'Talk to sales', action: { type: 'bookCall' } },
    features: [
      'Unlimited users',
      '-',
      'Client questionnaire',
      'Preparer workpaper',
      '1040, 1120, 1120s, 1065',
      '-',
      'Live chat and phone support',
      '1-hour support and response time',
      'Dedicated account manager',
      '-',
      'Custom integrations'
    ]
  }
]
