import { BrandLogo } from '@st/theme'
import { ReactNode } from 'react'

export function CollectionHomePage() {
  return (
    <div>
      <div style={{ height: '60px' }} />
      <div className="flex flex-col items-center">
        <BrandLogo className="my-12" />

        <img
          style={{ maxWidth: 'min(800, 90%)', margin: '0 auto' }}
          src="/images/security-shield-icon.svg"
          alt="security lock"
          loading="lazy"
        />

        <div style={{ height: '20px' }} />

        <div className="flex flex-col gap-0.5" style={{ maxWidth: '650px', margin: 'auto' }}>
          <h1 className="mv-12 text-center text-3xl">Welcome to StanfordTax</h1>

          <p className="mv-12 text-center" style={{ marginTop: '-12px' }}>
            StanfordTax is a secure way for accountants to collect tax documents from their clients.
          </p>

          <b className="mv-12 text-center">
            You may have reached this web page by mistake. If you are trying to send files to your
            accountant, please contact them for a secure link.
          </b>
          <SecuritySection title="256 SSL">
            <p>
              StanfordTax encrypts all sensitive data, both at rest and in transit. The underlying
              infrastructure uses industry standard AES-256 encryption for storage.
            </p>
          </SecuritySection>

          <SecuritySection title="MFA">
            <p>
              All personnel of StanfordTax use multi-factor authentication for all services. This
              includes but is not limited to email, cloud storage accounts, and the application
              source code.
            </p>
          </SecuritySection>

          <SecuritySection title="File storage">
            <p>
              All files uploaded by users of StanfordTax are stored directly on a compliant storage
              service. This storage service has been certified ISO 27001, ISO 27017, ISO 27018, SOC
              1, SOC 2, and SOC 3.
            </p>
          </SecuritySection>

          <SecuritySection title="Software Audits">
            <p>
              3rd party code libraries used inside of the StanfordTax app are kept to a minimum. The
              few used are thoroughly audited. This includes line-by-line code audits and the use of
              security tools such as{' '}
              <a href="https://snyk.io" target="_blank" rel="noreferrer">
                snyk
              </a>{' '}
              to maintain package security.
            </p>
          </SecuritySection>
        </div>
      </div>
    </div>
  )
}

type SecuritySectionProps = { title: string; children: ReactNode }
function SecuritySection({ title, children }: SecuritySectionProps) {
  return (
    <section className="flex flex-col gap-3">
      <h2 className="text-lg">{title}</h2>
      <div>{children}</div>
    </section>
  )
}
