export interface KeyValueStorage {
  readonly name: string

  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void

  addChangeListener(key: string, listener: () => void): void
  removeChangeListener(key: string, listener: () => void): void
}

export function getSupportedStorage(): KeyValueStorage {
  try {
    localStorage.getItem('_testLocalStorage')
    return new LocalStorage()
  } catch (e) {
    console.warn('LocalStorage not supported, falling back to InMemoryStorage')
    return new InMemoryStorage()
  }
}

export class LocalStorage implements KeyValueStorage {
  readonly name = 'LocalStorage'
  /**
   * We maintain a mapping so we can go from
   * (key being listened to, listener) => global listener
   *
   * This allows us to wrap the listener with a filter over the key being listened to.
   */
  private listeners = new Map<string, Map<() => void, (e: StorageEvent) => void>>()

  getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value)

    console.info(`[LocalStorage] setItem key=${key} value=${formatValue(value)}`)
  }

  removeItem(key: string): void {
    localStorage.removeItem(key)
    console.info(`[LocalStorage] removeItem key=${key}`)
  }

  addChangeListener(key: string, listener: () => void): void {
    let keyMap = this.listeners.get(key)
    if (!keyMap) {
      keyMap = new Map()
      this.listeners.set(key, keyMap)
    }

    const wrappedListener = (event: StorageEvent) => {
      if (event.key === 'st:token') {
        console.info(
          `[LocalStorage] StorageEvent: ${event.type} key=${event.key} oldValue=${formatValue(
            event.oldValue
          )} newValue=${formatValue(event.newValue)}`
        )
      }
      if (event.key === key) {
        listener()
      }
    }

    keyMap.set(listener, wrappedListener)

    window.addEventListener('storage', wrappedListener)
  }

  removeChangeListener(key: string, listener: () => void): void {
    const keyMap = this.listeners.get(key)
    if (!keyMap) return

    const wrappedListener = keyMap.get(listener)
    if (!wrappedListener) return

    // Unhook from window
    window.removeEventListener('storage', wrappedListener)

    // Remove from our map
    keyMap.delete(listener)

    // Clean up empty sub-map
    if (keyMap.size === 0) {
      this.listeners.delete(key)
    }
  }
}
type BroadcastMessage =
  | {
      type: 'set'
      key: string
      value: string
    }
  | {
      type: 'remove'
      key: string
    }

type InMemoryStorageOpts = { syncTabs?: boolean }

export class InMemoryStorage implements KeyValueStorage {
  readonly name = 'InMemoryStorage'

  /** Simple object to store data in memory (no persistence beyond this tab). */
  private data: Record<string, string> = {}

  /**
   * For each key, store a Set of listeners so we can notify them when that key changes.
   */
  private listeners = new Map<string, Set<() => void>>()

  /**
   * BroadcastChannel for cross-tab communication. If unsupported, stays undefined.
   */
  private channel?: BroadcastChannel

  private channelName = 'KeyValueStorage:InMemoryStorage'

  constructor(opts?: InMemoryStorageOpts) {
    const syncTabs = opts?.syncTabs ?? true

    if (typeof BroadcastChannel !== 'undefined' && syncTabs) {
      this.channel = new BroadcastChannel(this.channelName)
      // Listen for messages from other tabs
      this.channel.addEventListener('message', this.onMessage)
    }
  }

  getItem(key: string): string | null {
    return this.data[key] ?? null
  }

  setItem(key: string, value: string): void {
    this.data[key] = value

    this.notifyLocalChange(key)
    this.broadcastMessage({ type: 'set', key, value })
  }

  removeItem(key: string): void {
    // 1) Remove locally
    delete this.data[key]

    this.notifyLocalChange(key)
    this.broadcastMessage({ type: 'remove', key })
  }

  addChangeListener(key: string, listener: () => void): void {
    let keyListeners = this.listeners.get(key)
    if (!keyListeners) {
      keyListeners = new Set()
      this.listeners.set(key, keyListeners)
    }
    keyListeners.add(listener)
  }

  removeChangeListener(key: string, listener: () => void): void {
    const keyListeners = this.listeners.get(key)
    if (!keyListeners) return

    keyListeners.delete(listener)
    if (keyListeners.size === 0) {
      this.listeners.delete(key)
    }
  }

  /**
   * Handle incoming messages from the BroadcastChannel.
   * Notice we do NOT call setItem/removeItem here, so we won't re-postMessage and loop.
   */
  private onMessage = (event: MessageEvent) => {
    const message = event.data as BroadcastMessage

    switch (message.type) {
      case 'set':
        this.data[message.key] = message.value
        this.notifyLocalChange(message.key)
        break
      case 'remove':
        delete this.data[message.key]
        this.notifyLocalChange(message.key)
        break
    }
  }

  private broadcastMessage(message: BroadcastMessage) {
    this.channel?.postMessage(message)
  }

  /**
   * Notify all local listeners of a change to the given key.
   */
  private notifyLocalChange(key: string) {
    const keyListeners = this.listeners.get(key)
    if (!keyListeners) return

    for (const listener of keyListeners) {
      listener()
    }
  }
}

function formatValue(value: string | null) {
  if (value === null) return 'null'
  if (value.length > 7) return `${value.substring(0, 7)}...`
  return value
}
