import { RouteMessage } from '@features/route-module'
import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { LookupFolderByEmailResult } from '@st/sdk'
import { match } from 'ts-pattern'

export type STQuestionnaireLookupState =
  | {
      status: 'idle'
      organizationId: string
      schemaId: string
      email: string
      autoCreateWhenNotFound: boolean
    }
  | {
      status: 'running'
      organizationId: string
      schemaId: string
      email: string
      autoCreateWhenNotFound: boolean
    }
  | {
      status: 'completed'
      organizationId: string
      schemaId: string
      email: string
      autoCreateWhenNotFound: boolean
      result: LookupFolderByEmailResult
    }

export type STQuestionnaireLookupMessage = { type: 'lookup'; email: string } | SDKResponse

type STQuestionnaireLookupInit = {
  organizationId: string
  schemaId: string
  email: string | undefined
  autoCreateWhenNotFound: boolean
}

export const stQuestionnaireLookupModule = defineModule<
  STQuestionnaireLookupState,
  STQuestionnaireLookupMessage,
  STQuestionnaireLookupInit,
  { sdk: SDKMessage; route: RouteMessage }
>({
  name: 'stQuestionnaireLookup',
  init: ({ organizationId, schemaId, email, autoCreateWhenNotFound }) => {
    // if an email is provided at the outset, we want to immediately lookup the folder
    if (email) {
      return [
        {
          status: 'running',
          organizationId: organizationId,
          schemaId: schemaId,
          email: email ?? '',
          autoCreateWhenNotFound: autoCreateWhenNotFound
        },
        {
          sdk: {
            type: 'request',
            request: {
              type: 'folders/lookupFolderByEmail',
              organizationId: organizationId,
              schemaId: schemaId,
              email: email,
              autoCreateWhenNotFound: autoCreateWhenNotFound
            }
          }
        }
      ]
    } else {
      return {
        status: 'idle',
        organizationId: organizationId,
        schemaId: schemaId,
        email: email ?? '',
        autoCreateWhenNotFound: autoCreateWhenNotFound
      }
    }
  },
  handle: (state, message) => {
    return match({ state, message })
      .with({ state: { status: 'idle' }, message: { type: 'lookup' } }, ({ state, message }) => {
        return [
          {
            status: 'running',
            organizationId: state.organizationId,
            schemaId: state.schemaId,
            email: message.email,
            autoCreateWhenNotFound: state.autoCreateWhenNotFound
          },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/lookupFolderByEmail',
                organizationId: state.organizationId,
                schemaId: state.schemaId,
                email: message.email,
                autoCreateWhenNotFound: state.autoCreateWhenNotFound
              }
            }
          }
        ] satisfies [STQuestionnaireLookupState, { sdk: SDKMessage }]
      })
      .with(
        {
          state: { status: 'running' },
          message: { type: 'response', operation: { type: 'folders/lookupFolderByEmail' } }
        },
        ({ state, message }) => {
          return {
            status: 'completed',
            organizationId: state.organizationId,
            schemaId: state.schemaId,
            email: state.email,
            autoCreateWhenNotFound: state.autoCreateWhenNotFound,
            result: message.operation.response
          } satisfies STQuestionnaireLookupState
        }
      )
      .otherwise(({ state }) => {
        return state
      })
  }
})
