import { formatChecklistItem } from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import { Organization, STChecklistItem, STMissingReason } from '@st/sdk'
import {
  ArrowLeftIcon,
  Button,
  CircleXIcon,
  Dialog,
  DialogButtons,
  Modal,
  QuestionnaireBanner,
  QuestionnaireFooter,
  QuestionnaireHeader,
  QuestionnairePageContainer,
  QuestionnaireProgressBar,
  QuestionnaireSectionTab,
  Select,
  SendIcon
} from '@st/theme'
import { Progress } from '@st/util/progress'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { match, P } from 'ts-pattern'
import {
  STQuestionnaireMessage,
  WizardNavigationState,
  WizardSectionTab
} from './st-questionnaire-module'
import { STQuestionnaireTrailingHeader } from './st-questionnaire-trailing-header'
import {
  selIncompleteReasons,
  selIsSubmitting,
  stReviewModule
} from './st-review-questionnaire-module'

export type Props = {
  folderId: string
  organization: Organization
  navState: WizardNavigationState
  progress: Progress
  tabs: WizardSectionTab[]
  saveStatus: 'saving' | 'saved'
  documentsCount: number
  incompleteChecklistItems: STChecklistItem[]
  missingReasonsById: Record<string, STMissingReason[]>

  dialog: ReactNode | undefined

  onClickViewAttachments: () => void
  send: (message: STQuestionnaireMessage) => void
}
export function STReviewQuestionnairePage({
  folderId,
  organization,
  navState,
  progress,
  tabs,
  incompleteChecklistItems,
  missingReasonsById,
  saveStatus,
  documentsCount,
  dialog,
  onClickViewAttachments,
  send
}: Props) {
  const stReview = useProcess(stReviewModule, { folderId, incompleteChecklistItems })

  const reviewStatus = useProcessState(stReviewModule, (s) => s.status)
  const incompleteReasons = useProcessState(stReviewModule, selIncompleteReasons)
  const validationResult = useProcessState(stReviewModule, (s) => s.validationResult)
  const isSubmitting = useProcessState(stReviewModule, selIsSubmitting)
  const editedChecklistItemIds = useProcessState(stReviewModule, (s) =>
    Object.keys(s.editedIncompleteReasons)
  )

  return (
    <QuestionnairePageContainer
      banner={
        validationResult?.status == 'missing_reasons_error' && (
          <QuestionnaireBanner variant="error">
            Please select reasons for the remaining incomplete items
          </QuestionnaireBanner>
        )
      }
      header={
        <QuestionnaireHeader
          leading={<div className="text-md hidden font-semibold md:block">{organization.name}</div>}
          trailing={
            <STQuestionnaireTrailingHeader
              saveStatus={saveStatus}
              documentsCount={documentsCount}
              onClickViewAttachments={onClickViewAttachments}
              onClose={() => send({ type: 'returnToDashboard' })}
            />
          }
        >
          {tabs.map((tab) => {
            return (
              <QuestionnaireSectionTab
                key={tab.route.sectionId}
                icon={tab.route.sectionId}
                caption={tab.label}
                selected={tab.route.sectionId == navState.cur.sectionId}
                onClick={() => send({ type: 'navigate', route: tab.route })}
              />
            )
          })}
        </QuestionnaireHeader>
      }
      footer={
        <QuestionnaireFooter>
          <Button
            size="l"
            variant="primary"
            leadingIcon={<ArrowLeftIcon className="h-4 w-4" />}
            disabled={navState.prev == undefined}
            onClick={() => send({ type: 'navigate', route: navState.prev! })}
          >
            Back
          </Button>
          <QuestionnaireProgressBar value={Progress.toNumber(progress)} />
          <Button
            size="l"
            variant="primary"
            leadingIcon={<SendIcon className="h-4 w-4" />}
            disabled={isSubmitting}
            onClick={() => stReview.send({ type: 'saveBeforeSubmit' })}
          >
            {reviewStatus == 'saving' ? 'Saving...' : 'Submit'}
          </Button>
        </QuestionnaireFooter>
      }
    >
      {match(incompleteChecklistItems)
        .with([], () => (
          <div className="flex h-full grow flex-col items-center justify-center">
            <h1 className="text-center text-3xl font-semibold text-gray-800">
              You are almost done with the questionaire!
            </h1>

            <div className="mt-5">
              Please click the submit button below so we will be notified and start working on your
              return.
            </div>
          </div>
        ))
        .otherwise(() => {
          return (
            <div className="flex flex-col">
              <h1 className="text-center text-3xl font-semibold text-gray-800">{`You're almost done with the questionnaire, but you're still missing some items!`}</h1>

              <div className="mt-5">
                <div className="mb-6 text-center text-lg text-gray-900">
                  {`Please do not submit the questionnaire until you've provided all of your info. You
                  can leave and come back later if needed (all of your info has been saved). To
                  submit with missing info, please let us know why below.`}
                </div>

                <div className="divide-y divide-gray-200">
                  {incompleteChecklistItems.map((checklistItem) => {
                    let missingReasons = missingReasonsById[checklistItem.constraint.id] ?? []

                    const checklistItemValidation = validationResult?.validations.find(
                      (v) => v.checklistItemId == checklistItem.id
                    )

                    return (
                      <IncompleteChecklistItem
                        key={checklistItem.id}
                        title={formatChecklistItem(checklistItem)}
                        select={
                          <Select
                            className={clsx('w-48', {
                              'ring-red-700':
                                checklistItemValidation?.status == 'requires_missing_reason' &&
                                // We want the validation to clear if they edit the reason
                                !editedChecklistItemIds.includes(checklistItem.id)
                            })}
                            placeholder="Select reason"
                            options={missingReasons}
                            buildValue={(reason) => reason.key}
                            buildLabel={(reason) => reason.name}
                            value={incompleteReasons[checklistItem.id]}
                            onChange={(v) =>
                              stReview.send({
                                type: 'setIncompleteReason',
                                checklistItemId: checklistItem.id,
                                incompleteReason: v
                              })
                            }
                          />
                        }
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}

      {dialog}

      {match({ reviewStatus, validationResult })
        .with(
          {
            reviewStatus: P.union('confirmSubmission', 'submitting'),
            validationResult: {
              status: 'prefer_complete_before_submission_warning'
            }
          },
          () => (
            <Modal isOpen={true}>
              <PreferCompletionBeforeSubmissionWarningDialog
                submitting={isSubmitting}
                onClose={() => stReview.send({ type: 'goBack' })}
                onSubmit={() => stReview.send({ type: 'submit' })}
              />
            </Modal>
          )
        )
        .with(
          {
            reviewStatus: P.union('confirmSubmission', 'submitting'),
            validationResult: { status: 'passed' }
          },
          () => (
            <Modal isOpen={true}>
              <DefaultConfirmSubmissionDialog
                submitting={isSubmitting}
                onClose={() => stReview.send({ type: 'goBack' })}
                onSubmit={() => stReview.send({ type: 'submit' })}
              />
            </Modal>
          )
        )
        .otherwise(() => null)}
    </QuestionnairePageContainer>
  )
}

function IncompleteChecklistItem({ title, select }: { title: string; select?: ReactNode }) {
  return (
    <div className="flex items-center py-2">
      <CircleXIcon className="mx-3 h-4 w-4 text-gray-300" />
      <div className="grow text-base font-semibold text-gray-900">{title}</div>
      <div>{select}</div>
    </div>
  )
}

function DefaultConfirmSubmissionDialog({
  submitting,
  onClose,
  onSubmit
}: {
  submitting: boolean
  onClose: () => void
  onSubmit: () => void
}) {
  return (
    <Dialog
      title="Confirm submission"
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Go back
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={submitting}>
            {submitting ? 'Submitting' : 'Submit'}
          </Button>
        </DialogButtons>
      }
    >
      <div className="space-y-2">
        <p>
          {`Please submit your questionnaire only once you have provided your documents. This will help us stay organized.`}
        </p>
        <p>
          You can leave and come back at any time using the link in your invitation email since your
          information has been automatically saved.
        </p>
        <p>Are you sure you would like to submit the questionnaire?</p>
      </div>
    </Dialog>
  )
}

function PreferCompletionBeforeSubmissionWarningDialog({
  submitting,
  onClose,
  onSubmit
}: {
  submitting: boolean
  onClose: () => void
  onSubmit: () => void
}) {
  return (
    <Dialog
      title="Missing items"
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onSubmit} disabled={submitting}>
            {submitting ? 'Submitting' : 'Submit anyway'}
          </Button>
          <Button variant="primary" onClick={onClose}>
            Go back
          </Button>
        </DialogButtons>
      }
    >
      <div className="space-y-2">
        <p>
          {`We noticed you're still waiting to receive some documents. We would prefer you upload
            these documents before submitting your questionnaire. This will help us stay organized.`}
        </p>
        <p>
          Once you have the missing documents, simply return to the questionnaire using the link in
          your invitation email and add them.
        </p>
        <p>
          All of your work and uploads have been automatically saved, so you may leave and come back
          later. Thank you.
        </p>
      </div>
    </Dialog>
  )
}
