import { DrawOp } from '../draw'
import {
  addPadding,
  constrainSize,
  Constraints,
  hAlignRect,
  Pad,
  Rect,
  validateSize,
  vAlignRect
} from '../../util/geom'
import { Box, Renderer } from './interface'

export const boxRenderer: Renderer<Box> = {
  getSize(props, constraints) {
    return validateSize(
      props,
      constraints,
      constrainSize({ width: props.width, height: props.height }, constraints)
    )
  },
  render(props, origin, constraints, context) {
    const size = this.getSize(props, constraints, context)

    const vAlign = props.vAlign ?? 'start'
    const hAlign = props.hAlign ?? 'start'
    const padding = props.padding ?? Pad.zero
    const children = props.children ?? []

    const rect: Rect = { ...origin, ...size }

    const op: DrawOp = {
      type: 'rect',
      rect: { ...origin, ...size },
      backgroundColor: props.backgroundColor,
      borderWidth: props.borderWidth,
      borderColor: props.borderColor
    }

    // no children so we just draw the rect itself
    if (children.length == 0) return op

    const child = children[0]
    const childRenderer = context.rendererFor(child.type)

    var renderRect = rect
    if (padding) renderRect = addPadding(rect, padding)

    const childConstraints: Constraints = {
      width: renderRect.width,
      height: renderRect.height
    }

    const childSize = childRenderer.getSize(child, childConstraints, context)

    renderRect = vAlignRect({ ...renderRect, ...childSize }, rect, vAlign)
    renderRect = hAlignRect({ ...renderRect, ...childSize }, rect, hAlign)

    const renderChildOp = childRenderer.render(
      child,
      { x: renderRect.x, y: renderRect.y },
      childConstraints,
      context
    )

    return {
      type: 'group',
      ops: [op, renderChildOp]
    }
  }
}
