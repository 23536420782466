export type STBulkSendQuestionnairesState =
  | {
      status: 'editTemplate'
      organizationId: string
      folderIds: string[]
    }
  | {
      status: 'previewRecipients' | 'sending' | 'sent'
      organizationId: string
      folderIds: string[]
    }
import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { stFolderListModule } from '@features/st-folder-list/st-folder-list-module'
import { defineModule } from '@st/redux'
import { Folder } from '@st/sdk'

export type STBulkSendQuestionnairesMessage =
  | { type: 'editTemplate' }
  | { type: 'previewRecipients' }
  | { type: 'send' }
  | { type: 'sendCompleted' }
  | { type: 'confirmEnrollInReminders' }
  | { type: 'editFolder'; folder: Folder }
  | SDKResponse

type STBulkSendQuestionnairesInit = {
  organizationId: string
  folderIds: string[]
}

type STBulkSendQuestionnairesDeps = {
  stFolderList: typeof stFolderListModule
}

export const stBulkSendQuestionnairesModule = defineModule<
  STBulkSendQuestionnairesState,
  STBulkSendQuestionnairesMessage,
  STBulkSendQuestionnairesInit,
  { sdk: SDKMessage },
  STBulkSendQuestionnairesDeps
>({
  name: 'stBulkSendQuestionnaires',
  deps: { stFolderList: stFolderListModule },
  init: ({ organizationId, folderIds }, _, { stFolderList }) => {
    const folderGroups = selBulkSendSelectedFolders(folderIds, stFolderList.items)
    const sortedFolders = [...folderGroups.missingEmails, ...folderGroups.sendable]

    return {
      status: 'editTemplate',
      organizationId: organizationId,
      folderIds: sortedFolders.map((f) => f.id)
    }
  },
  handle: (state, message, _, deps) => {
    switch (message.type) {
      case 'editTemplate':
        return {
          ...state,
          status: 'editTemplate'
        }
      case 'previewRecipients':
        return { ...state, status: 'previewRecipients' }
      case 'send':
        const { sendable } = selBulkSendSelectedFolders(state.folderIds, deps.stFolderList.items)
        return [
          {
            ...state,
            status: 'sending'
          },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/bulkSendQuestionnaireEmails',
                organizationId: state.organizationId,
                folderIds: sendable.map((f) => f.id)
              }
            }
          }
        ]
      case 'sendCompleted':
        return { ...state, status: 'sent' }
      case 'response':
        switch (message.operation.type) {
          case 'folders/bulkSendQuestionnaireEmails':
            return { ...state, status: 'sent' }
          default:
            return state
        }
      default:
        return state
    }
  }
})

export type BulkSendFolderGroups = {
  folders: Folder[]
  sendable: Folder[]
  missingEmails: Folder[]
}

export function selBulkSendSelectedFolders(folderIds: string[], folders: Folder[]) {
  const selectedFolders = folderIds
    .map((id) => folders.find((f) => f.id == id))
    .filter((f) => f != null)
  const groups: BulkSendFolderGroups = {
    sendable: [],
    missingEmails: [],
    folders: selectedFolders
  }
  for (const folder of selectedFolders) {
    if (folder.memberships.length == 0) {
      groups.missingEmails.push(folder)
    } else {
      groups.sendable.push(folder)
    }
  }
  return groups
}
