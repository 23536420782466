import { MultiSelectConfig, NONE_OPTION } from '@st/ui-config'
import { setContains, setRemove, setToggle } from '@st/util/array-set'
import { MultiSelectOption } from '@theme'
import { ConfigInputProps } from '@util/input-component'

type MultiSelectProps = ConfigInputProps<MultiSelectConfig, string[]>

export function MultiSelect({ value, options, onChange }: MultiSelectProps) {
  function onToggleOption(key: string): void {
    const newValue = newSelectedValue(key, value)
    onChange(newValue)
  }

  const optionsComponents = [...options, NONE_OPTION].map((opt) => {
    const selected = setContains(value, opt.key)
    return (
      <MultiSelectOption
        {...opt}
        key={opt.key}
        selected={selected}
        onClick={() => onToggleOption(opt.key)}
      >
        {opt.label}
      </MultiSelectOption>
    )
  })

  return (
    <div className="flex w-full flex-col items-stretch justify-start gap-4 p-1">
      {optionsComponents}
    </div>
  )
}

/**
 * Given the currently {@link selectedKeys} and the {@link key} that is just
 * about to be toggled, return what the new selected keys should be.
 *
 * This takes into consideration that {@link NONE_OPTION} is mutually exclusive,
 * with other options being selected.
 */
function newSelectedValue(key: string, selectedKeys: string[]): string[] {
  const noneKey = NONE_OPTION.key
  // Selecting none should deselect the other options
  // because saying "none of the above" AND selecting options above
  // would be a contradiction.
  if (key == noneKey && !setContains(selectedKeys, noneKey)) {
    return [noneKey]
  }
  // If none is selected, but you select an option above, we
  // want to deselect none because "none of the above" no longer applies
  else if (key != noneKey && setContains(selectedKeys, noneKey)) {
    return setToggle(setRemove(selectedKeys, noneKey), key)
  }
  return setToggle(selectedKeys, key)
}
