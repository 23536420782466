import {
  ChangeEvent,
  DragEvent,
  DragEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

type FileDropHandlers = {
  onDragOver: DragEventHandler
  onDragEnter: DragEventHandler
  onDragLeave: DragEventHandler
  onDrop: DragEventHandler
}
type FileDropHook = {
  isDragging: boolean
  dropHandlers: FileDropHandlers
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type DropHandler = (files: DataTransfer) => void

function isFileDrag(e: DragEvent) {
  return Array.from(e.dataTransfer.items).some((item) => item.kind === 'file')
}

export function useFileDrop(onFilesPicked: DropHandler): FileDropHook {
  const [dragCounter, setDragCounter] = useState(0)

  const onDragOver = useCallback((e: DragEvent) => {
    if (!isFileDrag(e)) {
      return
    }

    e.preventDefault()
  }, [])

  const onDragEnter = useCallback((e: DragEvent) => {
    if (!isFileDrag(e)) {
      return
    }

    setDragCounter((n) => n + 1)
  }, [])

  const onDragLeave = useCallback((e: DragEvent) => {
    if (!isFileDrag(e)) {
      return
    }

    setDragCounter((n) => n - 1)
  }, [])

  useEffect(() => {
    function handleWindowDrop() {
      setDragCounter(0)
    }

    window.addEventListener('drop', handleWindowDrop, true)

    return () => {
      window.removeEventListener('drop', handleWindowDrop, true)
    }
  }, [])

  const onDrop = useCallback(
    (e: DragEvent) => {
      if (!isFileDrag(e)) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      setDragCounter(0)

      onFilesPicked(e.dataTransfer)
    },
    [onFilesPicked]
  )

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files || files.length == 0) {
        return
      }
      const dt = new DataTransfer()
      for (const f of files) dt.items.add(f)
      onFilesPicked(dt)
    },
    [onFilesPicked]
  )

  const dropHandlers = useMemo(() => {
    return { onDragOver, onDragEnter, onDragLeave, onDrop }
  }, [onDragOver, onDragEnter, onDragLeave, onDrop])

  return {
    isDragging: dragCounter > 0,
    dropHandlers: dropHandlers,
    onInputChange: onInputChange
  }
}
