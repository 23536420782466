import { Box, Label, Table } from '@st/pdf'
import { ReactNode } from 'react'
import { Align } from '../util/geom'
import { Pad } from '../util/geom'
const DEFAULT_FLEX = () => 1

type FormTableProps = {
  rowCount: number
  colCount: number

  colFlex?: (colIndex: number) => number
  children: (rowIndex: number, colIndex: number) => ReactNode

  rowHeader?: (rowIndex: number) => string
  colHeader?: (colIndex: number) => string
}
export function FormTable({
  colCount,
  rowCount,
  colFlex = DEFAULT_FLEX,
  colHeader,
  rowHeader,
  children
}: FormTableProps) {
  const hasExtraCol = !!rowHeader
  const hasExtraRow = !!colHeader

  // If we have a col header, we need an extra row at the top for it
  const totalRowCount = rowCount + (hasExtraRow ? 1 : 0)
  // If we have a row header we need an extra col on the left
  const totalColCount = colCount + (hasExtraCol ? 1 : 0)

  return (
    <Table
      colCount={totalColCount}
      rowCount={totalRowCount}
      rowHeight={() => 20}
      colFlex={(colIndex) => {
        if (hasExtraCol) colIndex -= 1

        // hardcode to 1 for now until we find a good way to handle
        if (colIndex == -1) return 1

        return colFlex(colIndex)
      }}
    >
      {(rowIndex, colIndex) => {
        // if there are headers we shift the rows/columns
        // so that negative -1 prepresents a header coordinate
        // this simplifies how we can handle all of the cases
        if (hasExtraRow) rowIndex -= 1
        if (hasExtraCol) colIndex -= 1

        // if there are headers on both sides, the top-left corner should be empty
        if (rowIndex == -1 && colIndex == -1) return <Box height={0} width={0} />
        else if (rowIndex == -1)
          return <TableHeader hAlign="end">{colHeader!(colIndex)}</TableHeader>
        else if (colIndex == -1)
          return <TableHeader hAlign="end">{rowHeader!(rowIndex)}</TableHeader>

        return (
          <Box width={Infinity} height={20} padding={Pad.only({ left: 2 })}>
            {children(rowIndex, colIndex)}
          </Box>
        )
      }}
    </Table>
  )
}

function TableHeader({ children, hAlign }: { children: string; hAlign: Align }) {
  return (
    <Box width={Infinity} height={20} vAlign="center" hAlign={hAlign}>
      <Label fontSize={8} bold={true}>
        {children}
      </Label>
    </Box>
  )
}
