import * as Sentry from '@sentry/browser'

export function initSentry() {
  Sentry.init({
    enabled: process.env.NEXT_PUBLIC_SENTRY_DSN ? true : false,
    environment: process.env.NEXT_PUBLIC_ENV,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['api.stanfordtax.com', 'api.stanfordtax-staging.com'],
    ignoreErrors: [
      // This looks to be 3rd party lib, and apparently it's a harmless error
      // Ignoring this error to prevent too much noise in Sentry
      'ResizeObserver loop completed with undelivered notifications'
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.extraErrorDataIntegration({ depth: 4, captureErrorCause: true }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllInputs: true,
        maskAllText: false,
        networkRequestHeaders: ['x-request-id'],
        networkCaptureBodies: true,
        networkDetailAllowUrls: [
          'https://api.stanfordtax.com',
          'https://api.stanfordtax-staging.com'
        ]
      })
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      // we filter out data: and blob: urls
      // it does not help us debug and takes up space in the payload
      if (
        (breadcrumb.category === 'fetch' || breadcrumb.category == 'xhr') &&
        breadcrumb?.data?.url &&
        filterBreadcrumbURL(breadcrumb.data.url)
      ) {
        return null
      }

      return breadcrumb
    },
    beforeSend: (event, hint) => {
      const reduxStoreStates = (window as any).__reduxStoreStates
      if (reduxStoreStates?.st) {
        hint.attachments = [
          { filename: 'redux-snapshot.json', data: JSON.stringify(reduxStoreStates?.st) }
        ]
      }
      return event
    }
  })
}

function filterBreadcrumbURL(url: string) {
  // data and blob urls are very large and will cause sentry to choke
  // they are also unnecessary to log
  if (url.startsWith('data:')) return true
  if (url.startsWith('blob:')) return true

  // Tracking these analytics adds a lot of noise
  if (url.includes('posthog.com')) return true
  if (url.startsWith('https://a.')) return true // this is also posthog

  return false
}
