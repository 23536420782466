import { TextField } from '@components/text-field'
import { TextInputConfig } from '@st/ui-config'
import { QuestionTip } from '@st/theme'
import { formatInput } from '@st/util/input-format'
import { ConfigInputProps } from '@util/input-component'

type TextInputProps = ConfigInputProps<TextInputConfig, string> & {
  className?: string
  placeholder?: string
  onClick?: () => void
  onBlur?: () => void
  readOnly?: boolean
  autoFocus?: boolean
  rows?: number

  prevValue?: string
}
export function TextInput({
  className,
  inputFormat,
  value,
  label,
  tip,
  rows = 5,
  isMultiline,
  onChange,
  onBlur,
  placeholder,
  onClick,
  readOnly,
  autoFocus
}: TextInputProps) {
  return (
    <TextField
      className={className}
      tip={tip && <QuestionTip title={tip.title} body={tip.body} />}
      value={value}
      label={label}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      readOnly={readOnly}
      autoFocus={autoFocus}
      isMultiline={isMultiline ?? false}
      format={inputFormat ? (v) => formatInput(inputFormat, v) : undefined}
      mask={inputFormat == 'SocialSecurityNumber'}
    />
  )
}
