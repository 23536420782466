import { bookmarkRenderer } from './bookmark'
import { boxRenderer } from './box'
import { checkboxRenderer } from './checkbox'
import { expandRenderer, flexRenderer } from './flex'
import { hiddenInputRenderer } from './hidden-input'
import { Renderer } from './interface'
import { noteRenderer } from './note'
import { radioRenderer } from './radio'
import { selectRenderer } from './select'
import { textRenderer } from './text'
import { textInputRenderer } from './text-input'

export function rendererFor(type: string): Renderer<any> {
  switch (type) {
    case 'Text':
      return textRenderer
    case 'Flex':
      return flexRenderer
    case 'Box':
      return boxRenderer
    case 'Expand':
      return expandRenderer
    case 'TextInput':
      return textInputRenderer
    case 'HiddenInput':
      return hiddenInputRenderer
    case 'Radio':
      return radioRenderer
    case 'Checkbox':
      return checkboxRenderer
    case 'Bookmark':
      return bookmarkRenderer
    case 'Note':
      return noteRenderer
    case 'Select':
      return selectRenderer
    default:
      throw `No renderer for ${type} found`
  }
}
