import { annotationLayerModule, selSelectedAnnot } from '@features/st-annotation-layer'
import { pdfViewerModule } from '@features/st-pdf-viewer/module'
import { useProcess, useProcessState } from '@st/redux'
import { ReactNode } from 'react'
import { match } from 'ts-pattern'
import { TextTool, TextToolbar } from './tools/text-tool'

import { MinusIcon, PlusIcon } from '@st/theme/src/icons/24x24'

import { AIIcon, Button, Separator, ToggleButton, Toolbar, ToolbarSection } from '@st/theme'
import { useFeatureEnabled } from '@util/feature-flags'
import { DocTextAnnotation } from './field-types'

const TOOL_OPTIONS: ToolOption[] = [
  // { name: 'select', icon: <CursorTextIcon /> }
  // { name: 'rect', icon: <RectangleIcon /> },
  // { name: 'text', icon: <TextAUnderlineIcon /> },
  // { name: 'stamp', icon: <StampIcon /> },
  // { name: 'comment', icon: <AddCommentIcon /> },
  // { name: 'calculation', icon: <CalculatorIcon /> }
]

export function PDFDocToolbar() {
  const isDocumentAiFieldsEnabled = useFeatureEnabled('document-ai-fields')
  const pdfViewer = useProcess(pdfViewerModule)
  const annotationLayer = useProcess(annotationLayerModule)

  const selectedToolName = useProcessState(annotationLayerModule, (s) => s.activeToolName)
  const toolStates = useProcessState(annotationLayerModule, (s) => s.toolStates)
  const selectedTool = selectedToolName ? toolStates[selectedToolName] : undefined

  const sidebarType = useProcessState(annotationLayerModule, (s) => s.sidebarType)
  const selectedAnnot = useProcessState(annotationLayerModule, selSelectedAnnot)

  return (
    <Toolbar aria-label="Actions">
      <ToolbarSection>
        <Button
          variant="subtle"
          onClick={() =>
            pdfViewer.send({
              type: 'setZoom',
              zoom: Math.max(pdfViewer.getState().zoomLevel - 0.15, 0.15)
            })
          }
        >
          <MinusIcon />
        </Button>
        <Button
          variant="subtle"
          onClick={() =>
            pdfViewer.send({
              type: 'setZoom',
              zoom: pdfViewer.getState().zoomLevel + 0.15
            })
          }
        >
          <PlusIcon />
        </Button>

        {TOOL_OPTIONS.map((opt) => {
          return (
            <ToggleButton
              key={opt.name}
              isSelected={opt.name == selectedToolName}
              onChange={() => annotationLayer.send({ type: 'selectTool', toolName: opt.name })}
            >
              {opt.icon}
            </ToggleButton>
          )
        })}

        <div className="w-2" />
        <Separator />
        <div className="w-2" />

        {match({ annot: selectedAnnot, tool: selectedTool })
          .with({ annot: { type: 'text' } }, ({ annot, tool }) => (
            <TextToolbar
              tool={tool as TextTool}
              annot={annot as DocTextAnnotation}
              send={annotationLayer.send}
            />
          ))
          .with({ tool: { name: 'text' } }, ({ tool }) => (
            <TextToolbar tool={tool as TextTool} send={annotationLayer.send} />
          ))
          .otherwise(() => null)}
      </ToolbarSection>

      <ToolbarSection>
        {isDocumentAiFieldsEnabled && (
          <ToggleButton
            isSelected={sidebarType == 'field'}
            onClick={() => annotationLayer.send({ type: 'toggleSidebar', sidebarType: 'field' })}
          >
            <AIIcon className="size-6" />
          </ToggleButton>
        )}
        {/* <ToggleButton
          isSelected={sidebarType == 'comment'}
          onClick={() => annotationLayer.send({ type: 'toggleSidebar', sidebarType: 'comment' })}
        >
          <ChatIcon />
        </ToggleButton> */}
      </ToolbarSection>
    </Toolbar>
  )
}

type ToolOption = {
  name: string
  icon: ReactNode
}
