import { FormationDocument } from '@st/formation'
import { ReadWriteProvider, renderToPDF } from '@st/pdf'
import { defineTask } from '@st/redux'
import { FieldHint, STDocument, STDocumentType, STOpenFolderState, STSDK } from '@st/sdk'
import { getFormConfig } from '@st/tax-folder'
import { FormConfig } from '@st/ui-config'
import { sanitizeFilename } from '@st/util'
import { zip } from '@st/util/archive'
import { asyncSectionMap } from '@st/util/async'
import { isNotEmpty, JsonMap } from '@st/util/json-value'
import { matchesMimeType } from '@st/util/mime'
import { parseISO8601Time } from '@st/util/time'
import { Directory, fetchFile, XFile } from '@st/util/xfile'
import { download } from '@util/download'
import { mergePDFs } from '~/pdf-util/merge'
import { FormationChecklistDocument } from './formation-checklist-pdf'
import {
  DownloadError,
  selSelectedDocumentsForDownload,
  stDownloadFolderModule
} from './st-download-folder-module'
import {
  CHECKLIST_ITEMS_PER_PAGE,
  formatFolderEntityName,
  getBookmarkSections,
  selFieldComment,
  selFieldDecoration,
  stFolderModule
} from './st-folder-module'

type DownloadDocumentContext = {
  folderId: string
  sdk: STSDK
  format: 'pdf' | 'source'
}
export async function stDownloadDocumentHandler(
  doc: STDocument,
  { folderId, format, sdk }: DownloadDocumentContext
) {
  const url = await stDownloadURLHandler(doc, { folderId, format, sdk })
  if (url) {
    download(url, doc.name)
  }
}

async function stDownloadURLHandler(
  doc: STDocument,
  { folderId, format, sdk }: DownloadDocumentContext
): Promise<string | undefined> {
  if (matchesMimeType(doc.mimeType, 'application/formation-checklist')) {
    const state = await sdk.send({ type: 'folders/getOpenFolderState', folderId: folderId })
    const blob = await renderFormationChecklistToPDF(state)
    return URL.createObjectURL(blob)
  } else if (matchesMimeType(doc.mimeType, 'application/formation')) {
    const state = await sdk.send({ type: 'folders/getOpenFolderState', folderId: folderId })
    const formConfig = await getFormConfig(state.schemaId)
    const blob = await renderFormationToPDF({ openFolderState: state, formConfig: formConfig! })
    return URL.createObjectURL(blob)
  } else if (doc.urls.pdf && format == 'pdf') {
    return doc.urls.pdf
  } else if (doc.urls.sourceAttachment && format == 'source') {
    return doc.urls.sourceAttachment
  }
}

export type DownloadWorkpaperContext = {
  sdk: STSDK
}

export type DownloadSingleDocumentContext = {
  sdk: STSDK
}

export const downloadSourceDocument = defineTask(
  stFolderModule,
  async (folder, doc: STDocument, { sdk }: DownloadSingleDocumentContext) => {
    const folderId = folder.getState().folderState!.folderId
    const url = await stDownloadURLHandler(doc, {
      folderId,
      sdk,
      format: 'source'
    })

    if (url) {
      download(url, doc.name)
      await sdk.send({
        type: 'folders/markFolderDocumentsExported',
        folderId: folderId,
        documentIds: [doc.id],
        exportFormat: 'source'
      })
    }
  }
)

export const downloadWorkpaper = defineTask(
  stDownloadFolderModule,
  async (downloadFolder, arg, { sdk }: DownloadWorkpaperContext) => {
    const state = downloadFolder.getState()

    downloadFolder.send({ type: 'downloadStarted', format: 'pdf' })

    const folderId = state.folderId
    const sections = getBookmarkSections({
      documents: selSelectedDocumentsForDownload(state),
      documentTypes: state.downloadState!.documentTypes
    })

    const fileName = formatFolderEntityName(state.downloadState!.entities)

    const pdfSections = await asyncSectionMap<STDocumentType, STDocument, XFile>(
      sections,
      async (doc) => {
        const url = await stDownloadURLHandler(doc, { folderId, sdk, format: 'pdf' })
        const sourceUrl = await stDownloadURLHandler(doc, { folderId, sdk, format: 'source' })
        if (url) {
          return {
            type: 'file',
            name: doc.name,
            uri: url!,
            mimeType: 'application/pdf'
          } satisfies XFile
        } else {
          return {
            type: 'file',
            name: doc.name,
            uri: sourceUrl!,
            mimeType: doc.mimeType
          } satisfies XFile
        }
      }
    )

    const combinedResult = await mergePDFs(pdfSections, {
      getHeading: (h) => h.name,
      filename: `${fileName}.pdf`
    })

    if (!combinedResult.ok) {
      const errors: DownloadError[] = combinedResult.error.invalidDocuments.map((e) => {
        const document = state.downloadState!.documents.find((d) => d.name == e.file.name)
        return {
          documentId: document!.id,
          filename: document!.name,
          error: e.error
        }
      })
      downloadFolder.send({ type: 'downloadFailed', errors })
      return
    }

    download(await fetchFile(combinedResult.value).then((r) => r.blob()), combinedResult.value.name)

    downloadFolder.send({ type: 'downloadCompleted', format: 'pdf' })

    await sdk.send({
      type: 'folders/markFolderDocumentsExported',
      folderId: folderId,
      documentIds: state.selectedDocumentIds,
      exportFormat: 'pdf'
    })
    downloadFolder.send({ type: 'refreshFolderDownloadState' })
  }
)

export const downloadZip = defineTask(
  stDownloadFolderModule,
  async (downloadFolder, arg, { sdk }: DownloadWorkpaperContext) => {
    const state = downloadFolder.getState()

    downloadFolder.send({ type: 'downloadStarted', format: 'zip' })

    const folderId = state.folderId
    const sections = getBookmarkSections({
      documents: selSelectedDocumentsForDownload(state),
      documentTypes: state.downloadState!.documentTypes
    })

    const fileName = formatFolderEntityName(state.downloadState!.entities) + '.zip'

    const filesSections = await asyncSectionMap<STDocumentType, STDocument, XFile>(
      sections,
      async (doc, docIndex, documentType) => {
        const url = await stDownloadURLHandler(doc, { folderId, sdk, format: 'source' })
        const index = state.downloadState!.documentTypes.indexOf(documentType) + 1

        const baseFilename =
          sanitizeFilename(doc.name) + (documentType.id == 'stanfordtax' ? '.pdf' : '')

        const filename = [
          String(index).padStart(2, '0'), // prefix of document
          documentType.name, // document type name
          doc.uploadedAt ? formatDate(parseISO8601Time(doc.uploadedAt)) : null, // yyyy-mm-dd
          baseFilename
        ]
          .filter(isNotEmpty)
          .join('_')

        return {
          type: 'file',
          name: filename,
          uri: url!,
          lastModified: doc.uploadedAt
        } satisfies XFile
      }
    )

    const dir: Directory = { type: 'directory', name: 'root', entries: {} }
    for (const section of filesSections) {
      for (const file of section.items) {
        dir.entries[file.name] = file
      }
    }

    const zipResult = await zip(dir)

    if (zipResult.ok) {
      download(zipResult.value, fileName)
    }

    downloadFolder.send({ type: 'downloadCompleted', format: 'zip' })

    await sdk.send({
      type: 'folders/markFolderDocumentsExported',
      folderId: folderId,
      documentIds: state.selectedDocumentIds,
      exportFormat: 'zip'
    })
    downloadFolder.send({ type: 'refreshFolderDownloadState' })
  }
)

function formatDate(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

async function renderFormationChecklistToPDF(
  opts: Pick<
    STOpenFolderState,
    'folder' | 'checklistItems' | 'documents' | 'documentTypes' | 'categories'
  >
): Promise<Blob> {
  const doc = await renderToPDF(
    <FormationChecklistDocument
      folder={opts.folder}
      checklistItems={opts.checklistItems}
      documents={opts.documents}
      documentTypes={opts.documentTypes}
      categories={opts.categories}
      checklistItemsPerPage={CHECKLIST_ITEMS_PER_PAGE}
    />
  )

  const data = await doc.save()

  return new Blob([data], { type: 'application/pdf' })
}

type FormationFieldMeta = {
  key: string
  text: string
}

type FormationFieldComment = {
  key: string
  body: string
  text: string
}

type FormationData = {
  formConfig: FormConfig
  fieldHints: FormationFieldMeta[]
  inputs: JsonMap
}

async function renderFormationToPDF({
  formConfig,
  openFolderState
}: {
  formConfig: FormConfig
  openFolderState: STOpenFolderState
}): Promise<Blob> {
  const doc = await renderToPDF(
    <ReadWriteProvider
      useRead={(key) => openFolderState.inputs[key]}
      useReadInputs={(sel) => sel(openFolderState.inputs)}
      useFieldDecoration={(key) => {
        return selFieldDecoration(openFolderState, key)
      }}
      useReadFieldComment={(key) => selFieldComment(openFolderState, key)}
    >
      <FormationDocument config={formConfig} />
    </ReadWriteProvider>
  )

  const data = await doc.save()
  return new Blob([data], { type: 'application/pdf' })
}
