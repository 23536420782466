import { ReactNode, createContext, useContext } from 'react'
import { ReduxStore } from './redux-store'

type Props<S, E> = {
  children: ReactNode
  store: ReduxStore
}
export function StoreProvider<S, E>({ children, store }: Props<S, E>) {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export function useStore(): ReduxStore {
  return useContext(StoreContext)!
}

const StoreContext = createContext<ReduxStore | undefined>(undefined)
