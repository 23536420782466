import { useAuthClient } from '@features/app-hooks'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import { BasicUser } from '@st/sdk'
import { Button, Dialog, DialogButtons, FullPageCard, Modal } from '@st/theme'
import { inflect } from '@st/util/inflect'
import { ReactNode, useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import {
  selLastSentTo,
  selPossibleUsers,
  STQuestionnaireLoginMessage,
  stQuestionnaireLoginModule,
  STQuestionnaireLoginState,
  STQuestionnaireLoginStateNotFound,
  STQuestionnaireLoginStateUnauthorized
} from './st-questionnaire-login-module'
import { OrganizationLogo, useOrganization } from '@features/organizations'
import { Title } from '@st/react-util/title'

export function STQuestionnaireLoginPage({
  folderId,
  children
}: {
  folderId: string
  children: ReactNode
}) {
  const authClient = useAuthClient()

  const stQuestionnaireLogin = useProcess(stQuestionnaireLoginModule, { folderId })
  const state = useProcessState(stQuestionnaireLoginModule)

  useEffect(() => {
    const unsubscribe = authClient.subscribeToAuthStatus((event) => {
      if (event.type == 'loggedIn') {
        stQuestionnaireLogin.send({ type: 'loggedIn', user: event.user })
      } else if (event.type == 'loggedOut') {
        stQuestionnaireLogin.send({ type: 'loggedOut' })
      }
    })
    return unsubscribe
  }, [authClient])

  return match(state)
    .with({ status: 'loading' }, () => <FullPageCard />)
    .with({ status: 'notFound' }, (state) => <QuestionnaireNotFound state={state} />)
    .with({ status: 'unauthorized' }, (state) => (
      <UnauthorizedAccess
        state={state}
        send={stQuestionnaireLogin.send}
        onClickLogout={() => {
          authClient.logout()
        }}
      />
    ))
    .with({ status: 'clickEmailVerificationLink' }, () => (
      <STQuestionnaireLoginView state={state} send={stQuestionnaireLogin.send} />
    ))
    .with({ status: 'sendingVerificationEmail' }, () => (
      <STQuestionnaireLoginView state={state} send={stQuestionnaireLogin.send} />
    ))
    .with({ status: 'sendVerificationEmail' }, () => (
      <STQuestionnaireLoginView state={state} send={stQuestionnaireLogin.send} />
    ))
    .with({ status: 'verificationSuccessful' }, () => (
      <ClickedVerificationLink state={state} send={stQuestionnaireLogin.send} />
    ))
    .with({ status: 'authorized' }, () => {
      return <>{children}</>
    })
    .run()
}

export function STQuestionnaireLoginView({
  state,
  send
}: {
  state: STQuestionnaireLoginState
  send: (message: STQuestionnaireLoginMessage) => void
}) {
  const [showUserSelector, setShowUserSelector] = useState(false)

  const possibleUsers = selPossibleUsers(state)
  const logo = state.loggedOutFolder?.organization.logo

  return (
    <FullPageCard
      footer={
        <p className="mt-10 text-center text-sm text-gray-500">
          Powered by StanfordTax, Inc.
          <br /> 222 Broadway, New York, 10032
        </p>
      }
    >
      <Title>{state.loggedOutFolder?.organization.name}</Title>
      <div className="flex flex-col gap-1">
        {logo && <OrganizationLogo className="mx-auto" src={logo} size={80} />}

        <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          {state.loggedOutFolder?.organization.name ?? ' '}
        </h3>
      </div>

      <h1 className="mt-8 text-center text-3xl">
        {formatFolderEntityName(state.loggedOutFolder!.entities, 'client')}
      </h1>
      <h2 className="mb-8 text-center text-2xl">
        {state.loggedOutFolder ? `${state.loggedOutFolder.year} Tax Questionnaire` : ''}
      </h2>

      <Button
        variant="primary"
        className="m-auto"
        disabled={state.status == 'loading' || possibleUsers.length == 0}
        onClick={() => {
          if (possibleUsers.length > 1) {
            // multiple people in the folder, we need to ask them who they are
            setShowUserSelector(true)
          } else {
            // single user, send immediaetly
            send({
              type: 'clickSendVerificationEmail',
              continueUrl: window.location.href,
              recipientUserId: possibleUsers[0].id
            })
          }
        }}
      >
        {match(state)
          .with({ status: 'sendVerificationEmail' }, () => 'Send Verification Email')
          .with({ status: 'sendingVerificationEmail' }, () => 'Sending Verification Email')
          .with({ status: 'clickEmailVerificationLink' }, () => 'Resend Verification Email')
          .otherwise(() => null)}
      </Button>

      {state.status == 'sendVerificationEmail' && possibleUsers.length == 0 && (
        <p className="mt-2 text-center text-sm text-gray-600">
          No email addresses have been granted access to this questionnaire. Please contact us to
          grant you access.
        </p>
      )}

      {showUserSelector && (
        <Modal isOpen={showUserSelector}>
          <UserSelector
            users={selPossibleUsers(state)}
            onClose={() => setShowUserSelector(false)}
            onSelect={(user) => {
              setShowUserSelector(false)
              send({
                type: 'clickSendVerificationEmail',
                continueUrl: window.location.href,
                recipientUserId: user.id
              })
            }}
          />
        </Modal>
      )}

      {match(state)
        .with({ status: 'clickEmailVerificationLink' }, (state) => (
          <p className="mt-2 text-center text-sm text-gray-600">
            Sent verification email to {selLastSentTo(state)?.email}
          </p>
        ))
        .otherwise(() => null)}
    </FullPageCard>
  )
}

function UserSelector({
  users,
  onClose,
  onSelect
}: {
  users: BasicUser[]
  onClose: () => void
  onSelect: (user: BasicUser) => void
}) {
  return (
    <Dialog
      title="Select your email"
      subtitle="Please select the email you would like to verify with."
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col items-stretch gap-2">
        {users.map((user) => (
          <Button variant="default" size="l" key={user.id} onClick={() => onSelect(user)}>
            {user.email}
          </Button>
        ))}
      </div>
    </Dialog>
  )
}

export function ClickedVerificationLink({
  state
}: {
  state: STQuestionnaireLoginState
  send: (message: STQuestionnaireLoginMessage) => void
}) {
  return (
    <FullPageCard
      footer={
        <p className="mt-10 text-center text-sm text-gray-500">
          Powered by StanfordTax, Inc.
          <br /> 222 Broadway, New York, 10032
        </p>
      }
    >
      <Title>{state.loggedOutFolder?.organization.name}</Title>
      <div className="flex flex-col items-center gap-1">
        {state.loggedOutFolder?.organization.logo && (
          <OrganizationLogo src={state.loggedOutFolder.organization.logo} size={80} />
        )}

        <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          {state.loggedOutFolder!.organization.name}
        </h3>
      </div>

      <h1 className="mt-12 text-center text-lg">
        You verified your email. You can close this tab.
      </h1>
    </FullPageCard>
  )
}

export function QuestionnaireNotFound({ state }: { state: STQuestionnaireLoginStateNotFound }) {
  return (
    <FullPageCard>
      <div className="flex flex-col items-center gap-1">
        <div className="text-center text-lg">
          <p>Questionnaire with id {state.folderId} was not found.</p>
        </div>
      </div>
    </FullPageCard>
  )
}

export function UnauthorizedAccess({
  state,
  onClickLogout
}: {
  state: STQuestionnaireLoginStateUnauthorized
  send: (message: STQuestionnaireLoginMessage) => void
  onClickLogout: () => void
}) {
  const allowedEmails = selPossibleUsers(state).map((u) => u.email)

  return (
    <FullPageCard
      footer={
        <p className="mt-10 text-center text-sm text-gray-500">
          Powered by StanfordTax, Inc.
          <br /> 222 Broadway, New York, 10032
        </p>
      }
    >
      <Title>{state.loggedOutFolder.organization.name}</Title>
      <div className="flex flex-col items-center gap-1">
        <img
          alt="Logo"
          src={state.loggedOutFolder?.organization.logo}
          className="mx-auto h-10 w-auto"
        />

        <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          {state.loggedOutFolder!.organization.name}
        </h3>

        <div className="mt-12 text-center text-lg">
          <p>Your email {state.currentUser.email} is unauthorized to view this questionnaire.</p>
        </div>

        <Button className="mt-4" variant="primary" onClick={onClickLogout}>
          Logout
        </Button>

        <p className="mt-6 text-center text-sm">
          The following {inflect(allowedEmails.length, 'email')} can access this questionnaire:{' '}
          {allowedEmails.join(', ')}
        </p>
      </div>
    </FullPageCard>
  )
}
