// Originally from:
// https://github.com/marp-team/marp-cli/blob/9e0eff5f9d9530577458e93769cd2b0000958a7d/src/utils/pdf.ts

import { PDFDocument, PDFHexString, PDFRef } from 'pdf-lib'
import type { FontStyle, FontWeight } from './document/renderer'

export type PDFOutlineNode = {
  /**
   * The title of the bookmark
   */
  title: string
  /**
   * The 0-based page index to link to
   */
  to?: PageIndex

  /**
   * The optional children of the node. If this is present, the node will have an expand/collapse
   * chevron to expand/collapse its children.
   */
  children?: PDFOutlineNode[]

  /**
   * Whether the node is open or collapsed
   */
  open?: boolean

  /**
   * The font weight of the node. Defaults to normal.
   * Not supported in all viewers but is supported in Adobe Acrobat.
   */
  fontWeight?: FontWeight

  /**
   * The font style of the node. Defaults to normal but can be italic.
   * Not supported in all viewers but is supported in Adobe Acrobat.
   */
  fontStyle?: FontStyle
}

export type PageIndex = number

export async function setOutlineWithPDFLib(doc: PDFDocument, nodes: PDFOutlineNode[]) {
  // Refs
  const rootRef = doc.context.nextRef()

  const refMap = new WeakMap<PDFOutlineNode, PDFRef>()
  for (const outline of flatten(nodes)) {
    refMap.set(outline, doc.context.nextRef())
  }

  const pageRefs = (() => {
    const refs: PDFRef[] = []

    doc.catalog.Pages().traverse((kid, ref) => {
      if (kid.get(kid.context.obj('Type'))?.toString() === '/Page') {
        refs.push(ref)
      }
    })

    return refs
  })()

  function createOutline(outlines: readonly PDFOutlineNode[], parent: PDFRef) {
    const { length } = outlines

    for (let i = 0; i < length; i += 1) {
      const outline = outlines[i]
      const outlineRef = refMap.get(outline)!

      const destOrAction = (() => {
        // if (typeof outline.to === 'string') {
        //   // URL
        //   return { A: { S: 'URI', URI: PDFHexString.fromText(outline.to) } }
        // } else
        if (typeof outline.to === 'number') {
          return { Dest: [pageRefs[outline.to], 'Fit'] }
        } else if (Array.isArray(outline.to)) {
          const page = doc.getPage(outline.to[0])
          const width = page.getWidth()
          const height = page.getHeight()

          return {
            Dest: [
              pageRefs[outline.to[0]],
              'XYZ',
              width * outline.to[1],
              height * outline.to[2],
              null
            ]
          }
        }
        return {}
      })()

      const childrenDict = (() => {
        if (outline.children && outline.children.length > 0) {
          createOutline(outline.children, outlineRef)

          return {
            First: refMap.get(outline.children[0])!,
            Last: refMap.get(outline.children[outline.children.length - 1])!,
            Count: getOpeningCount(outline.children) * (outline.open ? 1 : -1)
          }
        }
        return {}
      })()

      doc.context.assign(
        outlineRef,
        doc.context.obj({
          Title: PDFHexString.fromText(outline.title),
          Parent: parent,
          ...(i > 0 ? { Prev: refMap.get(outlines[i - 1])! } : {}),
          ...(i < length - 1 ? { Next: refMap.get(outlines[i + 1])! } : {}),
          ...childrenDict,
          ...destOrAction,
          F: (outline.fontStyle == 'italic' ? 1 : 0) | (outline.fontWeight == 'bold' ? 2 : 0)
        })
      )
    }
  }

  createOutline(nodes, rootRef)

  // Root
  const rootCount = getOpeningCount(nodes)

  doc.context.assign(
    rootRef,
    doc.context.obj({
      Type: 'Outlines',
      ...(rootCount > 0
        ? {
            First: refMap.get(nodes[0])!,
            Last: refMap.get(nodes[nodes.length - 1])!
          }
        : {}),
      Count: rootCount
    })
  )

  doc.catalog.set(doc.context.obj('Outlines'), rootRef)

  function walk(
    nodes: readonly PDFOutlineNode[],
    callback: (outline: PDFOutlineNode) => void | boolean // stop walking to children if returned false
  ) {
    for (const outline of nodes) {
      const ret = callback(outline)
      if (outline.children && ret !== false) walk(outline.children, callback)
    }
  }

  function flatten(nodes: readonly PDFOutlineNode[]) {
    const result: PDFOutlineNode[] = []

    walk(nodes, (node) => void result.push(node))
    return result
  }

  function getOpeningCount(node: readonly PDFOutlineNode[]) {
    let count = 0

    walk(node, (outline) => {
      count += 1
      return !!outline.open
    })

    return count
  }
}
