import { GridTableColumnConfig, GridTableRowConfig, LabeledGridTableConfig } from '@st/ui-config'
import { ReadHook, WriteHook } from '@st/pdf'
import { toStr } from '@st/util/cast'
import { cn } from '@util/cn'
import { FormTextInput } from './form-text-input'

type Props = LabeledGridTableConfig & {
  className?: string
  useRead: ReadHook
  useWrite: WriteHook
}
export function LabeledGridTable({
  className,
  columns,
  rows,
  userInputKey,
  useRead,
  useWrite
}: Props) {
  const totalWidth = columns.reduce((acc, b) => acc + (b.width ?? 1), 0)

  return (
    <table className={cn('form-table-left-align', className)}>
      <thead>
        <tr className="text-lg">
          <th style={{ width: '1%' }}></th>
          {columns.map((c) => {
            return (
              <th style={{ width: percentWidth(c.width ?? 0, totalWidth) }} key={c.key}>
                {c.title}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <GridTableRow
            key={row.key}
            row={row}
            columns={columns}
            useRead={useRead}
            useWrite={useWrite}
            buildCellInputKey={(row, col) => {
              // For single-row tables, we don't include the index as part of the user input key
              return rows.length > 1
                ? `${userInputKey}.${row.key}.${col.key}`
                : `${userInputKey}.${col.key}`
            }}
          />
        ))}
      </tbody>
    </table>
  )
}

type BuildCellInputKey = (row: GridTableRowConfig, column: GridTableColumnConfig) => string

function percentWidth(width: number, totalWidth: number): string {
  return Math.round((width * 100) / totalWidth) + '%'
}

type GridTableRowProps = {
  row: GridTableRowConfig
  columns: GridTableColumnConfig[]
  buildCellInputKey: BuildCellInputKey
  useRead: ReadHook
  useWrite: WriteHook
}
function GridTableRow({ row, columns, buildCellInputKey, useRead, useWrite }: GridTableRowProps) {
  return (
    <tr>
      {/* nowrap forces this to expand to take up all the space  */}
      <td className="whitespace-nowrap text-left text-base">{row.title}</td>
      {columns.map((column) => {
        return (
          <td key={column.key}>
            <GridCellInput
              inputKey={buildCellInputKey(row, column)}
              useRead={useRead}
              useWrite={useWrite}
            />
          </td>
        )
      })}
    </tr>
  )
}

function GridCellInput({
  inputKey,
  useRead,
  useWrite
}: {
  inputKey: string
  useRead: ReadHook
  useWrite: WriteHook
}) {
  const value = toStr(useRead(inputKey))
  const write = useWrite()

  return <FormTextInput label="" value={value} onChange={(v) => write({ [inputKey]: v })} />
}
