import { STSDK } from '@st/sdk'
import { RemoteSocket } from '@util/remote-socket'
import { ReactNode, createContext, useContext } from 'react'
import { AnalyticsClient } from './analytics/core'
import { AuthClientAdapter } from './auth/auth-client-adapter'
import { FileStorageProvider } from './file-storage/file-storage-provider'
import { FileStorageClient } from './file-storage/adapters/file-storage-client'

export type AppDeps = {
  authClient: AuthClientAdapter
  storageAdapter: FileStorageClient
  analytics: AnalyticsClient
  sdk: STSDK
  socket: RemoteSocket
} & Disposable

type Props = {
  children: ReactNode
  deps: AppDeps
}
export function AppDepsProvider({ children, deps }: Props) {
  return (
    <AppDepsContext.Provider value={deps}>
      <FileStorageProvider adapter={deps.storageAdapter}>{children}</FileStorageProvider>
    </AppDepsContext.Provider>
  )
}

export function useAppDeps(): AppDeps {
  return useContext(AppDepsContext)!
}

export function useSDK(): STSDK {
  return useContext(AppDepsContext)!.sdk
}

const AppDepsContext = createContext<AppDeps | undefined>(undefined)
