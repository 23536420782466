import { ReactNode } from 'react'
import { Size } from '../util/geom'
import { toTreeNode } from '../el-reconciler'
import { DrawOp } from './draw'
import {
  Document,
  Font,
  RenderContext,
  RenderDebugOpts,
  TextMeasurement,
  pageRenderer
} from './renderer'
import { rendererFor } from './renderer/renderer-for'

export type PDFDocumentStruct = {
  pages: PDFPageStruct[]
}

export type PDFPageStruct = {
  size: Size
  drawOp: DrawOp
}

type RenderPDFOpts = {
  debug?: RenderDebugOpts
  defaultFont?: Font
  textSize: TextMeasurement
}
export function renderToPDFStruct(element: ReactNode, opts: RenderPDFOpts): PDFDocumentStruct {
  const doc = toTreeNode(element) as Document

  const renderContext: RenderContext = {
    defaultFont: opts.defaultFont ?? {
      family: 'Helvetica',
      style: 'normal',
      weight: 'normal'
    },
    textSize: opts.textSize,
    debug: opts.debug,
    rendererFor
  }

  const pageStructures = doc.children.map<PDFPageStruct>((page) => {
    return {
      size: { width: page.width, height: page.height },
      drawOp: pageRenderer.render(
        page,
        { x: 0, y: 0 },
        { width: page.width, height: page.height },
        renderContext
      )
    }
  })

  return {
    pages: pageStructures
  }
}
