/**
 * We keep a global to handle collisions in the same ms
 */
const MAX_COUNTER = 1 << 18 // 2^18
let lastTimestamp = 0
let counter = 0

/**
 * If new ms => reset, else increment.
 */
function nextClockValue(): [number, number] {
  const now = Date.now()
  if (now > lastTimestamp) {
    lastTimestamp = now
    counter = 0
  } else {
    counter++
    if (counter >= MAX_COUNTER) {
      lastTimestamp++
      counter = 0
    }
  }
  return [lastTimestamp, counter]
}

function uuidv7bin(): Uint8Array {
  // 1) 16 bytes of output
  const out = new Uint8Array(16)
  let bitOffset = 0

  // 2) Get 7 random bytes => 56 bits
  const rand7 = new Uint8Array(7)
  crypto.getRandomValues(rand7)

  // 3) If we want Elixir's "rand_a=17 bits" approach, we can parse it:
  //    but let's skip that detail, or do it if we want the exact approach.

  // 4) Get time & clock
  const [timeMs, clockVal] = nextClockValue()

  // 5) Split the clock => 12 bits + 6 bits
  const clockA = clockVal >>> 6 // top 12
  const clockB = clockVal & 0x3f // bottom 6

  // 6) Write fields in the same order Elixir does:
  //    time_ms::48
  bitOffset = writeTime48(out, bitOffset, timeMs)

  //    version=7 :: 4
  bitOffset = writeBitsBE(out, bitOffset, 4, 7)

  //    clockA :: 12
  bitOffset = writeBitsBE(out, bitOffset, 12, clockA)

  //    variant=2 :: 2
  bitOffset = writeBitsBE(out, bitOffset, 2, 2)

  //    clockB :: 6
  bitOffset = writeBitsBE(out, bitOffset, 6, clockB)

  //    rand_b :: 56 (7 bytes)
  bitOffset = writeRand56(out, bitOffset, rand7)

  return out
}

/**
 * Convert the 16 bytes to the usual hex-with-dashes UUID string.
 */
export function uuidv7(): string {
  const bin = uuidv7bin()
  const hex = Array.from(bin)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
  return (
    hex.slice(0, 8) +
    '-' +
    hex.slice(8, 12) +
    '-' +
    hex.slice(12, 16) +
    '-' +
    hex.slice(16, 20) +
    '-' +
    hex.slice(20)
  )
}

/**
 * Create a mask of n bits, e.g. bitMask(6) = 0b111111 = 63.
 * Up to n=31 is safe in JS. If you need more, break them up.
 */
function bitMask(n: number): number {
  return (1 << n) - 1 // e.g. n=6 => 63
}

/**
 * Write `length` bits from `value` into `arr`, starting at `bitOffset`.
 * Bits go in **big-endian** order:
 *   - if bitOffset=0, we set the top (leftmost) bit of arr[0].
 *   - if bitOffset=7, we set the bottom (rightmost) bit of arr[0].
 *
 * Returns the new bitOffset after writing.
 *
 * Example usage:
 *   bitOffset = writeBitsBE(arr, bitOffset, 4, 0x7) // version=7
 */
function writeBitsBE(arr: Uint8Array, bitOffset: number, length: number, value: number): number {
  // We assume `value` fits in `length` bits => user must ensure that.
  // We write from the most-significant bit down to the least.
  for (let i = length - 1; i >= 0; i--) {
    const bit = (value >>> i) & 1
    const byteIndex = bitOffset >>> 3
    const bitIndexInByte = 7 - (bitOffset & 7) // big-endian within the byte

    arr[byteIndex] |= bit << bitIndexInByte
    bitOffset++
  }
  return bitOffset
}

/**
 * Write a 48-bit timeMs into arr, big-endian, at bitOffset.
 * Returns new bitOffset.
 *
 * timeMs must be <= 2^48-1.
 * In JS, we can store up to 2^53 safely in a Number, so 48 bits is fine.
 */
function writeTime48(arr: Uint8Array, bitOffset: number, timeMs: number): number {
  // top16 = highest 16 bits, low32 = lowest 32 bits
  const top16 = Math.floor(timeMs / 0x100000000) // timeMs >> 32, but safe
  const low32 = timeMs >>> 0 // bottom 32 bits

  // write top16 (16 bits), then low32 (32 bits)
  bitOffset = writeBitsBE(arr, bitOffset, 16, top16)
  bitOffset = writeBitsBE(arr, bitOffset, 32, low32)
  return bitOffset
}

/**
 * Write 56 bits from a 7-byte array into arr.
 * top-to-bottom big-endian means the first byte is the top 8 bits, etc.
 */
function writeRand56(arr: Uint8Array, bitOffset: number, rand7: Uint8Array): number {
  // We expect rand7.length=7
  for (let i = 0; i < 7; i++) {
    bitOffset = writeBitsBE(arr, bitOffset, 8, rand7[i])
  }
  return bitOffset
}
