import { GridTableColumnConfig, GridTableConfig } from '@st/ui-config'
import { ReadHook, WriteHook } from '@st/pdf'
import { range } from '@st/util/array'
import { toStr } from '@st/util/cast'
import { isEmpty } from '@st/util/json-value'
import { cn } from '@util/cn'
import { FormTextInput } from './form-text-input'

type Props = GridTableConfig & {
  className?: string
  useRead: ReadHook
  useWrite: WriteHook
}
/**
 * A table that has labels on the top and no labels on the left
 * Each row is a single instance of something, for example shareholders.
 *
 * The keys might look like:
 * shareholders.0.first_name
 * shareholders.0.last_name
 * shareholders.1.first_name
 * shareholders.1.first_name
 */
export function GridTable({
  className,
  columns,
  rowCount,
  useRead,
  useWrite,
  userInputKey
}: Props) {
  const totalWidth = columns.reduce((acc, b) => acc + (b.width ?? 1), 0)

  return (
    <table className={cn('form-table-left-align border-spacing-10', className)}>
      <thead>
        <tr className="text-lg">
          {columns.map((c) => {
            return (
              <th
                className="text-left"
                style={{
                  width: percentWidth(c.width ?? 0, totalWidth),
                  paddingLeft: 12,
                  paddingRight: 12
                }}
                key={c.key}
              >
                {c.title}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {range(0, rowCount - 1).map((rowIndex) => (
          <GridTableRow
            key={rowIndex}
            rowIndex={rowIndex}
            columns={columns}
            useRead={useRead}
            useWrite={useWrite}
            buildCellInputKey={(rowIndex, col) => {
              // For single-row tables, we don't include the index as part of the user input key
              return rowCount > 1
                ? `${userInputKey}.${rowIndex}.${col.key}`
                : `${userInputKey}.${col.key}`
            }}
          />
        ))}
      </tbody>
    </table>
  )
}

type BuildCellInputKey = (rowIndex: number, column: GridTableColumnConfig) => string

function percentWidth(width: number, totalWidth: number): string {
  return Math.round((width * 100) / totalWidth) + '%'
}

type GridTableRowProps = {
  columns: GridTableColumnConfig[]
  rowIndex: number
  useRead: ReadHook
  useWrite: WriteHook
  buildCellInputKey: BuildCellInputKey
}
function GridTableRow({
  columns,
  rowIndex,
  useRead,
  useWrite,
  buildCellInputKey
}: GridTableRowProps) {
  return (
    <tr>
      {columns.map((column) => {
        return (
          <td key={column.key} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <GridCellInput
              inputKey={buildCellInputKey(rowIndex, column)}
              useRead={useRead}
              useWrite={useWrite}
            />
          </td>
        )
      })}
    </tr>
  )
}

function GridCellInput({
  inputKey,
  useRead,
  useWrite
}: {
  inputKey: string
  useRead: ReadHook
  useWrite: WriteHook
}) {
  const value = toStr(useRead(inputKey))
  const write = useWrite()

  return (
    <FormTextInput
      className="w-full"
      label=""
      value={value}
      onChange={(v) => write({ [inputKey]: isEmpty(v) ? null : v })}
    />
  )
}
