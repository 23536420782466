import { validateSize } from '../../util/geom'
import { Renderer, Select } from './interface'

export const selectRenderer: Renderer<Select> = {
  getSize(props, constraints, context) {
    const size = constraints
    return validateSize(props, constraints, size)
  },
  render(props, origin, constraints, context) {
    return {
      type: 'select',
      rect: { ...origin, ...constraints },
      name: props.name,
      value: props.value,
      options: props.options
    }
  }
}
