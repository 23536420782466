import { OptionConfig } from '@st/ui-config'

export const ENTITY_OPTIONS: OptionConfig[] = [
  { key: 'individual', label: 'Individual' },
  { key: 'partnership', label: 'Partnership' },
  { key: 'corporation', label: 'Corporation' },
  { key: 's_corporation', label: 'S Corporation' },
  { key: 'trust', label: 'Trust' },
  { key: 'estate', label: 'Estate' },
  { key: 'exempt_organization', label: 'Exempt Organization' },
  { key: 'ira', label: 'IRA' },
  { key: 'llc', label: 'LLC' },
  { key: 'disregarded_entity', label: 'Disregarded Entity' },
  { key: 'other', label: 'Other' }
]
