import { NameInputConfig } from '@st/ui-config'
import { useInputValue } from '@st/pdf'
import { toStr } from '@st/util/cast'
import { TextInput } from '.'
import { QuestionnairePageValidationResult } from '@features/st-questionnaire/st-questionnaire-validation'
import { clsx } from 'clsx'
import { hasValidationError } from '@features/st-questionnaire/st-questionnaire-module'
import { HUMAN_FIRST_NAME_KEY, HUMAN_LAST_NAME_KEY } from '@st/tax-folder'

export function WizardNameInput({
  config,
  validationResult
}: {
  config: NameInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const firstNameKey = `${config.userInputKey}.${HUMAN_FIRST_NAME_KEY}`
  const lastNameKey = `${config.userInputKey}.${HUMAN_LAST_NAME_KEY}`

  const [firstName, setFirstName] = useInputValue(firstNameKey, toStr)
  const [lastName, setLastName] = useInputValue(lastNameKey, toStr)

  return (
    <div className="flex flex-row gap-4">
      <TextInput
        label="First name"
        className={clsx('flex-grow', {
          'border-red-500': hasValidationError(validationResult, firstNameKey)
        })}
        placeholder="First name"
        value={firstName}
        onChange={setFirstName}
      />

      <TextInput
        label="Last name"
        className={clsx('flex-grow', {
          'border-red-500': hasValidationError(validationResult, lastNameKey)
        })}
        placeholder="Last name"
        value={lastName}
        onChange={setLastName}
      />
    </div>
  )
}
