import { PDFDocument, PDFFont, StandardFonts } from 'pdf-lib'

export async function embedFonts(
  doc: PDFDocument,
  fontMap: Record<string, StandardFonts | string>
): Promise<Record<string, PDFFont>> {
  const promises = Object.entries(fontMap).map(([fontName, fontSource]) => {
    return doc.embedFont(fontSource).then((font) => [fontName, font] as [string, PDFFont])
  })
  const tuples = await Promise.all(promises)
  return Object.fromEntries(tuples)
}
