import {
  AnnotationMessage,
  DrawTool,
  ElementContainer,
  Font,
  SelectedElementContainer,
  toDragPointerEvent,
  useDrawContext,
  useUpdateBounds
} from '@features/st-annotation-layer'
import { cuid } from '@st/util/cuid'
import { getTextDimensions } from '@st/util/render/canvas'
import { InlineColorPicker } from '@theme'
import { create } from 'mutative'
import { Dispatch } from 'react'
import { P, match } from 'ts-pattern'
import { AnnotationToolbar, DELETE_OPTION } from './annotation-toolbar'
import { useDebouncedCallback } from '@st/react-util/use-debounced-callback'
import { DocTextAnnotation } from '../field-types'

const TEXT_COLOR_OPTIONS = ['#FF0000', '#FFCD45', '#59BE8D']

export const TEXT_TOOL: DrawTool<DocTextAnnotation, TextTool> = {
  name: 'text',
  init: () => {
    return {
      name: 'text',
      font: { family: 'Helvetica', size: 20, weight: 'normal' },
      color: TEXT_COLOR_OPTIONS[0],
      editing: false
    }
  },
  handle: (state, message) => {
    if (message.type == 'deselect' || message.type == 'deactivate') {
      return [{ ...state, editing: false }, { annotationLayer: [] }]
    }
    return [state, { annotationLayer: [] }]
  },
  CanvasBackgroundElement: TextCanvasTool,
  Element: TextCanvasElement,
  matches: (annot) => annot.type == 'text'
}

const LINE_HEIGHT = 1.1

export type TextTool = {
  name: 'text'
  font: Font
  color: string
  editing: boolean
}

export function TextToolbar({
  annot,
  tool,
  send
}: {
  annot?: DocTextAnnotation | undefined
  tool: TextTool
  send: Dispatch<AnnotationMessage>
}) {
  const fontSize = match({ annot, tool })
    .with({ annot: P.nonNullable }, ({ annot }) => annot.font.size)
    .otherwise(() => tool.font.size)

  const color = match({ annot, tool })
    .with({ annot: P.nonNullable }, ({ annot }) => annot.color)
    .otherwise(() => tool.color)

  const saveAnnotDebounced = useDebouncedCallback(
    (annotToSave: DocTextAnnotation) => {
      send({ type: 'annotSave', annot: annotToSave })
    },
    2000,
    [send]
  )

  return (
    <div className="flex flex-row gap-4">
      <InlineColorPicker
        options={TEXT_COLOR_OPTIONS}
        value={color}
        onChange={(c) => {
          if (annot) {
            const newAnnot = create(annot, (a) => {
              a.color = c
            })
            send({ type: 'annotDraftEdited', annot: newAnnot, save: true })
          }

          // remember the color for next time
          send({
            type: 'updateTool',
            tool: create(tool, (t) => {
              t.color = c
            })
          })
        }}
      />

      <input
        type="range"
        min="12"
        max="30"
        value={fontSize}
        onChange={(e) => {
          const newFontSize = Number(e.target.value)
          if (annot) {
            const newAnnot = autoResizeTextAnnot({
              ...annot,
              font: { ...annot.font, size: newFontSize }
            })
            send({
              type: 'annotDraftEdited',
              annot: newAnnot
            })
            saveAnnotDebounced(newAnnot)
          }
          // remember the tool also
          send({
            type: 'updateTool',
            tool: create(tool, (s) => {
              s.font.size = newFontSize
            })
          })
        }}
      />
    </div>
  )
}

type TextCanvasToolProps = {
  draft: DocTextAnnotation | undefined
  tool: TextTool
  send: Dispatch<AnnotationMessage>
}
export function TextCanvasTool({ draft, tool, send }: TextCanvasToolProps) {
  const { page, zoom } = useDrawContext()

  return (
    <div
      style={{
        position: 'absolute',
        cursor: 'text',
        background: 'rgba(1, 1, 1, 0.0)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
      onPointerDown={(e) => {
        e.stopPropagation()
        e.preventDefault()

        const event = toDragPointerEvent(e, zoom, 'drawing-canvas')

        if (!draft) {
          const draftAnnot: DocTextAnnotation = autoResizeTextAnnot({
            type: 'text',
            time: new Date().toISOString(),
            id: cuid(),
            page: page.index,
            bounds: { ...event.point, width: 0, height: 0 },
            font: { size: tool.font.size },
            color: tool.color,
            body: ''
          })

          send({
            type: 'addAnnotDraft',
            annot: draftAnnot
          })
          send({ type: 'updateTool', tool: { ...tool, editing: true } })
        } else if (draft.body == '') {
          send({ type: 'annotDraftCancelled', annotId: draft.id })
        } else {
          send({
            type: 'annotDraftEdited',
            annot: autoResizeTextAnnot(draft)
          })
          send({ type: 'deselectAnnot' })
        }
      }}
    />
  )
}

type TextCanvasElementProps = {
  annot: DocTextAnnotation
  tool: TextTool
  selected?: boolean
  send: Dispatch<AnnotationMessage>
}
export function TextCanvasElement({ annot, tool, selected, send }: TextCanvasElementProps) {
  const { onMove, onResize } = useUpdateBounds(annot, send)

  if (selected) {
    if (tool.editing) {
      return (
        <ElementContainer key={annot.id} rect={annot.bounds} selectionBorder={true}>
          <TextEditor draft={annot} send={send} />
        </ElementContainer>
      )
    } else {
      return (
        <SelectedElementContainer
          key={annot.id}
          rect={annot.bounds}
          onMove={onMove}
          onResize={onResize}
          resizeHandles={false}
          onDoubleClick={() => {
            send({ type: 'updateTool', tool: { ...tool, editing: true } })
          }}
          toolbar={
            <AnnotationToolbar
              options={[DELETE_OPTION]}
              height={24}
              onClickOption={(opt) => {
                if (opt.name == 'delete') {
                  send({ type: 'annotDelete', annotId: annot.id })
                }
              }}
            />
          }
        >
          <TextContents annot={annot} />
        </SelectedElementContainer>
      )
    }
  } else {
    return (
      <ElementContainer
        key={annot.id}
        rect={annot.bounds}
        onClick={() => send({ type: 'selectAnnot', annotId: annot.id })}
      >
        <TextContents annot={annot} />
      </ElementContainer>
    )
  }
}

function TextContents({ annot }: { annot: DocTextAnnotation }) {
  return (
    <div
      style={{
        whiteSpace: 'pre',
        fontFamily: 'Helvetica',
        fontSize: annot.font.size,
        color: annot.color,
        lineHeight: LINE_HEIGHT
      }}
    >
      {annot.body}
    </div>
  )
}

function TextEditor({
  draft,
  send
}: {
  draft: DocTextAnnotation
  send: Dispatch<AnnotationMessage>
}) {
  return (
    <textarea
      value={draft.body}
      onChange={(e) => {
        send({
          type: 'annotDraftEdited',
          annot: autoResizeTextAnnot({ ...draft, body: e.target.value })
        })
      }}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      style={{
        border: 'none',
        padding: 0,
        outline: 'none',
        background: 'none',
        resize: 'none',
        width: '100%',
        height: '100%',
        fontFamily: 'Helvetica',
        overflow: 'hidden',
        fontSize: draft.font.size,
        color: draft.color,
        lineHeight: LINE_HEIGHT
      }}
      autoFocus={true}
    />
  )
}

function autoResizeTextAnnot(annot: DocTextAnnotation): DocTextAnnotation {
  const dims = getTextDimensions(
    annot.body.length == 0 ? '  ' : annot.body,
    { family: 'Helvetica', weight: 'normal', size: annot.font.size },
    LINE_HEIGHT
  )
  return create(annot, (d) => {
    d.bounds.width = dims.width
    d.bounds.height = dims.height
  })
}
