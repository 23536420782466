import { Size } from '../geom'

type Font = {
  family: string
  weight: 'normal' | 'bold'
  size: number
}

function toCanvasFont(font: Font): string {
  let str = [`${font.size}px`, font.family]
  if (font.weight == 'bold') {
    str.unshift('bold')
  }
  return str.join(' ')
}

export function getTextDimensions(text: string, font: Font, lineHeight: number): Size {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.font = toCanvasFont(font)

  const lines = text.split('\n')
  const lineMetrics = lines.map((line) => ctx.measureText(line))
  const lineRects = lineMetrics.map<Size>((metric) => {
    return {
      width: metric.width,
      height: metric.actualBoundingBoxAscent + metric.actualBoundingBoxDescent
    }
  })

  let dimensions: Size = { width: 0, height: 0 }
  for (const r of lineRects) {
    // width of rect is with of max line
    if (r.width > dimensions.width) dimensions.width = r.width
    dimensions.height += font.size * lineHeight
  }

  return dimensions
}
