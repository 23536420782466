import { AppAnalytics, useAnalytics } from '@features/analytics'
import * as Sentry from '@sentry/browser'
import { useProcess } from '@st/redux'
import { createSTClientSDK, redactRequestTrace, RequestTrace, SDKRequestError } from '@st/sdk'
import { RemoteSocket } from '@util/remote-socket'
import { useEffect } from 'react'
import { AppDeps } from './app-deps-provider'
import { useAuthClient } from './app-hooks'
import { authModule } from './auth/auth-module'
import { STAuthClientAdapter } from './auth/st-auth-client-adapter'
import { S3StorageClient } from './file-storage/adapters/s3-storage-client'
import { uuidv7 } from '@st/util/uuidv7'

export function appDepsCreate(): AppDeps {
  const authClient = new STAuthClientAdapter()

  const sdk = createSTClientSDK({
    baseUrl: process.env.NEXT_PUBLIC_API_V2_ENDPOINT!,
    getToken: async (): Promise<string | undefined> => {
      return authClient.getToken()
    },
    getExtraHeaders: () => {
      return { 'x-request-id': uuidv7() }
    },
    onVersionEvent: (event) => {
      if (event.type == 'versionInit') {
        console.log('versionInit', event)
      } else if (event.type == 'versionChange') {
        console.log('versionChange', event)

        if (event.nextVersion != event.prevVersion) {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }
    }
  })

  const socket = new RemoteSocket({
    endpoint: httpToWebSocket(process.env.NEXT_PUBLIC_API_V2_ENDPOINT!)
  })

  authClient.subscribeToAuthStatus((e) => {
    switch (e.type) {
      case 'loggedIn':
        const token = authClient.getToken()
        socket.connect({ token: token! })
        break
      case 'loggedOut':
        socket.disconnect()
        break
    }
  })

  const storageAdapter = new S3StorageClient(sdk)

  return {
    authClient,
    sdk,
    storageAdapter,
    analytics: AppAnalytics,
    socket: socket,
    [Symbol.dispose]: () => null
  }
}

function httpToWebSocket(httpUrl: string) {
  const url = new URL(httpUrl)
  if (url.protocol === 'http:') {
    url.protocol = 'ws:'
    url.pathname = '/socket'
  } else if (url.protocol === 'https:') {
    url.protocol = 'wss:'
    url.pathname = '/socket'
  }
  return url.toString()
}

export function AppDepsPlugins() {
  const auth = useProcess(authModule)
  const analytics = useAnalytics()
  const authClient = useAuthClient()

  useEffect(() => {
    return authClient.subscribeToAuthStatus((event) => {
      switch (event.type) {
        case 'loggedIn':
          auth.send({ type: 'userIsLoggedIn', user: event.user })
          analytics.trackUserIdentified(event.user, {
            getToken: () => Promise.resolve(authClient.getToken())
          })
          break
        case 'loggedOut':
          auth.send({ type: 'userIsLoggedOut' })
          analytics.trackUserAnonymous()
          break
      }
    })
  }, [authClient])

  return <></>
}
