import { ReactNode, createContext } from 'react'

export type FormationViewerBranding = {
  companyName?: string
  logo?: string
}

type FormationViewerContext = {
  branding?: FormationViewerBranding
}

const FormationViewerContext = createContext<FormationViewerContext | undefined>(undefined)

type Props = {
  children: ReactNode
} & FormationViewerContext
export function FormationViewerProvider({ branding, children }: Props) {
  return (
    <FormationViewerContext.Provider value={{ branding }}>
      {children}
    </FormationViewerContext.Provider>
  )
}
