/**
 * Generates an array of numbers within a specified range.
 * Includes the start ane end numbers.
 *
 * Keep in mind: the length of the array will be (end - start + 1)
 *
 * @param start The starting value of the range. This number is included in the returned array.
 * @param end The ending value of the range. This number is included in the returned array.
 * @returns An array of numbers starting from `start` and ending at `end`. Each element in the array is a consecutive number from the start value, incrementing by 1 until the end value is reached.
 *
 * @example
 * // For generating a range from 1 to 5
 * const numbers = range(1, 5);
 * console.log(numbers); // Output: [1, 2, 3, 4, 5]
 *
 * @example
 * // For generating a range from -2 to 2
 * const numbers = range(-2, 2);
 * console.log(numbers); // Output: [-2, -1, 0, 1, 2]
 */
export function range(start: number, end: number): number[] {
  if (start > end) {
    return []
  }
  return Array(end - start + 1)
    .fill(0)
    .map((_, index) => start + index)
}

/**
 * A set that is really just an array underneath.
 * This is meant to be used instead of an actual Set object to keep things simple
 */
export type ArraySet<T> = T[]

export function setContains<T>(set: ArraySet<T>, value: T): boolean {
  return set.includes(value)
}

export function setAdd<T>(set: ArraySet<T>, value: T): ArraySet<T> {
  if (setContains(set, value)) return set
  return [...set, value].sort()
}

export function setRemove<T>(set: ArraySet<T>, value: T): ArraySet<T> {
  if (!setContains(set, value)) return set
  return set.filter((v) => v != value).sort()
}
