import { formatAuthError } from '@features/auth'
import { AuthClientAdapter, User } from '@features/auth/auth-client-adapter'
import { defineModule, defineTask } from '@st/redux'
import { Organization, STSDK } from '@st/sdk'
import { isEmpty, isNotEmpty } from '@st/util/json-value'
import { match } from 'ts-pattern'

export type FieldErrors<Input> = Partial<{
  [K in keyof Input]: string
}>

type SignUpError =
  | { type: 'validation'; errors: FieldErrors<OrganizationSignUpInput> }
  | { type: 'general'; message: string }

export type OrganizationSignUpState =
  | { status: 'idle'; error?: SignUpError }
  | { status: 'creatingUser' }
  | { status: 'creatingOrganization'; user: User }
  | { status: 'succeeded'; user: User; organization: Organization }

export type OrganizationSignUpMessage =
  | { type: 'createUser' }
  | { type: 'createOrganization'; user: User }
  | { type: 'completed'; organization: Organization }
  | { type: 'failed'; error: SignUpError }

export type OrganizationSignUpContext = {
  sdk: STSDK
  authClient: AuthClientAdapter
}

export const organizationSignUpModule = defineModule<
  OrganizationSignUpState,
  OrganizationSignUpMessage,
  void
>({
  name: 'organizationSignUp',
  init: () => {
    return { status: 'idle' }
  },
  handle: (state, message) => {
    return match<
      { state: OrganizationSignUpState; message: OrganizationSignUpMessage },
      OrganizationSignUpState
    >({ state, message })
      .with({ state: { status: 'idle' }, message: { type: 'createUser' } }, () => {
        return { status: 'creatingUser' }
      })
      .with(
        { state: { status: 'creatingUser' }, message: { type: 'createOrganization' } },
        ({ message }) => {
          return { status: 'creatingOrganization', user: message.user }
        }
      )
      .with(
        { state: { status: 'creatingOrganization' }, message: { type: 'completed' } },
        ({ state, message }) => {
          return { status: 'succeeded', user: state.user, organization: message.organization }
        }
      )
      .with({ message: { type: 'failed' } }, ({ message }) => {
        return { status: 'idle', error: message.error }
      })
      .otherwise(({ state, message }) => {
        console.log(
          `Invalid transition ${state.status} with message ${message.type}`,
          state,
          message
        )
        return state
      })
  }
})

export type OrganizationSignUpInput = {
  userName?: string
  userEmail: string
  password: string
  passwordConfirm: string

  organizationName: string
  organizationPhoneNumber: string
  taxApp?: string
  numberOfClients?: string
  tosPrivacyAgreement: boolean
}
export const signUpOrganization = defineTask(
  organizationSignUpModule,
  async (
    { getState, send },
    input: OrganizationSignUpInput,
    { sdk, authClient }: OrganizationSignUpContext
  ): Promise<OrganizationSignUpState> => {
    const currentUser = authClient.getUser()
    const validationErrors = validateOrganizationSignUp(input, currentUser)

    if (isNotEmpty(validationErrors)) {
      send({
        type: 'failed',
        error: { type: 'validation', errors: validationErrors }
      })
      return getState()
    }

    if (currentUser) {
      send({ type: 'createOrganization', user: currentUser })
    } else {
      send({ type: 'createUser' })

      const signUpResult = await authClient.signUp({
        name: input.userName,
        email: input.userEmail,
        password: input.password,
        passwordConfirm: input.passwordConfirm
      })

      if (!signUpResult.ok) {
        send({
          type: 'failed',
          error: { type: 'validation', errors: { userEmail: formatAuthError(signUpResult.error) } }
        })
        return getState()
      }

      send({ type: 'createOrganization', user: signUpResult.value })
    }

    try {
      const user = authClient.getUser()!
      const organization = await sdk.send({
        type: 'organizations/createOrganization',
        name: input.organizationName!,
        taxApp: input.taxApp!,
        numberOfClients: input.numberOfClients,
        email: isNotEmpty(input.userEmail) ? input.userEmail : user.email
      })

      send({ type: 'completed', organization })
    } catch (error) {
      send({
        type: 'failed',
        error: {
          type: 'general',
          message: error instanceof Error ? error.message : 'An unknown error occurred'
        }
      })
    }

    return getState()
  }
)

export const ORGANIZATION_NUM_CLIENTS_OPTIONS = [
  '0-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-2000',
  '2001-5000',
  '5000+'
]

function validateOrganizationSignUp(
  input: OrganizationSignUpInput,
  currentUser: User | undefined
): FieldErrors<OrganizationSignUpInput> {
  const errors: FieldErrors<OrganizationSignUpInput> = {}

  if (validateOrganizationName(input.organizationName, true)) {
    errors.organizationName = validateOrganizationName(input.organizationName, true)
  }

  // we only validate email, password, confirmation if they aren't creating an org logged in
  if (currentUser == undefined && isEmpty(input.userEmail)) {
    if (isEmpty(input.userEmail)) errors.userEmail = 'An email is required'
    if (isEmpty(input.password)) errors.password = 'A password is required'

    if (isEmpty(input.passwordConfirm)) {
      errors.password = 'A password confirmation is required'
    } else if (input.passwordConfirm != input.password) {
      errors.passwordConfirm = 'The password confirmation does not match the original password'
    }
  }

  if (isEmpty(input.taxApp)) {
    errors.taxApp = 'Please select the tax software you use'
  }

  if (isEmpty(input.numberOfClients)) {
    errors.numberOfClients = 'Please select the number of clients you have'
  }

  if (input.tosPrivacyAgreement == false) {
    errors.tosPrivacyAgreement = 'Please agree to the Terms of Service and Privacy Policy'
  }

  return errors
}

function validateOrganizationName(organizationName: string, required: boolean): string | undefined {
  if (required && organizationName.length == 0) {
    return 'Enter a company name. You can always change it later.'
  }
  if (organizationName.length > 0 && organizationName.length < 3) {
    return 'Your company name must be at least 3 characters long.'
  }
}
