import { LoadOrganization } from '@features/organizations/organization-module'
import type { SDKMessage, SDKRequestUnauthorized, SDKResponse } from '@features/sdk-module'
import { PlatformMessage } from '@features/st-pdf-viewer/platform-module'
import { defineModule, defineTask } from '@st/redux'
import { DynamicEmailTemplate, STOrganizationState, STSDK } from '@st/sdk'
import { groupBy } from '@st/util/array'
import { match } from 'ts-pattern'

export type STOrganizationModuleState = {
  status: 'loading' | 'loaded' | 'unauthorized'
  organizationId: string

  state: STOrganizationState | undefined
}

export type STOrganizationMessage = { type: 'reload' } | SDKResponse | SDKRequestUnauthorized

type STOrganizationContext = {
  sdk: STSDK
}

type STOrganizationModuleSend = {
  sdk: SDKMessage
  platform: PlatformMessage
  organization: LoadOrganization
}

type STOrganizationModuleInit = {
  organizationId: string
}

export const stOrganizationModule = defineModule<
  STOrganizationModuleState,
  STOrganizationMessage,
  STOrganizationModuleInit,
  STOrganizationModuleSend
>({
  name: 'stOrganization',
  init: ({ organizationId }) => {
    return [
      {
        status: 'loading',
        organizationId: organizationId,
        state: undefined
      },
      {
        sdk: {
          type: 'request',
          request: { type: 'organizations/getOrganizationState', organizationId: organizationId }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'reload':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: {
                type: 'organizations/getOrganizationState',
                organizationId: state.organizationId
              }
            }
          }
        ]
      case 'response':
        return match(message.operation)
          .with({ type: 'organizations/getOrganizationState' }, ({ response }) => {
            return {
              ...state,
              status: 'loaded',
              state: response
            } satisfies STOrganizationModuleState
          })
          .with({ type: 'organizations/updateOrganization' }, ({ response }) => {
            return [
              state,
              {
                sdk: {
                  type: 'request',
                  request: {
                    type: 'organizations/getOrganizationState',
                    organizationId: state.organizationId
                  }
                },
                organization: {
                  type: 'reloadOrganization'
                }
              }
            ] as [STOrganizationModuleState, STOrganizationModuleSend]
          })
          .otherwise(() => state)
      case 'requestUnauthorized':
        return match(message.request)
          .with({ type: 'organizations/getOrganizationState' }, ({}) => {
            return { ...state, status: 'unauthorized' } as STOrganizationModuleState
          })
          .otherwise(() => state)
      default:
        return state
    }
  }
})

export type TemplateGroup = {
  label: string
  type: string
  templates: DynamicEmailTemplate[]
}

/**
 * Returns a list of template groups, grouped by type.
 * For example, send questionnaire, remind client, and document request.
 * In the interface, each group basically corresponds to a tab in the settings page.
 *
 * The reason groups are necessary is that, on the backend,
 * we have distinct templates for every combination of message type and return type.
 * For example there is "folder_invite:1040", "folder_invite:1120", etc.
 *
 * But we do not want to complicate the current interface by introducing a tab for every single permutation.
 *
 * Instead, what you effectively do is:
 * - Click the "Send questionnaire" tab (send questionnaire means folder_invite)
 * - Edit the template for "folder_invite:1040"
 * - Click "Save"
 * - The template for "folder_invite:1040" but so is every other template in the folder invite group
 *
 * @param state
 * @returns
 */
export function selTemplateGroups(state: STOrganizationState): TemplateGroup[] {
  return Object.entries(groupBy(state.messageTemplates, (t) => t.label)).map(
    ([label, templates]) => {
      return {
        label: label,
        // by convention, the first tag is the type of template (ex "folder_invite")
        type: templates[0].tags[0],
        templates: templates
      }
    }
  )
}

export const addMemberToOrganization = defineTask(
  stOrganizationModule,
  async ({ send, getState }, arg: { userId: string }, { sdk }: STOrganizationContext) => {
    const state = getState()

    await sdk.send({
      type: 'organizations/addMemberToOrganization',
      organizationId: state.organizationId,
      userId: arg.userId
    })

    send({ type: 'reload' })
  }
)

export const resendOrganizationInviteToMember = defineTask(
  stOrganizationModule,
  async ({ send, getState }, arg: { userId: string }, { sdk }: STOrganizationContext) => {
    const state = getState()

    await sdk.send({
      type: 'organizations/sendTeamInviteEmail',
      organizationId: state.organizationId,
      userId: arg.userId
    })

    send({ type: 'reload' })
  }
)

export const removeMemberFromOrganization = defineTask(
  stOrganizationModule,
  async ({ send, getState }, arg: { userId: string }, { sdk }: STOrganizationContext) => {
    const state = getState()

    await sdk.send({
      type: 'organizations/removeMemberFromOrganization',
      organizationId: state.organizationId,
      userId: arg.userId
    })

    send({ type: 'reload' })
  }
)

export type EditOrganizationAttributes = {
  name?: string
  logo?: string
  appsInStack?: string[]
}
export const updateOrganization = defineTask(
  stOrganizationModule,
  async ({ send, getState }, arg: EditOrganizationAttributes, { sdk }: STOrganizationContext) => {
    const state = getState()

    await sdk.send({
      type: 'organizations/updateOrganization',
      organizationId: state.organizationId,
      name: arg.name,
      logo: arg.logo,
      appsInStack: arg.appsInStack
    })

    send({ type: 'reload' })
  }
)
