import { SectionID } from '~/domain/entities'
import type { Param } from '@st/util/routing'
import { TypedParam } from '@st/util/routing'

type SchemaId = string

export const SchemaIdParam: Param<SchemaId> = {
  encode: (s: SchemaId) => s.toLowerCase(),
  decode: (s: SchemaId) => s.toLowerCase()
}

export const SectionIdParam = TypedParam<SectionID>()
