import type { Core } from '@pdftron/webviewer'
import { all, asyncMap } from '@st/util/async'
import { Size } from '@st/util/geom'
import { Result, err, ok, splitResults } from '@st/util/result'
import { Section } from '@st/util/section'
import { XFile, fetchFile, toXFile } from '@st/util/xfile'
import { PDFTronBookmark, loadPDFTronCore } from './pdftron'
import { PDFPageMode } from '@st/pdf/src/document/types'
import { PDFOutlineNode } from '@st/pdf/src/outline'

type PDFSection<T> = Section<T, XFile>

type MergeOptions<T> = {
  filename: string
  getHeading: (heading: T) => string | undefined
  pageMode?: PDFPageMode
}

type PartialPDF = {
  /**
   * The PDF file to be included inline
   */
  pdf: XFile

  /**
   * The number of files in the pdf
   */
  pageCount: number

  /**
   * The outline for the PDF file that will get merged into the final PDF
   */
  outline: PDFOutlineNode[]

  /**
   * If there's a corresponding attachment to be included in the final result
   */
  attachment?: XFile
}

type PDFFileSection<T> = Section<T, PartialPDF>

/**
 * Given a directory of .pdf files, return a single .pdf file
 * with all of the files merged together.
 *
 * Expects all of the inputs to be PDFs.
 * Files that are not PDFs will be included as attachments.
 *
 * @param dir
 * @returns
 */
export async function mergePDFs<T>(
  sections: PDFSection<T>[],
  opts: MergeOptions<T>
): Promise<Result<XFile, PDFError>> {
  // const pageMode = opts.pageMode ?? PDFPageMode.UseOutlines

  const core = await loadPDFTronCore()

  const pdfFileSections: PDFFileSection<T>[] = []
  for (const section of sections) {
    const fileSection: PDFFileSection<T> = {
      heading: section.heading,
      items: []
    }
    for (const file of section.items) {
      fileSection.items.push(await toPDFFile(file))
    }
    pdfFileSections.push(fileSection)
  }

  // First we just flatten and combine the pages
  // Outlines (bookmarks) will not be preserved so we need to bring those in
  // after the fact
  const sourcePDFs = pdfFileSections.flatMap((s) => s.items).map((el) => el.pdf)

  const concatResult = await concatPDFs(sourcePDFs)

  if (!concatResult.ok) {
    return concatResult
  }

  // // Acrobat, unlike other PDF readers, only generate appearance streams when it is explicitly told to do so,
  // // by setting / NeedsAppearances to true in the AcroForm dictionary.This is usually preserved when you fill out
  // // a single form, but when you copy forms, the setting is lost.
  // // further reading:
  // // - https://github.com/Hopding/pdf-lib/issues/569#issuecomment-1087328416
  // // - https://github.com/gettalong/hexapdf/issues/86#issuecomment-544110920
  // // - https://stackoverflow.com/questions/38915591/adobe-pdf-forms-text-field-displays-value-only-when-clicked-on-it
  // mergedDocument.getForm().acroForm.dict.set(PDFName.of('NeedAppearances'), PDFBool.True)

  // // Combine the outlines of all PDFs into one master outline
  const mergedOutline = mergeOutlines(pdfFileSections, opts)

  const doc = await core.PDFNet.PDFDoc.createFromBuffer(
    await fetchFile(concatResult.value).then((r) => r.arrayBuffer())
  )

  const pref = await doc.getViewPrefs()
  await pref.setPageMode(core.PDFNet.PDFDocViewPrefs.PageMode.e_UseBookmarks)

  await setOutlineWithPDFTron(doc, mergedOutline)

  const buf = await doc.saveMemoryBuffer(core.PDFNet.SDFDoc.SaveOptions.e_linearized)

  const mergedFile = await toXFile(new File([buf], opts.filename, { type: 'application/pdf' }), {
    protocol: 'blob'
  })

  return ok(mergedFile)
}

function mergeOutlines<T>(sections: PDFFileSection<T>[], opts: MergeOptions<T>): PDFOutlineNode[] {
  let mergedOutline: PDFOutlineNode[] = []
  let pageIndex = 0

  for (const { heading, items } of sections) {
    const title = opts.getHeading(heading)
    const sectionOutline: PDFOutlineNode = {
      title: title ?? '',
      children: [],
      open: true,
      to: pageIndex
    }

    for (const file of items) {
      const fileOutline = file.outline
      sectionOutline.children!.push({
        title: file.pdf.name,
        to: pageIndex,
        open: false,
        children: shiftOutlineIndex(fileOutline, pageIndex)
      })

      pageIndex += file.pageCount
    }

    if (title) {
      mergedOutline.push(sectionOutline)
    } else if (sectionOutline.children) {
      // there is no title so we want to lift the children up and include them as siblings
      // we also want them to be expanded
      const children = sectionOutline.children!.map((node) => {
        return { ...node, open: true }
      })
      mergedOutline = [...mergedOutline, ...children]
    }
  }

  return mergedOutline

  function shiftOutlineIndex(nodes: PDFOutlineNode[], delta: number): PDFOutlineNode[] {
    return nodes.map((node) => {
      const newNode: PDFOutlineNode = { ...node }

      // not all nodes are bookmarked to jump to a page
      // if that's the case, we skip over them
      // need a strict check to distinguish between 0 and undefined
      if (newNode.to !== undefined) {
        // offset destination by the pageIndex
        newNode.to = delta + newNode.to
      }
      if (newNode.children) {
        newNode.children = shiftOutlineIndex(newNode.children, delta)
      }
      return newNode
    })
  }
}

async function toPDFFile(file: XFile): Promise<PartialPDF> {
  switch (file.mimeType) {
    case 'application/pdf':
      try {
        const pageInfo = await getPageInfo(file)
        return {
          pdf: file,
          pageCount: pageInfo.pageCount,
          outline: pageInfo.outline
        }
      } catch (e) {
        console.error(e)
        return attachmentToPDFFile(file)
      }
    default:
      return attachmentToPDFFile(file)
  }
}

/**
 * Creates a brand-new PDF (via Apryse/PDFTron) when the file is non-PDF or invalid,
 * containing a short message that the file “could not be downloaded”.
 */
async function attachmentToPDFFile(file: XFile): Promise<PartialPDF> {
  const core = await loadPDFTronCore()
  const PDFNet = core.PDFNet

  const doc = await PDFNet.PDFDoc.create()

  // Create page sized 595 wide x 240 tall
  const pageRect = await PDFNet.Rect.init(0, 0, 595, 240)
  const page = await doc.pageCreate(pageRect)
  doc.pagePushBack(page)

  const writer = await PDFNet.ElementWriter.create()
  const builder = await PDFNet.ElementBuilder.create()
  writer.beginOnPage(page)

  // Create font & text block
  const font = await PDFNet.Font.create(doc, PDFNet.Font.StandardType1Font.e_helvetica)

  // Start text block with your chosen font at size 16
  let element = await builder.createTextBeginWithFont(font, 14)
  writer.writeElement(element)

  element = await builder.createNewTextRun(`${file.name} could not be converted to PDF`)
  element.setTextMatrixEntries(1, 0, 0, 1, 30, 160)

  let gstate = await element.getGState()
  gstate.setLeading(14 * 1.5)

  writer.writeElement(element)

  writer.writeElement(await builder.createTextNewLine())
  writer.writeElement(await builder.createTextNewLine())

  element = await builder.createNewTextRun(
    'To download this file, select the "Download zip" option within StanfordTax.'
  )
  writer.writeElement(element)

  writer.end()

  // Save to memory buffer
  const pdfData = await doc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized)

  // Convert to File & then to XFile
  const pdf = await toXFile(
    new File([pdfData], `${file.name} [omitted]`, { type: 'application/pdf' })
  )

  const pageCount = await doc.getPageCount()

  return {
    pdf,
    pageCount,
    outline: [],
    attachment: file
  }
}

type InvalidPDFError = 'passwordRequired' | 'unknown'

export type InvalidPDF = { file: XFile; error: InvalidPDFError; message?: string }
export type PDFError = {
  type: 'invalid'
  invalidDocuments: InvalidPDF[]
}

const A4_WIDTH = 595.276

type DocResult = {
  file: XFile
  doc: Core.Document
}

async function concatPDFs(sourceFiles: XFile[]): Promise<Result<XFile, PDFError>> {
  const core = await loadPDFTronCore()
  const docResults = await asyncMap(
    sourceFiles,
    async (xfile): Promise<Result<DocResult, InvalidPDF>> => {
      const data = await fetchFile(xfile).then((f) => f.blob())

      try {
        const doc = await core.createDocument(data, { extension: 'pdf' })
        doc.setFilename(xfile.name)
        const finalDoc = await removePDFSecurityIfPresent(doc)

        return ok({ file: xfile, doc: finalDoc })
      } catch (e) {
        if (typeof e == 'string') {
          if (e.includes('requires a password')) {
            return err({ file: xfile, error: 'passwordRequired' })
          }
        }
        return err({ file: xfile, error: 'unknown' })
      }
    }
  )

  const { succeeded, failed } = splitResults(docResults)

  if (failed.length > 0) {
    return err({ type: 'invalid', invalidDocuments: failed })
  }

  const [master, ...remaining] = succeeded

  const invalidPdfs: InvalidPDF[] = []

  for (const result of remaining) {
    try {
      await master.doc.insertPages(result.doc)
    } catch (e) {
      invalidPdfs.push({
        file: result.file,
        error: 'unknown',
        message: e instanceof Error ? e.message : undefined
      })
    }
  }

  if (invalidPdfs.length > 0) {
    return err({ type: 'invalid', invalidDocuments: invalidPdfs })
  }

  await ensureScaleToMaxDimensions(master.doc, {
    width: A4_WIDTH,
    // we are OK with any height
    height: Number.MAX_SAFE_INTEGER
  })

  const combinedDocumentData = await master.doc.getFileData()
  const file = new File([new Blob([combinedDocumentData])], 'combined.pdf', {
    type: 'application/pdf'
  })

  return toXFile(file, { protocol: 'blob' }).then(ok)
}

/**
 * Ensures all pages in a PDF document are scaled to fit within maximum dimensions
 * @param doc The PDFTron Document to process
 * @param size The maximum dimensions to scale to, with width and height properties
 */

async function ensureScaleToMaxDimensions(doc: Core.Document, size: Size) {
  const maxWidth = size.width
  const maxHeight = size.height

  const core = await loadPDFTronCore()
  const pdfDoc = await doc.getPDFDoc()
  const pageCount = await pdfDoc.getPageCount()

  for (let i = 1; i <= pageCount; i++) {
    const page = await pdfDoc.getPage(i)
    const mediaBox = await page.getMediaBox()
    const width = await mediaBox.width()
    const height = await mediaBox.height()

    const scaleX = maxWidth / width
    const scaleY = maxHeight / height

    const scale = Math.min(scaleX, scaleY)

    if (scale < 1) {
      // Scale the page content
      await page.scale(scale)

      // Adjust the page boxes to match the new dimensions
      const newWidth = width * scale
      const newHeight = height * scale
      const newMediaBox = await core.PDFNet.Rect.init(0, 0, newWidth, newHeight)
      await page.setMediaBox(newMediaBox)
      await page.setCropBox(newMediaBox)
    }
  }
}

async function removePDFSecurityIfPresent(doc: Core.Document): Promise<Core.Document> {
  const core = await loadPDFTronCore()

  const pdfDoc = await doc.getPDFDoc()
  if (await pdfDoc.isEncrypted()) {
    await pdfDoc.removeSecurity()

    const decryptedData = await pdfDoc.saveMemoryBuffer(
      core.PDFNet.SDFDoc.SaveOptions.e_linearized |
        core.PDFNet.SDFDoc.SaveOptions.e_compatibility |
        core.PDFNet.SDFDoc.SaveOptions.e_remove_unused
    )

    const decryptedDoc = await core.createDocument(new Blob([decryptedData]), {
      extension: 'pdf',
      filename: doc.getFilename()
    })

    return decryptedDoc
  } else {
    return doc
  }
}

async function setOutlineWithPDFTron(doc: Core.PDFNet.PDFDoc, nodes: PDFOutlineNode[]) {
  const core = await loadPDFTronCore()

  await deleteBookmarks(doc)

  for (const node of nodes) {
    const bookmark = await createBookmarkFromNode(node)
    await doc.addRootBookmark(bookmark)
  }

  async function createBookmarkFromNode(node: PDFOutlineNode): Promise<PDFTronBookmark> {
    const bookmark = await core.PDFNet.Bookmark.create(doc, node.title)
    await bookmark.setOpen(node.open ? true : false)

    let flags = 0
    if (node.fontStyle === 'italic') flags |= 1
    if (node.fontWeight === 'bold') flags |= 2
    if (flags) {
      await bookmark.setFlags(flags)
    }

    if (node.to !== undefined) {
      const page = await doc.getPage(node.to + 1) // Convert back to 1-based
      const box = await page.getMediaBox()

      // y coordinate starts at the bottom of the page
      const dest = await core.PDFNet.Destination.createXYZ(page, 0, await box.height(), 1)

      const action = await core.PDFNet.Action.createGoto(dest)
      await bookmark.setAction(action)
    }

    if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        const childBookmark = await createBookmarkFromNode(childNode)
        await bookmark.addChild(childBookmark)
      }
    }

    return bookmark
  }
}

async function deleteBookmarks(doc: Core.PDFNet.PDFDoc) {
  const bookmarksToDelete: PDFTronBookmark[] = []
  for (var node = await doc.getFirstBookmark(); node != null; node = await node.getNext()) {
    bookmarksToDelete.push(node)
  }

  for (const bookmark of bookmarksToDelete) {
    await bookmark.delete()
  }
}

type PageInfo = {
  pageCount: number
  outline: PDFOutlineNode[]
}

async function getPageInfo(file: XFile): Promise<PageInfo> {
  const core = await loadPDFTronCore()

  const docData = await fetchFile(file).then((f) => f.arrayBuffer())
  const doc = await core.PDFNet.PDFDoc.createFromBuffer(docData)

  const pageCount = await doc.getPageCount()
  const root = await doc.getFirstBookmark()
  const hasOutline = root ? await root.isValid() : false

  if (!hasOutline) {
    return { pageCount, outline: [] }
  }

  return { pageCount, outline: await getOutlineNodeList(root) }

  async function getOutlineNodeList(firstNode: PDFTronBookmark): Promise<PDFOutlineNode[]> {
    const nodes: PDFOutlineNode[] = []
    for (var node = await firstNode; node != null; node = await node.getNext()) {
      nodes.push(await getOutlineNode(node))
    }
    return nodes
  }

  async function getOutlineNode(child: PDFTronBookmark): Promise<PDFOutlineNode> {
    const flags = await child.getFlags()

    const { action, title, isOpen, hasChildren } = await all({
      action: child.getAction(),
      isValid: child.isValid(),
      title: child.getTitle(),
      isOpen: child.isOpen(),
      hasChildren: child.hasChildren()
    })

    const pageIndex = action
      ? await action
          .getDest()
          .then((dest) => dest.getPage())
          .then((page) => page.getIndex())
      : undefined

    const node: PDFOutlineNode = {
      title: title,
      fontWeight: flags & 2 ? 'bold' : 'normal',
      fontStyle: flags & 1 ? 'italic' : 'normal',
      open: isOpen,
      // some bookmarks point to nothing
      // this means to will be undefined and clicking on them in adobe will do nothing
      to: pageIndex ? pageIndex - 1 : undefined // pageIndex is 1-based, and we want to normalize to 0-based
    }

    if (hasChildren) {
      node.children = await getOutlineNodeList(await child.getFirstChild())
    }

    return node
  }
}
