import { useAppDeps } from '@features/app-deps-provider'
import {
  TemplateCommentEditor,
  TemplatePlaceholderLegend
} from '@features/st-organizations/st-organization-message-templates-page'
import {
  selTemplateGroups,
  stOrganizationModule
} from '@features/st-organizations/st-organization-module'
import { useDerivedState } from '@st/react-util/use-derived-state'
import { useProcessState } from '@st/redux'
import { Button, Wizard } from '@st/theme'

export function STEditTemplateInlineWizardPage({
  title,
  subtitle,
  onClose,
  onClickBack,
  onClickContinue,
  templateType
}: {
  title: string
  subtitle: string
  templateType: string
  onClose: () => void
  onClickBack: () => void
  onClickContinue: () => void
}) {
  const { sdk } = useAppDeps()
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)

  // See {@link selTemplateGroups} for more information on why we need template groups
  const templateGroup = useProcessState(stOrganizationModule, (s) => {
    return selTemplateGroups(s.state!).find((tg) => tg.type === templateType)!
  })

  const [comment, setComment] = useDerivedState(templateGroup.templates[0].comment)

  async function handleClickContinue() {
    if (comment != templateGroup.templates[0].comment) {
      await sdk.send({
        type: 'organizations/editMessageTemplates',
        organizationId: organizationId,
        messageTemplateIds: templateGroup.templates.map((t) => t.id),
        messageTemplateComment: comment
      })
    }

    onClickContinue()
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onClickBack}>
            Back
          </Button>
          <Button variant="primary" onClick={handleClickContinue}>
            Continue
          </Button>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main variant="wide" title={title} subtitle={subtitle}>
        <div className="mt-4 flex items-start gap-5">
          {/* This is used to balance out the page so the textarea is centered */}
          <div className="w-[320px]" />

          <div className="flex w-[600px] flex-col gap-6">
            <TemplateCommentEditor
              template={templateGroup.templates[0]}
              value={comment}
              onChange={setComment}
            />
          </div>

          <TemplatePlaceholderLegend template={templateGroup.templates[0]} />
        </div>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}
