import { AddressInputConfig } from '@st/ui-config'
import { useReadWriteContext } from '@st/pdf'
import { toStr } from '@st/util/cast'
import { COUNTRIES, US_STATES } from '@util/us-states'
import { useCallback } from 'react'
import { Dropdown, TextInput } from '.'
import { QuestionnairePageValidationResult } from '@features/st-questionnaire/st-questionnaire-validation'
import { hasValidationError } from '@features/st-questionnaire/st-questionnaire-module'
import { clsx } from 'clsx'

const US_STATES_OPTIONS = US_STATES.map((state) => {
  return { key: state.code, label: state.name }
})

const COUNTRY_OPTIONS = COUNTRIES.map((country) => {
  return { key: country.code, label: country.name }
})

export function WizardAddressInput({
  config,
  validationResult
}: {
  config: AddressInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const [street, setStreet, streetError] = useAddressComponent(
    config.userInputKey,
    validationResult,
    'street'
  )
  const [city, setCity, cityError] = useAddressComponent(
    config.userInputKey,
    validationResult,
    'city'
  )
  const [state, setState, stateError] = useAddressComponent(
    config.userInputKey,
    validationResult,
    'state'
  )
  const [country, setCountry, countryError] = useAddressComponent(
    config.userInputKey,
    validationResult,
    'country',
    {
      defaultValue: config.isForeignAddress ? undefined : 'US'
    }
  )
  const [zip, setZip, zipError] = useAddressComponent(config.userInputKey, validationResult, 'zip')

  return (
    <div className="flex w-full flex-col gap-4">
      <TextInput
        label="Address"
        className={clsx({ 'border-red-500': streetError })}
        value={street}
        onChange={setStreet}
      />

      <TextInput
        label="City"
        className={clsx({ 'border-red-500': cityError })}
        value={city}
        onChange={setCity}
      />

      {country == 'US' ? (
        <Dropdown
          label="State"
          className={clsx({ 'border-red-500': stateError })}
          options={US_STATES_OPTIONS}
          value={state}
          onChange={setState}
        />
      ) : (
        <TextInput
          label="Province"
          className={clsx({ 'border-red-500': stateError })}
          value={state}
          onChange={setState}
        />
      )}

      <TextInput
        label={country == 'US' ? 'ZIP code' : 'Postal code'}
        className={clsx({ 'border-red-500': zipError })}
        value={zip}
        onChange={setZip}
      />

      <Dropdown
        label="Country"
        className={clsx({ 'border-red-500': countryError })}
        options={COUNTRY_OPTIONS}
        value={country || (config.isForeignAddress ? undefined : 'US')}
        onChange={setCountry}
      />
    </div>
  )
}

function useAddressComponent(
  inputKey: string,
  validationResult: QuestionnairePageValidationResult,
  component: string,
  opts?: { defaultValue?: string }
): [string, (value: string | undefined) => void, boolean] {
  const { useRead, useWrite } = useReadWriteContext()

  const finalInputKey = `${inputKey}.${component}`
  const value = toStr(useRead(finalInputKey)) || opts?.defaultValue || ''
  const setValues = useWrite()

  const onChange = useCallback(
    (value: string | undefined) => {
      setValues({ [finalInputKey]: value ?? null })
    },
    [finalInputKey, component]
  )

  return [value, onChange, hasValidationError(validationResult, finalInputKey)]
}
