import {
  Box,
  Checkbox,
  Column,
  Expand,
  FORM_BORDER_GREY,
  Pad,
  PDFFormDocument,
  PDFFormPage,
  Row,
  Space,
  Text
} from '@st/pdf'
import {
  FolderSummary,
  STCategory,
  STChecklistItem,
  STDocument,
  STDocumentType,
  STMissingReason
} from '@st/sdk'
import { paginate, PaginatedPage } from '@st/util/array'
import { formatFolderEntityName, selMissingReasonsById } from './st-folder-module'

export type STFormationChecklistPDFProps = {
  entityName: string
  type: string
  year: number
  documents: STDocument[]
  page: PaginatedPage<STChecklistItem>
  missingReasonsById: Record<string, STMissingReason>
}

type Props = {
  folder: FolderSummary
  checklistItems: STChecklistItem[]
  documents: STDocument[]
  documentTypes: STDocumentType[]
  categories: STCategory[]
  checklistItemsPerPage: number
}
export function FormationChecklistDocument({
  folder,
  checklistItems,
  documents,
  documentTypes,
  categories,
  checklistItemsPerPage
}: Props) {
  const pages = paginate(checklistItems, checklistItemsPerPage)

  return (
    <PDFFormDocument>
      {pages.map((page) => {
        return (
          <PDFFormPage
            key={page.number}
            width={800}
            height={1000}
            padding={{ top: 60, bottom: 60, left: 80, right: 80 }}
          >
            <STFormationChecklistTable
              key={page.number}
              entityName={formatFolderEntityName(folder.entities)}
              page={page}
              documents={documents}
              type={folder.type}
              year={folder.year}
              missingReasonsById={selMissingReasonsById({
                documentTypes,
                categories
              })}
            />
          </PDFFormPage>
        )
      })}
    </PDFFormDocument>
  )
}

export function STFormationChecklistTable(props: STFormationChecklistPDFProps) {
  const { entityName, page } = props
  return (
    <Column mainAxisSize="min">
      {page.isFirst && (
        <Row>
          <Expand flex={4}>
            <Box width={Infinity} height={24} borderColor="#ffffff" borderWidth={1}>
              <Text fontSize={24} fontStyle="normal" overflow="ellipsis">
                {`${entityName}'s Checklist`}
              </Text>
            </Box>
          </Expand>
          <Expand>
            <Box width={Infinity} height={32} borderColor="#ffffff" borderWidth={1} hAlign="end">
              <Column align="end">
                <Text fontSize={10} fontStyle="normal">
                  {`RETURN TYPE: ${props.type}`}
                </Text>
                <Space h={8} />
                <Text fontSize={10} fontStyle="normal">
                  {`TAX YEAR: ${props.year}`}
                </Text>
              </Column>
            </Box>
          </Expand>
        </Row>
      )}

      {page.isFirst && <Space h={40} />}

      {page.items.map((checklistItem) => {
        const document = props.documents.find(
          (doc) => doc.id == checklistItem.reference?.documentId
        )

        const incompleteReason = checklistItem.incompleteReason
          ? props.missingReasonsById[checklistItem.incompleteReason]
          : undefined

        return (
          <Box
            key={checklistItem.id}
            width={Infinity}
            height={20}
            borderColor={FORM_BORDER_GREY}
            borderWidth={0.5}
            padding={Pad.all(6)}
          >
            <Row mainAxisSize="max" gap={12}>
              <Box
                height={20}
                width={20}
                hAlign="center"
                vAlign="start"
                padding={Pad.only({ bottom: 6 })}
              >
                <Checkbox name={checklistItem.id} value={checklistItem.status == 'complete'} />
              </Box>
              <Expand flex={3}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <Text fontSize={10} fontStyle="normal">
                      {checklistItem.name}
                    </Text>
                  </Box>
                </Column>
              </Expand>

              <Expand flex={4}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <Text fontSize={10} fontStyle="normal" overflow="ellipsis">
                      {checklistItem.note}
                    </Text>
                  </Box>
                </Column>
              </Expand>

              <Expand flex={6}>
                <Column mainAxisSize="min">
                  <Box height={20} width={Infinity}>
                    <STChecklistViewerItemLink
                      item={checklistItem}
                      document={document}
                      incompleteReason={incompleteReason}
                    />
                  </Box>
                </Column>
              </Expand>
            </Row>
          </Box>
        )
      })}
    </Column>
  )
}

function STChecklistViewerItemLink({
  item,
  document,
  incompleteReason
}: {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
}) {
  if (document) {
    return (
      <Text fontSize={10} fontStyle="normal" color="#354dcc" overflow="ellipsis">
        {document.name}
      </Text>
    )
  } else if (incompleteReason) {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        {incompleteReason.name}
      </Text>
    )
  } else if (item.constraint.type == 'document_type') {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        Not uploaded
      </Text>
    )
  } else if (item.constraint.type == 'category') {
    return (
      <Text fontSize={10} fontStyle="normal" color="#848796">
        Not filled in
      </Text>
    )
  } else {
    return null
  }
}
