import { MFASession } from '@st/sdk'
import { AuthError, User } from './auth-client-adapter'

export type AuthStatus =
  | 'unknown'
  | 'signingUp'
  | 'loggingIn'
  | 'waitingForMFACode'
  | 'loggedOut'
  | 'loggedIn'
  | 'error'

export type AuthState = {
  status: AuthStatus
  user?: User
  email?: string
  error?: AuthError
  loginState: LoginState

  mfaSession?: MFASession
}

type LoginState = {
  status: 'idle' | 'sendingLoginVerificationEmail'
  lastDeliveredTo?: string
}

export type AuthEvent =
  /**
   * The user is in the process of registering an account
   * with the given email.
   * The operation hasn't yet been completed.
   */
  | { type: 'signingUp'; email: string }

  /**
   * Creating a new account succeeded
   */
  | { type: 'signUpSucceeded'; user: User }

  /**
   * Creating a new account failed.
   * This could be for many reasons such as a weak password or
   * an email with the account already existing.
   */
  | { type: 'signUpFailed'; error: AuthError }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'loggingIn/emailPassword'; email: string }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'loggingIn/verificationLink'; email: string }

  /**
   * The user is in the process of verifying their MFA method
   */
  | { type: 'loginRequiresMFA'; method: 'phone_number'; phoneNumber: string }

  /**
   * The user tried to login and the login failed do to the reason
   * provided in the error
   */
  | { type: 'loginFailed'; error: AuthError }

  /**
   * We have detected that the user is logged in.
   * This can be because user logged in from the UI
   * or they returned later and were still logged in.
   */
  | { type: 'userIsLoggedIn'; user: User }

  /**
   * We have detected that the user is logged out.
   * This can be because the user tapped logout
   * or they come to the website and we see they are logged out.
   */
  | { type: 'userIsLoggedOut' }
  | {
      type: 'loginWithEmail/sendingEmail'
      organizationId: string
      email: string
      destination: string
    }
  | {
      type: 'loginWithEmail/emailSent'
      organizationId: string
      email: string
      destination: string
    }

export function formatAuthError(error: AuthError): string {
  return error.message
}
