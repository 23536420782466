import {
  PDFContentStream,
  PDFDict,
  PDFFont,
  PDFHexString,
  PDFName,
  PDFPage,
  PDFString,
  degrees,
  drawRectangle as pdfLibDrawRectangle,
  drawText as pdfLibDrawText,
  rgb
} from 'pdf-lib'
import { Point, Rect, Size } from '../util/geom'
import { parseISO8601Time } from '../util/time'
import { Border, Font, FontWeight } from './renderer'

export type DrawOp =
  | DrawTextOp
  | DrawRectOp
  | DrawLineOp
  | DrawTextInputOp
  | DrawHiddenInputOp
  | DrawRadioOp
  | DrawCheckboxOp
  | DrawSelectOp
  | DrawGroup
  | AddBookmarkOp
  | RenderAnnot

type DrawGroup = { type: 'group'; ops: DrawOp[] }

export type FontResolver = (font: Font) => PDFFont

export type DrawContext = {
  defaultFont: Font
  resolveFont: FontResolver
  addBookmark: (page: PDFPage, op: AddBookmarkOp) => void
  sanitizeText: (text: string) => string
  updateAppearances: () => void
}

export function draw(page: PDFPage, context: DrawContext, op: DrawOp) {
  switch (op.type) {
    case 'text':
      return drawText(page, context, op)
    case 'rect':
      return drawRect(page, context, op)
    case 'line':
      return drawLine(page, context, op)
    case 'text_input':
      return drawTextInput(page, context, op)
    case 'hidden_input':
      return drawHiddenInput(page, context, op)
    case 'radio':
      return drawRadio(page, context, op)
    case 'checkbox':
      return drawCheckbox(page, context, op)
    case 'select':
      return drawSelect(page, context, op)
    case 'group':
      for (var op of op.ops) draw(page, context, op)
      return
    case 'render_annot':
      return renderAnnot(page, context, op)
    case 'add_bookmark':
      return addBookmark(page, context, op)
  }
}

type DrawTextOp = {
  type: 'text'
  text: string
  point: Point
  fontFamily: string
  fontSize: number
  fontWeight: FontWeight
  fontStyle: 'normal' | 'italic'
  color: string
}
function drawText(page: PDFPage, context: DrawContext, op: DrawTextOp) {
  const font = context.resolveFont({
    family: op.fontFamily,
    weight: op.fontWeight,
    style: op.fontStyle
  })

  try {
    return page.drawText(op.text, {
      x: op.point.x,
      y: page.getHeight() - op.point.y - font.heightAtSize(op.fontSize),
      size: op.fontSize,
      font: font,
      color: hexToRgb(op.color)
    })
  } catch (e) {
    console.error(e)
  }
}

type DrawRectOp = {
  type: 'rect'
  rect: Rect
  backgroundColor?: string
  borderWidth?: number
  borderColor?: string
}
function drawRect(page: PDFPage, context: DrawContext, op: DrawRectOp) {
  const rect = {
    ...op.rect,
    y: page.getHeight() - op.rect.y - op.rect.height
  }

  // no draw operation is needed when there's no color
  if (!op.backgroundColor && !op.borderColor) {
    return
  }

  page.drawRectangle({
    ...rect,
    borderWidth: op.borderWidth,
    color: hexToRgb(op.backgroundColor),
    borderColor: hexToRgb(op.borderColor)
  })
}

type DrawLineOp = {
  type: 'line'
  start: Point
  end: Point
  width: number
  color?: string
}
function drawLine(page: PDFPage, context: DrawContext, op: DrawLineOp) {
  page.drawLine({
    start: { x: op.start.x, y: page.getHeight() - op.start.y },
    end: { x: op.end.x, y: page.getHeight() - op.end.y },
    color: hexToRgb(op.color)
  })
}

type DrawTextInputOp = {
  type: 'text_input'
  rect: Rect
  name: string
  value: string
  fontSize?: number
  multiline?: boolean
  mask?: boolean

  backgroundColor?: string
  borderWidth?: number
  borderColor?: string
}
function drawTextInput(page: PDFPage, context: DrawContext, op: DrawTextInputOp) {
  const value = context.sanitizeText(op.value)

  const form = page.doc.getForm()
  const textField = form.createTextField(op.name)

  textField.setText(value)

  textField.addToPage(page, {
    x: op.rect.x,
    y: page.getHeight() - op.rect.y - op.rect.height,
    width: op.rect.width,
    height: op.rect.height,
    borderWidth: op.borderWidth ?? 0,
    borderColor: hexToRgb(op.borderColor),
    backgroundColor: hexToRgb(op.backgroundColor)
  })

  // if (op.mask) {
  //   textField.enablePassword()
  // }

  if (op.multiline) textField.enableMultiline()
  if (op.fontSize) textField.setFontSize(op.fontSize!)
}

type DropdownOption = {
  value: string
  label: string
}

type DrawSelectOp = {
  type: 'select'
  rect: Rect
  name: string
  options: DropdownOption[]
  value?: string
}
function drawSelect(page: PDFPage, context: DrawContext, op: DrawSelectOp) {
  const form = page.doc.getForm()

  const dropdown = form.createDropdown(op.name)
  dropdown.disableEditing()
  dropdown.disableRequired()

  dropdown.acroField.setOptions(
    op.options.map((option) => ({
      value: PDFHexString.fromText(option.value),
      display: PDFHexString.fromText(option.label)
    }))
  )

  dropdown.addToPage(page, {
    x: op.rect.x,
    y: page.getHeight() - op.rect.y - op.rect.height,
    width: op.rect.width,
    height: op.rect.height
  })
  context.updateAppearances()

  dropdown.setFontSize(10)

  if (op.value) {
    dropdown.select(op.value)
  }
}

type DrawHiddenInputOp = {
  type: 'hidden_input'
  name: string
  value: string
}
function drawHiddenInput(page: PDFPage, context: DrawContext, op: DrawHiddenInputOp) {
  // do nothing for now
}

export type DrawRadioOp = {
  type: 'radio'
  name: string
  value: string | boolean | number
  selected?: boolean
  x: number
  y: number
  width: number
  height: number
  backgroundColor?: string
}
function drawRadio(page: PDFPage, context: DrawContext, op: DrawRadioOp) {
  const form = page.doc.getForm()

  const field = form.getFieldMaybe(op.name)
  const group = field ? form.getRadioGroup(op.name) : form.createRadioGroup(op.name)

  group.addOptionToPage(op.value.toString(), page, {
    x: op.x,
    y: page.getHeight() - op.y - op.height,
    width: op.width,
    height: op.height,
    backgroundColor: hexToRgb(op.backgroundColor)
  })

  if (op.selected) group.select(op.value.toString())
}

export type DrawCheckboxOp = {
  type: 'checkbox'
  name: string
  checked?: boolean
  x: number
  y: number
  width: number
  height: number
  backgroundColor?: string
}
function drawCheckbox(page: PDFPage, context: DrawContext, op: DrawCheckboxOp) {
  const form = page.doc.getForm()

  const checkbox = form.createCheckBox(op.name)

  checkbox.addToPage(page, {
    x: op.x,
    y: page.getHeight() - op.y - op.height,
    width: op.width,
    height: op.height,
    backgroundColor: hexToRgb(op.backgroundColor)
  })

  if (op.checked) checkbox.check()
}

type AddBookmarkOp = {
  type: 'add_bookmark'
  path: string
  title: string
  fontWeight: FontWeight
}
function addBookmark(page: PDFPage, context: DrawContext, op: AddBookmarkOp) {
  context.addBookmark(page, op)
}

export type PDFAnnot =
  | {
      type: 'comment'
      body: string
      color?: string
      author?: string
      time?: string
      rect: Rect
    }
  | {
      type: 'rect'
      time?: string
      author?: string
      rect: Rect
      border: Border
    }
  | {
      type: 'text'
      time?: string
      author?: string
      rect: Rect
      body: string
      fontSize: number
      color: string
    }
  | {
      type: 'stamp'
      time?: string
      author?: string
      rect: Rect
      body: string
      fontSize: number
      color: string
    }

type RenderAnnot = {
  type: 'render_annot'
  annot: PDFAnnot
}
function renderAnnot(page: PDFPage, context: DrawContext, op: RenderAnnot) {
  const pageSize = page.getSize()

  const annot = op.annot
  let dict: PDFDict | undefined

  switch (annot.type) {
    case 'comment':
      if (!annot.body) {
        console.warn('annotation body is empty', annot)
        return
      }

      dict = page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Text',
        Name: 'Comment',
        Open: false,
        Rect: toPDFRect(annot.rect, pageSize),

        T: annot.author ? PDFString.of(annot.author) : undefined,
        M: annot.time ? PDFString.fromDate(parseISO8601Time(annot.time)) : undefined,

        Contents: PDFString.of(context.sanitizeText(annot.body)),

        C: annot.color ? hexToPDFColor(annot.color) : undefined
      })
      break
    case 'rect':
      dict = page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Square',
        Rect: toPDFRect(annot.rect, pageSize),
        T: annot.author ? PDFString.of(annot.author) : undefined,
        M: annot.time ? PDFString.fromDate(parseISO8601Time(annot.time)) : undefined,
        C: annot.border.color ? hexToPDFColor(annot.border.color) : undefined,
        BS: { W: annot.border.width, S: 'S' }
      })
      break
    case 'text':
      dict = page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'FreeText',
        Rect: toPDFRect(annot.rect, pageSize),
        Contents: PDFString.of(annot.body),
        DA: PDFString.of(`/Helv ${annot.fontSize} Tf ${hexToRGBString(annot.color)} rg`),
        Border: [0, 0, 0]
      })
      break
    case 'stamp':
      const font = context.resolveFont({
        family: 'Helvetica',
        style: 'normal',
        weight: 'normal'
      })
      const xobject = page.doc.context.obj({
        Type: 'XObject',
        Subtype: 'Form',
        FormType: 1,
        BBox: [0, 0, annot.rect.width, annot.rect.height],
        Resources: { Font: { [font.name]: font.ref } }
      })

      const fontSize = annot.rect.height * 0.6
      const textHeight = font.heightAtSize(fontSize, { descender: false })
      const textWidth = font.widthOfTextAtSize(annot.body, fontSize)
      const borderWidth = 2

      const operators = [
        ...pdfLibDrawRectangle({
          x: 0,
          y: 0,
          width: annot.rect.width,
          height: annot.rect.height,
          borderWidth: borderWidth,
          borderColor: hexToRgb(annot.color),
          color: hexToRgb('#FFFFFF'),
          rotate: degrees(0),
          xSkew: degrees(0),
          ySkew: degrees(0)
        }),
        ...pdfLibDrawText(font.encodeText(annot.body), {
          color: hexToRgb(annot.color)!,
          font: font.name,
          size: annot.fontSize,
          rotate: degrees(0),
          xSkew: degrees(0),
          ySkew: degrees(0),
          x: annot.rect.width / 2 - textWidth / 2,
          y: annot.rect.height / 2 - textHeight / 2
        })
      ]

      const stream = PDFContentStream.of(xobject, operators, false)
      const streamRef = page.doc.context.register(stream)
      const appearance = page.doc.context.obj({ N: streamRef })

      dict = page.doc.context.obj({
        Subtype: PDFName.of('Stamp'),
        Rect: toPDFRect(annot.rect, pageSize),
        CA: 1,
        AP: appearance
      }) as PDFDict
  }

  const annotationRef = page.doc.context.register(dict!) // Register the annotation in the document.
  // console.log('Annotation added:', annotationRef, annotationRect, annotation)

  page.node.addAnnot(annotationRef)
}

function hexToRGBString(hex: string) {
  const color = hexToRgb(hex)!
  return [color.red, color.green, color.blue].join(' ')
}

// helpers

function hexToRgb(hex: string | undefined) {
  if (!hex) return undefined

  hex = hex.replace('#', '').toLowerCase()

  const r = parseInt(hex.substring(0, 2), 16) / 255
  const g = parseInt(hex.substring(2, 4), 16) / 255
  const b = parseInt(hex.substring(4, 6), 16) / 255

  if (isNaN(r) || isNaN(g) || isNaN(b)) throw `Invalid hex color ${hex}`

  return rgb(r, g, b)
}

type PDFRect = [number, number, number, number]
/**
 * Rects in PDF-land are of the format:
 * [bottomLeftX, bottomLeftY, topRightX, topRightY]
 *
 * The original of a PDF document is also (0, 0) for the bottom-left of a page
 * and increases as we go up and to the right.
 *
 * We prefer working from a (0, 0) being top-left since most graphics works that way.
 * So we transform the {@link rect} given a page of size {@link pageSize} the PDF rect format and coordinate system.
 *
 * @param rect
 * @param pageSize
 * @returns
 */
function toPDFRect(rect: Rect, pageSize: Size): PDFRect {
  return [
    // bottom left
    rect.x,
    pageSize.height - (rect.y + rect.height),
    // top right x
    rect.x + rect.width,
    pageSize.height - rect.y
  ]
}

type PDFColor = [number, number, number]
function hexToPDFColor(hex: string): PDFColor {
  const color = hexToRgb(hex)!
  return [color.red, color.green, color.blue]
}
