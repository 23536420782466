import { hasValidationError } from '@features/st-questionnaire/st-questionnaire-module'
import { QuestionnairePageValidationResult } from '@features/st-questionnaire/st-questionnaire-validation'
import { ENTITY_OPTIONS } from '@st/formation'
import { ShareholderInputConfig } from '@st/ui-config'
import { useReadWriteContext } from '@st/pdf'
import { toStr } from '@st/util/cast'
import { clsx } from 'clsx'
import { useCallback } from 'react'
import { Dropdown, TextInput } from '.'

export function WizardShareholderInput({
  config,
  validationResult
}: {
  config: ShareholderInputConfig
  validationResult: QuestionnairePageValidationResult
}) {
  const [type, setType, typeError] = useShareholderComponent(
    config.userInputKey,
    'type',
    validationResult
  )

  const [firstName, setFirstName, firstNameError] = useShareholderComponent(
    config.userInputKey,
    'name.first_name',
    validationResult
  )

  const [lastName, setLastName, lastNameError] = useShareholderComponent(
    config.userInputKey,
    'name.last_name',
    validationResult
  )

  const [entityName, setEntityName, entityNameError] = useShareholderComponent(
    config.userInputKey,
    'entity_name',
    validationResult
  )

  const [tin, setTin, tinError] = useShareholderComponent(
    config.userInputKey,
    'tin',
    validationResult
  )

  return (
    <div className="flex w-full flex-col gap-4">
      <Dropdown
        label="Type"
        placeholder="Select a type"
        className={clsx({ 'border-red-500': typeError })}
        options={ENTITY_OPTIONS}
        value={type}
        onChange={setType}
      />

      {type == 'individual' ? (
        <div className="flex flex-col gap-4 sm:flex-row">
          <TextInput
            label="First Name"
            className={clsx('flex-grow', { 'border-red-500': firstNameError })}
            value={firstName}
            onChange={setFirstName}
          />

          <TextInput
            label="Last Name"
            className={clsx('flex-grow', { 'border-red-500': lastNameError })}
            value={lastName}
            onChange={setLastName}
          />
        </div>
      ) : (
        <TextInput
          label="Entity Name"
          className={clsx('flex-grow', { 'border-red-500': entityNameError })}
          value={entityName}
          onChange={setEntityName}
        />
      )}

      <TextInput
        label={type == 'individual' ? 'SSN' : 'EIN'}
        className={clsx({ 'border-red-500': tinError })}
        value={tin}
        onChange={setTin}
      />
    </div>
  )
}

function useShareholderComponent(
  inputKey: string,
  subKey: string,
  validationResult: QuestionnairePageValidationResult,
  opts?: { defaultValue?: string }
): [string, (value: string | undefined) => void, boolean] {
  const { useRead, useWrite } = useReadWriteContext()

  const finalInputKey = `${inputKey}.${subKey}`
  const value = toStr(useRead(finalInputKey)) || opts?.defaultValue || ''
  const setValues = useWrite()

  const onChange = useCallback(
    (value: string | undefined) => {
      setValues({ [finalInputKey]: value ?? null })
    },
    [finalInputKey, subKey]
  )

  return [value, onChange, hasValidationError(validationResult, finalInputKey)]
}
