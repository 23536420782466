import { Button } from '@st/theme'
import { CloseIcon, PaperClipIcon } from '@st/theme/src/icons'
import { SaveIndicator } from '@st/theme/src/SaveIndicator'
import { inflect } from '@st/util/inflect'

type Props = {
  saveStatus: 'saving' | 'saved'
  documentsCount: number
  onClickViewAttachments: () => void
  onClose: () => void
}
export function STQuestionnaireTrailingHeader({
  saveStatus,
  documentsCount,
  onClickViewAttachments,
  onClose
}: Props) {
  return (
    <div className="flex flex-row items-center gap-2">
      <SaveIndicator className="hidden lg:flex" saveStatus={saveStatus} />
      <div className="hidden md:flex">
        <Button
          variant="default"
          leadingIcon={<PaperClipIcon className="h-4 w-4" />}
          onClick={onClickViewAttachments}
        >
          {documentsCount} {inflect(documentsCount, 'Attachment')}
        </Button>
      </div>
      <Button variant="default" onClick={onClose}>{`Save & close`}</Button>
    </div>
  )
}
