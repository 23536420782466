import { DeleteIcon } from '@components/icons'
import { selClientComment, stFolderModule } from '@features/st-folder-viewer/st-folder-module'
import { NoteWidgetConfig } from '@st/ui-config'
import { useDebouncedCallback } from '@st/react-util/use-debounced-callback'
import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, Modal } from '@st/theme'
import { isNotEmpty } from '@st/util/json-value'
import { ButtonGroup, TextField, TextFieldToolbar } from '@theme'
import { AddNoteIcon } from '@theme/icons'
import { useState } from 'react'
import { match } from 'ts-pattern'
export function WizardNoteComposer(config: NoteWidgetConfig) {
  const commentKey = getCommentKey(config.userInputKey)
  const stFolder = useProcess(stFolderModule)
  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)

  const originalClientComment = useProcessState(stFolderModule, (s) =>
    selClientComment(s, commentKey)
  )

  const [comment, setComment] = useState(originalClientComment)
  const [open, setOpen] = useState<boolean>(isNotEmpty(originalClientComment))
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState<undefined | 'confirmDelete'>()

  const flushChanges = useDebouncedCallback((value: string) => {
    stFolder.send({
      type: 'request',
      request: { type: 'folders/setClientComment', folderId, commentKey, commentBody: value }
    })
  }, 1000)

  async function onConfirmDelete() {
    setComment('')
    setConfirmDeleteDialog(undefined)
    setOpen(false)
    stFolder.send({
      type: 'request',
      request: { type: 'folders/setClientComment', folderId, commentKey, commentBody: '' }
    })
  }

  return (
    <div className="flex flex-col">
      {open ? (
        <TextField
          isMultiline={true}
          autoFocus={true}
          value={comment ?? ''}
          onChange={(value) => {
            setComment(value)
            flushChanges(value)
          }}
          toolbar={
            <TextFieldToolbar
              icon={<AddNoteIcon />}
              title="Note"
              buttons={
                <ButtonGroup>
                  <Button variant="subtle" onClick={() => setConfirmDeleteDialog('confirmDelete')}>
                    <DeleteIcon />
                  </Button>
                </ButtonGroup>
              }
            />
          }
        />
      ) : (
        <button
          className="flex h-10 flex-row items-center gap-2 self-end rounded-md border border-gray-200 bg-white px-3.5 text-base hover:border-blue-500"
          // empty string keeps it open
          onClick={() => setOpen(true)}
        >
          <AddNoteIcon className="mr-2 text-blue-500" /> Add a note
        </button>
      )}
      {match(confirmDeleteDialog)
        .with('confirmDelete', () => (
          <Modal isOpen={true}>
            <Dialog
              title="Delete note"
              subtitle="Please confirm if you would like to delete this note"
              buttons={
                <DialogButtons>
                  <Button variant="subtle" onClick={() => setConfirmDeleteDialog(undefined)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={onConfirmDelete}>
                    Delete
                  </Button>
                </DialogButtons>
              }
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </div>
  )
}
/**
 * For legacy reasons, we have a full user input key for client comments
 * but because we now have a dedicated api, we need to strip off the prefix to get the commentKey
 */
function getCommentKey(userInputKey: string) {
  if (userInputKey.startsWith('questionnaire.notes.client.')) {
    return userInputKey.substring('questionnaire.notes.client.'.length)
  }
  return userInputKey
}
