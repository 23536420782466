export type Rect = {
  x: number
  y: number
  width: number
  height: number
}

export type Point = { x: number; y: number }
export type Size = { width: number; height: number }

export type NormalizedRect = Rect
export type NormalizedPoint = { x: number; y: number }

type PDFRect = string
type PDFQuadPoints = string

type RectPoints = {
  topLeft: Point
  bottomLeft: Point
  topRight: Point
  bottomRight: Point
}
export function getRectPoints(rect: Rect): RectPoints {
  return {
    topLeft: { x: rect.x, y: rect.y },
    bottomLeft: { x: rect.x, y: rect.y + rect.height },
    topRight: { x: rect.x + rect.width, y: rect.y },
    bottomRight: { x: rect.x + rect.width, y: rect.y + rect.height }
  }
}

export function toPDFRect(normalizedRect: NormalizedRect, pageSize: Size): PDFRect {
  const { bottomLeft, topRight } = getRectPoints(normalizedRect)
  return toPDFCoordList([bottomLeft, topRight], pageSize)
    .map((n) => n.toString())
    .join(',')
}

function parsePDFPoints(pdfPoints: string): number[] {
  return pdfPoints.split(',').map(Number)
}

export function toNormalizedRect(pdfRect: PDFRect, pageSize: Size): NormalizedRect {
  const [x1, y1, x2, y2] = parsePDFPoints(pdfRect)
  const normalizedRect: NormalizedRect = {
    x: x1 / pageSize.width,
    y: 1 - y2 / pageSize.height, // Flip the y-coordinate
    width: Math.abs(x2 - x1) / pageSize.width,
    height: Math.abs(y2 - y1) / pageSize.height
  }
  return normalizedRect
}

export function toRect(pdfRect: PDFRect, pageSize: Size): Rect {
  const [x1, y1, x2, y2] = parsePDFPoints(pdfRect)
  const normalizedRect: NormalizedRect = {
    x: x1,
    y: pageSize.height - y2, // Flip the y-coordinate
    width: Math.abs(x2 - x1),
    height: Math.abs(y2 - y1)
  }
  return normalizedRect
}

export function toPDFQuadPoints(
  normalizedPoints: NormalizedPoint[],
  pageSize: Size
): PDFQuadPoints {
  return toPDFCoordList(normalizedPoints, pageSize)
    .map((n) => n.toString())
    .join(',')
}

export function toNormalizedPoints(
  pdfQuadPoints: PDFQuadPoints,
  pageSize: Size
): NormalizedPoint[] {
  const pdfPoints = parsePDFPoints(pdfQuadPoints)
  let normalizedPoints: NormalizedPoint[] = []

  for (let i = 0; i < pdfPoints.length; i += 2) {
    normalizedPoints.push({
      x: pdfPoints[i] / pageSize.width,
      y: 1 - pdfPoints[i + 1] / pageSize.height // Adjust y-coordinate for PDF's origin being at the bottom-left
    })
  }

  return normalizedPoints
}

function toPDFCoordList(normalizedPoints: NormalizedPoint[], pageSize: Size): number[] {
  return normalizedPoints.flatMap((point) => {
    return [pageSize.width * point.x, pageSize.height * (1 - point.y)]
  })
}

/**
 * Constraints inspired by Flutter's constraints for layouts
 * A parent passes constraints down to each child when asking for its size.
 */
export type Constraints = {
  /**
   * The max width a child can occupy
   */
  width: number

  /**
   * The max height a child can occupy
   */
  height: number
}

export function validateSize(el: any, constraints: Constraints, size: Size): Size {
  if (size.width == Infinity || size.height == Infinity) {
    console.error('Invalid size', size, ' for ', el, ' constraints ', constraints)
    throw 'Invalid size'
  }
  return size
}

export type Pad = {
  top: number
  bottom: number
  left: number
  right: number
}

export const Pad = {
  zero: { top: 0, left: 0, right: 0, bottom: 0 } as Pad,
  all: (v: number) => {
    return { top: v, bottom: v, left: v, right: v }
  },
  horizontal: (v: number) => {
    return { top: 0, bottom: 0, left: v, right: v }
  },
  only: (pad: Partial<Pad>) => {
    return { ...Pad.zero, ...pad }
  }
}

export function rectSize(rect: Rect): Size {
  return { width: rect.width, height: rect.height }
}

export function unionRects(rects: Rect[]): Rect {
  let left = Number.MAX_SAFE_INTEGER
  let top = Number.MAX_SAFE_INTEGER
  let right = Number.MIN_SAFE_INTEGER
  let bottom = Number.MIN_SAFE_INTEGER

  for (const rect of rects) {
    left = Math.min(left, rect.x)
    top = Math.min(top, rect.y)
    right = Math.max(right, rect.x + rect.width)
    bottom = Math.max(bottom, rect.y + rect.height)
  }

  return {
    x: left,
    y: top,
    width: right - left,
    height: bottom - top
  }
}

export function addPadding(rect: Rect, padding: Pad): Rect {
  return {
    x: rect.x + padding.left,
    y: rect.y + padding.top,
    width: rect.width - padding.left - padding.right,
    height: rect.height - padding.top - padding.bottom
  }
}

export type Align = 'start' | 'center' | 'end'

export function vAlignRect(rect: Rect, inParent: Rect, align: Align): Rect {
  switch (align) {
    case 'start':
      return rect
    case 'center':
      return {
        x: rect.x,
        y: inParent.y + inParent.height / 2 - rect.height / 2,
        width: rect.width,
        height: rect.height
      }
    case 'end':
      return {
        x: rect.x,
        y: inParent.y + inParent.height - rect.height,
        width: rect.width,
        height: rect.height
      }
  }
}

export function hAlignRect(rect: Rect, inParent: Rect, align: Align): Rect {
  switch (align) {
    case 'start':
      return rect
    case 'center':
      return {
        x: inParent.x + inParent.width / 2 - rect.width / 2,
        y: rect.y,
        width: rect.width,
        height: rect.height
      }
    case 'end':
      return {
        x: inParent.x + inParent.width - rect.width,
        y: rect.y,
        width: rect.width,
        height: rect.height
      }
  }
}

export function getAlignOffset(parentSize: number, childSize: number, align: Align): number {
  switch (align) {
    case 'start':
      return 0
    case 'center':
      return (parentSize - childSize) * 0.5
    case 'end':
      return parentSize - childSize
  }
}

export type AxisDirection = 'row' | 'column'

export function constrainSize(size: Size, constraints: Constraints): Size {
  return {
    width: Math.min(size.width, constraints.width),
    height: Math.min(size.height, constraints.height)
  }
}

export function getMainDimension(direction: AxisDirection, size: Size): number {
  switch (direction) {
    case 'column':
      return size.height
    case 'row':
      return size.width
  }
}

export type Offset = { dx: number; dy: number }

export function move<T extends Point>(point: T, offset: Partial<Offset>): T {
  return {
    ...point,
    x: point.x + (offset.dx ?? 0),
    y: point.y + (offset.dy ?? 0)
  }
}
