import { defineModule, defineTask } from '@st/redux'
import { AuthClientAdapter, AuthError, AuthSignUpOpts, User } from './auth-client-adapter'
import { AuthState } from './auth-state'
import { MFASession } from '@st/sdk'
import { isEmpty } from '@st/util/json-value'
export type { AuthEvent } from './auth-state'

export type AuthModuleState = {
  auth: AuthState
}

export type AuthContext = {
  authClient: AuthClientAdapter
}

export type AuthMessage =
  /**
   * The user is in the process of registering an account
   * with the given email.
   * The operation hasn't yet been completed.
   */
  | { type: 'signingUp'; email: string }

  /**
   * Creating a new account succeeded
   */
  | { type: 'signUpSucceeded'; user: User }

  /**
   * Creating a new account failed.
   * This could be for many reasons such as a weak password or
   * an email with the account already existing.
   */
  | { type: 'signUpFailed'; error: AuthError }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'loggingIn/emailPassword'; email: string }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'loggingIn/verificationLink'; email: string }
  | { type: 'mfaRequired'; session: MFASession }

  /**
   * The user tried to login and the login failed do to the reason
   * provided in the error
   */
  | { type: 'loginFailed'; error: AuthError }

  /**
   * We have detected that the user is logged in.
   * This can be because user logged in from the UI
   * or they returned later and were still logged in.
   */
  | { type: 'userIsLoggedIn'; user: User }

  /**
   * We have detected that the user is logged out.
   * This can be because the user tapped logout
   * or they come to the website and we see they are logged out.
   */
  | { type: 'userIsLoggedOut' }
  | {
      type: 'loginWithEmail/sendingEmail'
      organizationId: string
      email: string
      destination: string
    }
  | {
      type: 'loginWithEmail/emailSent'
      organizationId: string
      email: string
      destination: string
    }

export const authModule = defineModule<AuthState, AuthMessage, AuthContext>({
  name: 'auth',
  init: () => {
    return { status: 'unknown', loginState: { status: 'idle' } }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'loggingIn/emailPassword':
        return { ...state, status: 'loggingIn', email: message.email }
      case 'mfaRequired':
        return { ...state, status: 'waitingForMFACode', mfaSession: message.session }
      case 'loggingIn/verificationLink':
        return { ...state, status: 'loggingIn', email: message.email }
      case 'signingUp':
        return { ...state, status: 'signingUp', email: message.email }
      case 'userIsLoggedOut':
        return { status: 'loggedOut', loginState: { status: 'idle' } }
      case 'userIsLoggedIn':
        return {
          ...state,
          status: 'loggedIn',
          user: message.user,
          email: message.user.email!
        }
      case 'loginFailed':
        return { ...state, status: 'error', error: message.error }
      case 'signUpFailed':
        return { ...state, status: 'error', error: message.error }
      case 'loginWithEmail/sendingEmail':
        return {
          ...state,
          loginState: {
            ...state.loginState,
            status: 'sendingLoginVerificationEmail'
          }
        }
      case 'loginWithEmail/emailSent':
        return {
          ...state,
          loginState: {
            ...state.loginState,
            status: 'idle',
            lastDeliveredTo: message.email
          }
        }
      default:
        return state
    }
  }
})

export function selFirstTimeVerificationSent(authState: AuthState) {
  return authState.loginState.lastDeliveredTo != null
}

export const signUp = defineTask(
  authModule,
  async ({ send, getState }, opts: AuthSignUpOpts, { authClient }: AuthContext) => {
    const result = await authClient.signUp(opts)
    if (result.ok) {
      send({ type: 'signUpSucceeded', user: result.value })
    } else {
      send({ type: 'signUpFailed', error: result.error })
    }
    return result
  }
)

type LoginWithMagicLink = { magicLinkToken: string }
export const loginWithMagicLink = defineTask(
  authModule,
  async ({ send }, opts: LoginWithMagicLink, { authClient }: AuthContext) => {
    const result = await authClient.loginWithMagicLink(opts.magicLinkToken)
    if (!result.ok) {
      send({ type: 'loginFailed', error: result.error })
    }
    return result
  }
)

type LoginWithEmailPassword = { email: string; password: string }
export const loginWithEmailPassword = defineTask(
  authModule,
  async ({ send }, opts: LoginWithEmailPassword, { authClient }: AuthContext) => {
    send({ type: 'loggingIn/emailPassword', email: opts.email })

    if (isEmpty(opts.email)) {
      send({
        type: 'loginFailed',
        error: { type: 'missing_email', message: 'Email is required' }
      })
      return
    }
    if (isEmpty(opts.password)) {
      send({
        type: 'loginFailed',
        error: { type: 'missing_password', message: 'Password is required' }
      })
      return
    }

    const result = await authClient.loginWithEmailPassword(opts.email, opts.password)

    switch (result.status) {
      case 'succeeded':
        send({ type: 'userIsLoggedIn', user: result.user })
        break
      case 'mfaRequired':
        send({ type: 'mfaRequired', session: result.session })
        break
      case 'failed':
        send({ type: 'loginFailed', error: result.error })
        break
    }

    return result
  }
)

export const logout = defineTask(authModule, async (_, __, { authClient }: AuthContext) => {
  authClient.logout()
})
