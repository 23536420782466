import { ReactNode, createContext, useContext } from 'react'
import { FileStorageClient } from './adapters/file-storage-client'

type Props = {
  children: ReactNode
  adapter: FileStorageClient
}
export function FileStorageProvider({ children, adapter }: Props) {
  return <FileStorageContext.Provider value={adapter}>{children}</FileStorageContext.Provider>
}

export function useFileStorageAdapter(): FileStorageClient {
  return useContext(FileStorageContext)!
}

const FileStorageContext = createContext<FileStorageClient | undefined>(undefined)
