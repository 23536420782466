import { addQueryParams } from '@st/util/url'
import { Param, RawParam } from './params'
import { makeRouteMatcher } from './route-matcher'

export type Route<
  Key extends string,
  Pattern extends string,
  ParamMap extends RouteParamMapOf<Pattern>
> = StringifyFn<Pattern, ParamMap> & {
  cast: CastFn<Pattern, ParamMap>
  match: MatchFn<Key, Pattern, ParamMap>
  key: Key
  pattern: string
  paramNames: string[]
}

export function route<
  Key extends string,
  Pattern extends string,
  PM extends ParamMap<AllParamNames<InferParamFromPattern<Pattern>>>
>(key: Key, pattern: Pattern, parserMap?: PM): Route<Key, Pattern, PM> {
  const parsedRoute = parseRoute(pattern, parserMap ?? ({} as PM))

  const matchRoute = makeRouteMatcher(parsedRoute.pathPattern)

  const routeFn = (rawParams: RawParams) => {
    const path = stringifyRoute(
      parserMap ?? {},
      stringifyParams(parsedRoute.pathParamParsers, rawParams),
      parsedRoute.pathTokens
    )

    const queryParams = stringifyParams(parsedRoute.queryParamParsers, rawParams)

    return addQueryParams(path, queryParams)
  }

  routeFn.key = key
  routeFn.cast = castParams(parsedRoute)
  routeFn.pattern = parsedRoute.pathPattern
  routeFn.paramNames = [
    ...Object.keys(parsedRoute.pathParamParsers),
    ...Object.keys(parsedRoute.queryParamParsers)
  ]

  routeFn.match = function (path: string, queryParams?: Record<string, string>) {
    const [ok, params] = matchRoute(path)

    if (!ok) {
      return undefined
    }

    const castedParams = routeFn.cast({ ...queryParams, ...params! })
    return { ...castedParams, name: routeFn.key }
  }

  return routeFn as Route<Key, Pattern, PM>
}

type ParamNames<R extends string = string, O extends string = string> = {
  required: R
  optional: O
}

type WithOptionalParam<PG extends ParamNames, P extends string> = ParamNames<
  PG['required'],
  PG['optional'] | P
>
type WithRequiredParam<PG extends ParamNames, P extends string> = ParamNames<
  PG['required'] | P,
  PG['optional']
>

type InferParam<T extends string, PN extends ParamNames> = T extends `:${infer P}?`
  ? WithOptionalParam<PN, P>
  : T extends `:${infer P}*`
  ? WithOptionalParam<PN, P>
  : T extends `:${infer P}+`
  ? WithRequiredParam<PN, P>
  : T extends `:${infer P}`
  ? WithRequiredParam<PN, P>
  : PN

type InferParamFromPattern<P extends string> = P extends `${infer A}/${infer B}`
  ? InferParam<A, InferParamFromPattern<B>>
  : P extends `${infer A}&${infer B}`
  ? InferParam<A, InferParamFromPattern<B>>
  : InferParam<P, { required: never; optional: never }>

type AllParamNames<G extends ParamNames> = G['required'] | G['optional']

type SerializedParams<K extends string = string> = Record<K, string>

type RawParams = Record<string, unknown>

type ParamMap<K extends string> = Record<K, Param<any>>

type ExtractParserReturnTypes<P extends ParamMap<any>, F extends keyof P> = {
  [K in F]: ReturnType<P[K]['decode']>
}

export type RouteParamMapOf<Pattern extends string> = ParamMap<
  AllParamNames<InferParamFromPattern<Pattern>>
>

export type RouteMatch<
  Name extends string,
  Pattern extends string,
  ParamMap extends RouteParamMapOf<Pattern>
> = { name: Name } & ExtractParserReturnTypes<
  ParamMap,
  InferParamFromPattern<Pattern>['required']
> &
  Partial<ExtractParserReturnTypes<ParamMap, InferParamFromPattern<Pattern>['optional']>>

export type RouteMatchPartial<
  Name extends string,
  Pattern extends string,
  ParamMap extends RouteParamMapOf<Pattern>
> = Partial<RouteMatch<Name, Pattern, ParamMap>> & { name: Name }

export type RouteMatchType<R> = R extends Route<infer Name, infer Pattern, infer ParamMap>
  ? RouteMatch<Name, Pattern, ParamMap>
  : never

type StringifyFn<Pattern extends string, ParamMap extends RouteParamMapOf<Pattern>> = <
  G extends InferParamFromPattern<Pattern>
>(
  params: ExtractParserReturnTypes<ParamMap, G['required']> &
    Partial<ExtractParserReturnTypes<ParamMap, G['optional']>>
) => string

type CastFn<Pattern extends string, ParamMap extends RouteParamMapOf<Pattern>> = <
  G extends InferParamFromPattern<Pattern>
>(
  params: SerializedParams<G['required']> & Partial<SerializedParams<G['optional']>>
) => ExtractParserReturnTypes<ParamMap, G['required']> &
  Partial<ExtractParserReturnTypes<ParamMap, G['optional']>>

type MatchFn<
  Name extends string,
  Pattern extends string,
  ParamMap extends RouteParamMapOf<Pattern>
> = (
  path: string,
  queryParams?: Record<string, string>
) => RouteMatch<Name, Pattern, ParamMap> | undefined

type PathToken = string | PathParam

type PathParam = {
  modifier: '' | '*' | '+' | '?'
  name: string
}

const isPathParam = (x: PathToken): x is PathParam => typeof x !== 'string'

function filterParamMap(parserMap: ParamMap<any>, tokens: PathToken[]): ParamMap<any> {
  return tokens.reduce<ParamMap<any>>(
    (acc, t: PathToken) => (!isPathParam(t) ? acc : { ...acc, [t.name]: parserMap[t.name] }),
    {}
  )
}

type ParsedRoute<T extends string> = {
  pathPattern: string
  pathTokens: PathToken[]
  queryTokens: PathToken[]
  pathParamParsers: ParamMap<any>
  queryParamParsers: ParamMap<any>
  paramMap: ParamMap<T>
}

function parseRoute<T extends string>(
  pathWithQuery: string,
  paramMap: ParamMap<T>
): ParsedRoute<T> {
  const [pathPattern, ...queryFragments] = pathWithQuery.split('&')
  const pathTokens = parseTokens(pathPattern.split('/'))
  const queryTokens = parseTokens(queryFragments)
  const pathParamParsers = filterParamMap(paramMap, pathTokens)
  const queryParamParsers = filterParamMap(paramMap, queryTokens)
  return {
    pathPattern,
    pathTokens,
    queryTokens,
    pathParamParsers,
    queryParamParsers,
    paramMap
  }
}

function parseTokens(path: string[]): PathToken[] {
  return path.reduce<PathToken[]>((acc, f) => {
    if (!f) {
      return acc
    } else if (f.startsWith(':')) {
      const maybeMod = f[f.length - 1]
      const modifier = maybeMod === '+' || maybeMod === '*' || maybeMod === '?' ? maybeMod : ''
      return acc.concat({
        modifier,
        name: f.slice(1, modifier ? f.length - 1 : undefined)
      })
    }
    return acc.concat(f)
  }, [])
}

function stringifyParams(parserMap: ParamMap<any>, params: RawParams): Record<string, string> {
  return Object.keys(parserMap).reduce(
    (acc, k) => ({
      ...acc,
      ...(params[k] ? { [k]: parserMap[k].encode(params[k]) } : {})
    }),
    {}
  )
}

function stringifyRoute(
  parserMap: ParamMap<any>,
  params: SerializedParams,
  pathTokens: PathToken[]
): string {
  const stringified = ['']
    .concat(
      pathTokens.reduce<string[]>((acc, t) => {
        if (!isPathParam(t)) {
          return acc.concat(t)
        }

        const value = params[t.name]
        if (!value) return acc

        if (parserMap[t.name] === RawParam) {
          return acc.concat(value)
        }

        return acc.concat(encodeURIComponent(value))
      }, [])
    )
    .join('/')
  // empty should be a slash
  return stringified.length == 0 ? '/' : stringified
}

function castParams({ paramMap }: ParsedRoute<any>) {
  return (params: SerializedParams): RawParams => {
    return Object.keys(params).reduce<RawParams>((acc, k) => {
      if (!paramMap[k]) {
        return acc
      }
      return {
        ...acc,
        [k]: paramMap[k].decode(params[k])
      }
    }, {})
  }
}
