import { Banner, Button, Dialog, DialogButtons, Modal, Select, TextInput, Wizard } from '@st/theme'

import { useOrganization } from '@features/organizations'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import { RETURN_TYPES, TaxYear } from '@st/tax-folder'
import { match } from 'ts-pattern'
import {
  selCanAddClient,
  selRelatedFolders,
  STFolderCreateOneByOneMessage,
  stFolderCreateOneByOneModule,
  STFolderCreateOneByOneState
} from './st-folder-create-one-by-one-module'
import { Link } from '@features/link'

type Props = {
  onClose: () => void
  onBack: () => void
}

export function STFolderCreateOneByOnePage({ onClose, onBack }: Props) {
  const organization = useOrganization()
  const folderCreateOneByOne = useProcess(stFolderCreateOneByOneModule, {
    organizationId: organization.id
  })
  const state = useProcessState(stFolderCreateOneByOneModule)

  const isBusinessReturn = state.form.returnType != '1040'

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onBack}>
            Back
          </Button>

          <Button
            disabled={!selCanAddClient(state)}
            variant="primary"
            onClick={() =>
              folderCreateOneByOne.send({
                type: 'addClient',
                confirmOnRelatedFolders: true
              })
            }
          >
            {state.status === 'running' ? 'Adding...' : 'Add client'}
          </Button>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main
        title="Add clients one by one"
        subtitle="You can manually add clients one at a time."
      >
        <div className="flex w-[600px] flex-col items-stretch gap-4">
          {match(state.lastResponse)
            .with({ status: 'created' }, ({ folder }) => (
              <Banner variant="success">
                Successfully added client {formatFolderEntityName(folder!.entities)}
              </Banner>
            ))
            .with({ status: 'needs_confirmation' }, ({ relatedFolders }) => (
              <Banner variant="warning">
                Client already exists. Please confirm you would like to add them.
              </Banner>
            ))
            .otherwise(() => null)}

          <div className="flex flex-col gap-1">
            <label className="text-base">Tax Year</label>
            <Select
              options={[2024, 2023, 2022, 2021, 2020] as TaxYear[]}
              buildLabel={(v) => String(v)}
              buildValue={(v) => v}
              value={state.form.year}
              onChange={(v) =>
                folderCreateOneByOne.send({ type: 'setField', field: 'year', value: v! })
              }
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base">Return type</label>
            <Select
              options={RETURN_TYPES}
              buildLabel={(v) => v}
              buildValue={(v) => v}
              value={state.form.returnType}
              onChange={(v) =>
                folderCreateOneByOne.send({ type: 'setField', field: 'returnType', value: v! })
              }
            />
          </div>

          <div className="flex flex-row gap-2">
            <div className="flex flex-1 flex-col gap-1">
              <label className="text-base">First name</label>
              <TextInput
                className="flex-1"
                placeholder="First name"
                value={state.form.firstName}
                onChange={(v) =>
                  folderCreateOneByOne.send({ type: 'setField', field: 'firstName', value: v })
                }
              />
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <label className="text-base">Last name</label>
              <TextInput
                className="flex-1"
                placeholder="Last name"
                value={state.form.lastName}
                onChange={(v) =>
                  folderCreateOneByOne.send({ type: 'setField', field: 'lastName', value: v })
                }
              />
            </div>
          </div>

          {isBusinessReturn && (
            <div className="flex flex-1 flex-col gap-1">
              <label className="text-base">Entity name</label>
              <TextInput
                className="flex-1"
                placeholder="Corporation name"
                value={state.form.corporationName}
                onChange={(v) =>
                  folderCreateOneByOne.send({
                    type: 'setField',
                    field: 'corporationName',
                    value: v
                  })
                }
              />
            </div>
          )}

          <div className="flex flex-row gap-2">
            <div className="flex flex-1 flex-col gap-1">
              <label className="text-base">Email</label>
              <TextInput
                className="flex-1"
                placeholder="Email"
                value={state.form.email}
                onChange={(v) =>
                  folderCreateOneByOne.send({ type: 'setField', field: 'email', value: v })
                }
              />
            </div>
          </div>
        </div>

        {match(state)
          .with({ confirmDialogVisible: true }, () => (
            <STFolderCreateOneByOnePageConfirmation
              state={state}
              send={folderCreateOneByOne.send}
              onClose={() => folderCreateOneByOne.send({ type: 'closeConfirmDialog' })}
            />
          ))
          .otherwise(() => null)}
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function STFolderCreateOneByOnePageConfirmation({
  onClose,
  state,
  send
}: {
  onClose: () => void
  state: STFolderCreateOneByOneState
  send: (message: STFolderCreateOneByOneMessage) => void
}) {
  const relatedFolders = selRelatedFolders(state)
  return (
    <Modal isOpen={true}>
      <Dialog
        title="Duplicate client"
        buttons={
          <DialogButtons>
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={state.status === 'running'}
              onClick={() => send({ type: 'addClient', confirmOnRelatedFolders: false })}
            >
              {state.status === 'running' ? 'Adding...' : 'Add client'}
            </Button>
          </DialogButtons>
        }
      >
        A client with the same email address already exists:
        <div className="flex flex-col items-start gap-2">
          {relatedFolders.map((f) => (
            <Link
              key={f.id}
              className="text-blue-500 underline hover:text-blue-700"
              target="_blank"
              href={{ name: 'organization_folder', folderId: f.id }}
            >
              {formatFolderEntityName(f.entities)}
            </Link>
          ))}
        </div>
      </Dialog>
    </Modal>
  )
}
