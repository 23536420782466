import { unique } from './array'
import { isNotEmpty } from './json-value'

type Entities = {
  taxpayer?: IndividualEntity
  spouse?: IndividualEntity
  corporation?: CorporateEntity
}

type IndividualEntity = {
  firstName?: string
  lastName?: string
  email?: string
}

type CorporateEntity = {
  name?: string
}

type EntitiesFormat = 'internal' | 'client' | 'salutation'

export function formatEntities(entities: Entities, format: EntitiesFormat = 'internal') {
  if (!entities) {
    return ''
  }

  const taxpayer = entities.taxpayer?.firstName ? entities.taxpayer : undefined
  const spouse = entities.spouse?.firstName ? entities.spouse : undefined

  const lastNames = unique([taxpayer?.lastName, spouse?.lastName].filter(isNotEmpty))
  const firstNames = [taxpayer?.firstName, spouse?.firstName].filter(isNotEmpty)

  switch (format) {
    case 'internal':
      if (isNotEmpty(entities.corporation?.name)) {
        return entities.corporation.name
      }

      return [lastNames.join(' & '), firstNames.join(' & ')].filter(isNotEmpty).join(', ')
    case 'client':
      if (isNotEmpty(entities.corporation?.name)) {
        return entities.corporation.name
      }

      if (lastNames.length == 2) {
        // If there are two last names, we want to format the output as "John Smith & Jane Doe"
        return `${taxpayer?.firstName} ${taxpayer?.lastName} & ${spouse?.firstName} ${spouse?.lastName}`
      } else {
        // If there is only one last name, we want to format the output as "John & Jane Smith"
        return [firstNames.join(' & '), lastNames.join(' & ')].filter(isNotEmpty).join(' ')
      }
    case 'salutation':
      return ''
  }
}

/**
 * Formats a friendly salutation from the taxpayer and spouse (e.g. "John & Jane").
 * Mirrors the Elixir `def salutation()` logic.
 */
export function formatSalutation(entities: Entities): string {
  // if either taxpayer/spouse has a firstName, gather them
  const names = [entities.taxpayer?.firstName, entities.spouse?.firstName].filter(isNotEmpty)

  // clean and capitalize each name, then join with " & "
  const cleanedNames = names.map(cleanFirstName)
  return cleanedNames.join(' & ')
}

/**
 * A helper to mimic Elixir's "clean_first_name" by extracting the first word and capitalizing it.
 */
function cleanFirstName(name: string): string {
  const [first] = name.trim().split(/\s+/)
  if (!first) return ''
  return first.charAt(0).toUpperCase() + first.slice(1).toLowerCase()
}
