import { DragEvent, useState } from 'react'

/**
 * A drag-and-drop move event.
 * The user wants to move item either before/after the target
 *
 * @template T - The type of the items being dragged and targeted
 */
export type Move<T> = {
  item: T
  target: T
  relation: 'before' | 'after'
}

export type DragState<T> =
  | {
      status: 'idle'
    }
  | {
      status: 'moving'
      item: T
    }
  | {
      status: 'hovering'
      item: T
      target: T
      relation: 'before' | 'after'
    }

export type DragHandlersProps = {
  draggable?: boolean
  onDragStart: (event: DragEvent<HTMLElement>) => void
  onDragOver: (event: DragEvent<HTMLElement>) => void
  onDrop: (event: DragEvent<HTMLElement>) => void
  onDragLeave: (event: DragEvent<HTMLElement>) => void
}

type DraggableHook<T> = {
  dragState: DragState<T>
  handlers: (item: T) => DragHandlersProps
}

/**
 * `useDraggable` Hook for enhancing drag-and-drop interactions within your components.
 *
 * @template T Generic type for items that can be dragged.
 * @param {Object} params - Configuration object.
 * @param {Function} params.onDrop - Callback fired when an item is dropped.
 *
 * ### Usage:
 *
 * 1. Destructure the `dragState` and `handlers` from the hook.
 *   const { dragState, handlers } = useDraggable({ onDrop });
 *
 * 2. Apply the `handlers` to each draggable element by passing the item as an argument.
 *    <div {...handlers(item)} />
 *
 * 3. The `dragState` can be used to conditionally render or style elements based on the drag state.
 *
 * ### Example:
 * ```jsx
 * function DraggableList({ items, onMove }) {
 *   const { handlers } = useDraggable({
 *     onDrop: ({ item, target, relation }) => {
 *       onMove({ item, target, relation });
 *     },
 *   });
 *
 *   return (
 *     <ul>
 *       {items.map(item => (
 *         <li {...handlers(item)} key={item.id}>
 *           {item.content}
 *         </li>
 *       ))}
 *     </ul>
 *   );
 * }
 * ```
 *
 * This will make each `li` element draggable, and the `onMove` function will be called with the move details when an item is dropped.
 */
export function useDraggable<T>({ onDrop }: { onDrop: (e: Move<T>) => void }): DraggableHook<T> {
  const [dragState, setDragState] = useState<DragState<T>>({
    status: 'idle'
  })

  function handlers(item: T): DragHandlersProps {
    return {
      onDragStart(e: DragEvent<HTMLElement>): void {
        setDragState({ status: 'moving', item })
      },
      onDragOver(e: DragEvent<HTMLElement>): void {
        e.preventDefault()

        if (dragState.status == 'idle') return

        e.dataTransfer.dropEffect = 'move'

        const bounding = e.currentTarget.getBoundingClientRect()
        const targetMidpoint = bounding.y + bounding.height / 2

        setDragState({
          status: 'hovering',
          item: dragState.item,
          target: item,
          // if we drag it on the top-half, the intent is to move before
          // bottom-half, intent is to move below
          relation: e.clientY > targetMidpoint ? 'after' : 'before'
        })
      },

      onDrop(e: DragEvent<HTMLElement>): void {
        // if the item and target are the same, we should not do anything
        if (dragState.status == 'hovering' && dragState.item != dragState.target) {
          onDrop({
            item: dragState.item!,
            target: dragState.target,
            relation: dragState.relation
          })
        }

        setDragState({ status: 'idle' })
      },

      onDragLeave(): void {
        if (dragState.status == 'idle') return

        setDragState({
          status: 'moving',
          item: dragState.item
        })
      }
    }
  }

  return { dragState, handlers }
}
